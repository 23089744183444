import React, {useState } from 'react';
import HomeCommonSec from './HomeCommonSec';
import Modalform from './Modalform';
import { useParams, Link } from 'react-router-dom';

function Home() {
    const { location } = useParams(); // Get the dynamic location from the URL

    // Set a default value for location if it's not provided in the URL
    const finalLocation = location || "India";

      // State to manage modal visibility
      const [showModal, setShowModal] = useState(false);
    
      // Functions to handle modal open/close
      const handleShow = () => setShowModal(true);
      const handleClose = () => setShowModal(false);

    return (
        <div>
            <section id="hero">
                <div className="hero-container">
                    <h1>Best main Digital Marketing In {finalLocation}</h1>
                    <h2>Digics - Get 10x ROI | Lead Generation Expert | Social Media Marketing | SEO</h2>
                    <Link 
                         onClick={handleShow}
                        className="btn-get-started scrollto myButton" 
                        >
                        Get Started
                    </Link>
                </div>
            </section>

            <HomeCommonSec />
            <Modalform showModal={showModal} handleClose={handleClose} />
        </div>
    );
}

export default Home;
