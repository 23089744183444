import React from "react";

const Termsconditions = () => {
  return (
    <div>
        <main id="main">
      {/* Breadcrumb Section */}
      <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero srvcMrkt">
        <div class="container">
          <div class="breadcrumb-hero">
            <h1>Terms & Conditions</h1>
            
          </div>
        </div>
      </div>
      <div class="container ">
        <ol>
          <li><a href="https://digics.in/">Home</a></li>
          <li>Terms & Conditions</li>
        </ol>
      </div>
    </section>

      {/* Disclaimer Content */}
      <div class="container pb-5">
    
   
    
 
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      
      <div class="row">

          <div class="col-md-12">

              <h2>Agreement Overview</h2>

              <p>This document represents a Service Level Agreement (“SLA”) for the provision of, Search Engine Optimization (SEO), Pay Per Click (PPC), Social Media Marketing or related online marketing services (“the service/s”) by Digics for and on behalf of the Client (hereinafter referred to as “the parties”) which together with the Service Brief contains the only terms and conditions of “the agreement” between the parties.</p>

              <p>Acceptance of proposals by email or payment indicates that you have read, understood and agree to Digics service as Just Perfect terms and that you have the authority to make purchase decisions on behalf of the company.</p>

              <h2>Goal &amp; Objectives</h2>

              <p>Digics specialises in the provision of the services and related advice and undertakes to make available to the Client, within reason and at its discretion, its expertise, knowledge and experience to maximise the effect of the service, the Client in turn undertakes to provide all necessary information and feedback regarding the service.</p>

              <p>At the outset, the parties will determine and record the service brief in a document, which brief shall specify the nature and extent of the service, a quotation as to the price payable and payment terms, alternatively, the client shall specify one or more of the existing products available from Digics</p>

              <p>1. We’ll have dedicated account manager for you. Who have all the details about what's going on in your campaign.</p>

              <p>2. In all marketing channels we share the same interface to make sure complete transparency.</p>

              <p>3. We’ll be sharing monthly reports of every single marketing channel (SEO, PPC, SMO)</p>

              <h2>Guarantee</h2>

              <p>The Client accepts that the deployment of the service is of a speculative nature and that there is no guarantee that the service will bring about the desired or intended result, accordingly, the Client will have no claims of any nature against Digics arising from the advice or service provided by Digics, accordingly, there is no refund policy.</p>

              <p>1. Every business is unique &amp; different from one another. There are so many reasons due to which results may vary for e.g. user behaviour, web/app interface, product description so on.</p>

              <p>2. Every online marketing channel gives different results in different business vertical. </p>

              <p>3. At initial level we create best basic campaign in all online marketing platforms by getting the data. We make future marketing strategies.</p>

              <h2>Cancellation of Agreement</h2>

              <p>The client acknowledges that this is a continuing service and can only be cancelled by giving Digics 1 (One) Calendar Month written notice e.g from the 1st to the last day of the month. Cancellation of this entire agreement shall in no way absolve the client from his/her/its obligation to remunerate Digics for services rendered up to the date of cancellation and the client shall affect payment of any and all fees due to Digics , calculated up to the date of cancellation, on or before such cancellation date. Refunds will not be issued for any reason.</p>

              <h2>Pausing &amp; Edition To Any Campaigns</h2>

              <p>Marketing campaigns produce the best results when it is uninterrupted. Clients request to pause campaigns/project does not forfeit monthly management payment obligation. Media spend may be reduced or paused but management fee will remain as per proposal. All outstanding invoices remain due upon payment due date. </p>

              <p>1. For new campaign we need at least 1 week time. Product description / information should be given at least 10 days before the product is going to be launch.</p>

              <p>2. Any changes in the current campaign might take maximum 8-16 business hrs.</p>

              <h5 style={{
    background: "antiquewhite",
    padding: "15px",
    borderRadius: "10px",
    margin: "20px 0px",
  }}>Note :- All important communications shall be recorded in writing and transmitted between the parties to their respective specified physical, email or whatsapp/telegram groups.</h5>

              <h2>Placement of Advertisements</h2>

              <p>Material, Image and /or content, required by Digics, needs to be supplied to Digics, in such a format as Digics may specify, at least 7 (seven) business days prior to the campaign commencing. Digics shall not be obligated to place any advertising that does not satisfy its requirements. The client is responsible to provide the advertising material and any delay or issues regarding the supply thereof, are not a basis to cancel, postpone or delay payment for the agreement. Digics shall within reason look to ensure the full exposure period is delivered on. Digics reserves the right to change the format, layout and look-and-feel of the advert and text to achieve click through target; Include any link in the web pages within Digics, in its sole discretion as it deems appropriate; Reject or remove any Advertisements that are, in the sole discretion of Digics, deemed improper, immoral or unlawful.</p>

              <h2>Content &amp; Images</h2>

              <p>All content is to be provided by the Client. Our copywriters will review the content and Provide minor edits if required. Special graphics and images need to be provided by the Client. Any other images will be sourced by our designers. All product images and descriptions for ecommerce platforms need to be provided by the Client. It is important to know that although images may be sourced, images/ graphics sourced may be subject copyright protection. Copyright laws dictate a fair usage of images/graphics

                sourced online. It is therefore highly recommended that clients source their own images and /or request our team to create new images/graphics.

              </p>

              <h2>Payment Terms</h2>

              <p>As we believe in complete transparency so we share the same online marketing platform with client where client can directly pay to marketing channels like Google, facebook etc.. </p>

              <p>1. For SEO only you have to make payment to us rest other online marketing channels which has their own payment gateway. We expect our clients to make direct payment to Google, facebook, LinkedIn etc online marketing platform. .</p>

              <p>2. Marketing Set Up/Analysis - Full payment due prior setup. - Once Off Invoice</p>

              <p>3. All other work that falls outside this agreement will be quoted on an adhoc basis. Payment will be due as specified on invoice.</p>

              <p>4. <b>Rush Fee :-</b> Deliverables requested for a same-day turnaround will be subject to a 100% rush fee. (USD 25 / INR 1000) per campaign.</p>

              <h5  style={{
    background: "antiquewhite",
    padding: "15px",
    borderRadius: "10px",
    margin: "20px 0px",
  }}>All payment terms can be found on the Service Brief or will be agreed separately for bespoke work. All prices and quotations are exclusive of VAT unless specifically stated to the contrary Payments shall be made without deduction or set-off whatsoever, free of exchange, and at such place as directed by Digics</h5>

            </div>

      </div>

</div>
    
   
                            

      </div>
      </main>
    </div>
  );
};

export default Termsconditions;
