import React from "react";

const Thankyou = () => {
  return (
    <div>
        <main id="main">
      {/* Breadcrumb Section */}
      <section id="breadcrumbs" class="breadcrumbs">
     
      <div className="container text-center pt-4 pb-5">
      <div className="row">
        {/* Left Section - Image */}
        <div className="col-md-6" style={{ borderRight: "8px solid #f99d1b" }}>
          <img
            decoding="async"
            fetchPriority="high"
            style={{ width: "100%" }}
            className="m-auto"
            src={ require("./images/thankyou.gif") }
            alt="digital-marketing"
          
          />
        </div>

        {/* Right Section - Message */}
        <div className="col-md-6 d-flex align-items-center">
          <h3 className="mt-3">
            Thank You For Sharing Your Contact Details With Team Digics. Our Team Will Get Back To You Shortly.
            For anything, Click on the Following:
            <br />
            <br />
            <a
              style={{
                background: "#f99d1b",
                color: "#fff",
                padding: "7px 15px",
                borderRadius: "37px",
                display: "inline-block",
                textDecoration: "none"
              }}
              href="https://wa.me/9266355565"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className=" bi bi-whatsapp text-success" size={20} /> WhatsApp
            </a>
          </h3>
        </div>
      </div>
    </div>
    </section>

   
      </main>
    </div>
  );
};

export default Thankyou;
