import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function MarketareaSamericastateandcity() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>S America State and City</h2>
                    <ul class="state-list">
                    <li> <Link to="/barbados">
                        Barbados
                    </Link></li>
                    <li> <Link to="/dominica">
                        Dominica
                    </Link></li>
                    <li> <Link to="/grenada">
                        Grenada
                    </Link></li>
                    <li> <Link to="/jamaica">
                        Jamaica
                    </Link></li>
                    <li> <Link to="/st-kitts-and-nevis">
                        St. Kitts and Nevis
                    </Link></li>
                    <li> <Link to="/st-lucia">
                        St. Lucia
                    </Link></li>
                    <li> <Link to="/st-vincent-and-the-grenadines">
                        St. Vincent and the Grenadines
                    </Link></li>
                    <li> <Link to="/trinidad-and-tobago">
                        Trinidad and Tobago
                    </Link></li>
                    <li> <Link to="/belize">
                        Belize
                    </Link></li>
                    <li> <Link to="/guyana">
                        Guyana
                    </Link></li>
                    <li> <Link to="/antigua-and-barbuda">
                        Antigua and Barbuda
                    </Link></li>
                    <li> <Link to="/bahamas">
                        Bahamas
                    </Link></li>
                    <li> <Link to="/bridgetown">
                        Bridgetown
                    </Link></li>
                    <li> <Link to="/speightstown">
                        Speightstown
                    </Link></li>
                    <li> <Link to="/oistins">
                        Oistins
                    </Link></li>
                    <li> <Link to="/holetown">
                        Holetown
                    </Link></li>
                    <li> <Link to="/roseau">
                        Roseau
                    </Link></li>
                    <li> <Link to="/portsmouth">
                        Portsmouth
                    </Link></li>
                    <li> <Link to="/marigot">
                        Marigot
                    </Link></li>
                    <li> <Link to="/st-georges">
                        St. Georges
                    </Link></li>
                    <li> <Link to="/gouyave">
                        Gouyave
                    </Link></li>
                    <li> <Link to="/grenville">
                        Grenville
                    </Link></li>
                    <li> <Link to="/kingston">
                        Kingston
                    </Link></li>
                    <li> <Link to="/montego-bay">
                        Montego Bay
                    </Link></li>
                    <li> <Link to="/portmore">
                        Portmore
                    </Link></li>
                    <li> <Link to="/spanish-town">
                        Spanish Town
                    </Link></li>
                    <li> <Link to="/basseterre">
                        Basseterre
                    </Link></li>
                    <li> <Link to="/charlestown">
                        Charlestown
                    </Link></li>
                    <li> <Link to="/castries">
                        Castries
                    </Link></li>
                    <li> <Link to="/soufriere">
                        Soufrière
                    </Link></li>
                    <li> <Link to="/vieux-fort">
                        Vieux Fort
                    </Link></li>
                    <li> <Link to="/kingstown">
                        Kingstown
                    </Link></li>
                    <li> <Link to="/georgetown">
                        Georgetown
                    </Link></li>
                    <li> <Link to="/calliaqua">
                        Calliaqua
                    </Link></li>
                    <li> <Link to="/port-of-spain">
                        Port of Spain
                    </Link></li>
                    <li> <Link to="/san-fernando">
                        San Fernando
                    </Link></li>
                    <li> <Link to="/chaguanas">
                        Chaguanas
                    </Link></li>
                    <li> <Link to="/arima">
                        Arima
                    </Link></li>
                    <li> <Link to="/belize-city">
                        Belize City
                    </Link></li>
                    <li> <Link to="/san-ignacio">
                        San Ignacio
                    </Link></li>
                    <li> <Link to="/orange-walk">
                        Orange Walk
                    </Link></li>
                    <li> <Link to="/linden">
                        Linden
                    </Link></li>
                    <li> <Link to="/new-amsterdam">
                        New Amsterdam
                    </Link></li>
                    <li> <Link to="/st-johns">
                        St. Johns
                    </Link></li>
                    <li> <Link to="/all-saints">
                        All Saints
                    </Link></li>
                    <li> <Link to="/liberta">
                        Liberta
                    </Link></li>
                    <li> <Link to="/nassau">
                        Nassau
                    </Link></li>
                    <li> <Link to="/freeport">
                        Freeport
                    </Link></li>
                    <li> <Link to="/west-end">
                        West End
                    </Link></li>
                    <li> <Link to="/lucaya">
                        Lucaya
                    </Link></li>

                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default MarketareaSamericastateandcity;
