import React from "react";

const Privacypolicy = () => {
  return (
    <div>
        <main id="main">
      {/* Breadcrumb Section */}
      <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero srvcMrkt">
        <div class="container">
          <div class="breadcrumb-hero">
            <h1>Privacy Policy</h1>
            
          </div>
        </div>
      </div>
      <div class="container ">
        <ol>
          <li><a href="https://digics.in/">Home</a></li>
          <li>Privacy Policy</li>
        </ol>
      </div>
    </section>

      {/* Disclaimer Content */}
      <div class="container pb-5">
    
   
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
           <div class="row">

                  <div class="col-md-12">



                     <p>We designed these Privacy policies to allow you to understand the process of our organization, how we gather information, how we use it, and how we protect the collected information from the customers. </p>



                     <p>Digics is committed to protecting the privacy of its users. We understand the secrecy and importance of data privacy. Therefore any kind of data breach is not acceptable. </p>

                     

                     <p>Being a reputed and renowned Digital Marketing firm, we believe it's our responsibility to avoid any sort of data breach. We have been firm on this since the beginning. We abide by the rules due to which we don't share any customer name or information with some other organization or individual without our customer's consent or endorsement.

                        Get the detailed information regarding the same in our privacy policy section below. 

                     </p>



                     <h2>Site maintenance :</h2>



                     <p>We provide flexible services to our clients. We deal with creating the website and website management. Charges for both services will be communicated via email communication. Each service holds a separate price. Customers can choose or opt for any one of them or you can choose both the services together. The price may vary according to the selection of the desired assistance. </p>



                     <h2>Marketing Account Access :</h2>



                     <p>We practice transparency and share the same interface with our clients. According to intellectual property rights our company will have admin control over the account and hold all the rights to keep that account. </p>



                     <p>We assure 100% dedication, efforts, and results from our end. We acknowledge the client's concern as the client is the one who is investing in the campaign that is why we will share the same interface with the client. Improving the campaign and making it successful is our responsibility but, all the promotional or marketing expenses are to be handled by the owner of the campaign that is the investor. </p>



                     <p>Pricing may vary as per the client and the work that client requires. We encourage that every communication is supposed to be done via mail or any other mode of written communication to maintain transparency and healthy professional relationships. </p>



                     <h2>Service :</h2>



                     <p>Customer satisfaction is our primary goal at Digics. To achieve this goal, we provide you a dedicated account manager who holds all the information related to clients, campaigns and updates required. In case any query client has the authority to establish contact via mail or can reach us directly through call. We appreciate feedback so that we can make improvements. So, the client's feedback matters to us the most.</p>



                     <h2>We are using cookie to save :</h2>



                     <p>We track cookies. When we say cookies it means it is a small browser history that saves in your internet browser like firefox, chrome, safari, or so on when you open any website. This will help us to see the very basic demography of our clients like-country, location, browser and other basic details. If you show interest in our product or services so we might show you other offers with the help of these cookies in the future. We don’t disclose information about any client. </p>

                     <p>We use cookies for many different purposes. Some cookies are necessary for technical and internal study &amp; reasons; some enable a personalized experience for both visitors and registered users. Some allow the display of advertising from selected third-party networks. Some of these cookies may be set when a page is loaded, or when a visitor takes a particular action (clicking the “like” or “follow” button on a post, for example).</p>

                     <p>Many of the cookies we use are set if you are a registered WordPress.com user (so you do not have to log in every time, for example), while others are set whenever you visit one of our websites irrespective of whether you have an account.</p>

                    

                  </div>

               </div>

    
   
                            

      </div>
    
   
                            

      </div>
      </main>
    </div>
  );
};

export default Privacypolicy;
