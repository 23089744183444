import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Productpagecommon from './Productpagecommon';
import Modalform from './Modalform';
import data from './data.json';

function ShowPath({ locationName }) {
  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <p>Current Location: {locationName}</p>
    </div>
  );
}

function Mainproductpage() {
  const [showModal, setShowModal] = useState(false);
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");
   // Define handleClose for the modal
   const handleClose = () => setShowModal(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [pageData, setPageData] = useState(null);

  const pathname = location.pathname;
  const parts = pathname.split('/');
  const locationName = parts[1] || 'Home';

  useEffect(() => {
    const random1 = Math.floor(Math.random() * 11) + 50;
    const random2 = Math.floor(Math.random() * 10) + 1;
    setNum1(random1);
    setNum2(random2);
  }, []);

  useEffect(() => {
    const matchedData = data.find((item) => location.pathname.includes(item.slug));
    if (matchedData) {
      const updatedTitle = matchedData.title.replaceAll('{locationName}', locationName);
      const updatedContent = matchedData.content.replaceAll('{locationName}', locationName);
      const updatedMetaDescription = matchedData.description.replaceAll('{locationName}', locationName);
      
      setPageData({ ...matchedData, content: updatedContent });
      document.title = `${updatedTitle} - Digics About Us`;

      const metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute("content", updatedMetaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = "description";
        newMetaTag.content = updatedMetaDescription;
        document.head.appendChild(newMetaTag);
      }
    } else {
      document.title = 'Page Not Found';
    }

    return () => {
      document.title = 'Digics';
    };
  }, [location.pathname, locationName]);

  if (!pageData) {
    return (
      <div style={{ textAlign: 'center', margin: '20px' }}>
        <ShowPath locationName={locationName} />
        <h2>No data available for this page.</h2>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (parseInt(captchaInput, 10) !== num1 + num2) {
      setCaptchaError("Captcha answer is incorrect. Please try again.");
      return;
    }

    const url =
      'https://script.google.com/macros/s/AKfycbwa4ZjE2HqP1Ql6nVTZdK41X5NLA1Evkuqzcd9xYyo16olKCI_1RwTgjZKRsWjb20NRYg/exec';

    const formData = new URLSearchParams({
      Name: e.target.name.value,
      Email: e.target.email.value,
      Phoneno: e.target.phoneno.value,
      Whatsapp: e.target.whatsapp.value,
      Location: e.target.location.value,
      PageUrl: e.target.PageUrl.value,
      Massage: e.target.massage.value,
    });

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formData.toString(),
    })
      .then((res) => res.text())
      .then(() => navigate('/Thankyou'))
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <section
        id="hero"
        style={{
          backgroundImage:
            'url("https://ml6fqqdy5q9o.i.optimole.com/w:auto/h:auto/q:mauto/ig:avif/https://digics.in/wp-content/themes/digics/dev-assets/images/productbanner.webp")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          marginTop: '65px',
          height: '50vh',
        }}
      >
        <div className="hero-container">
          <h1>Best {pageData.keyword} In {locationName}</h1>
          {/* <h1>{pageData.meta-description}</h1> */}
          <h2>Digics - Get 10x ROI | Lead Generation Expert | Social Media Marketing | SEO</h2>
          <Link
            type="button"
            className="btn-get-started scrollto myButton"
            onClick={() => setShowModal(true)}
          >
            Get Started
          </Link>
        </div>
      </section>

      <section id="blog" className="blog mt-5 pt-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-8 pt-lg-2 content">
              <div
                dangerouslySetInnerHTML={{ __html: pageData.content }}
                style={{
                  fontFamily: 'Arial, sans-serif',
                  lineHeight: '1.6',
                  color: '#333',
                  marginBottom: '20px',
                }}
              />
            </div>
            <div className="col-lg-4 pt-lg-2 content">
              <div style={{ background: '#f99d1bba', padding: '25px', borderRadius: '15px' }}>
                <form onSubmit={handleSubmit}>
                  <div className="form-request a-ajax-form">
                    <div className="row">
                      <div className="col-6">
                        <input name="name" className="form-control" placeholder="Name" />
                        <input type="Hidden" name="PageUrl" value={window.location.href}  />
                      </div>
                      <div className="col-6">
                        <input name="email" className="form-control" placeholder="Email" />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <input name="phoneno" className="form-control" placeholder="Phone" />
                      </div>
                      <div className="col-6">
                        <input name="whatsapp" className="form-control" placeholder="Whatsapp" />
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <input name="location" className="form-control" placeholder="Location" />
                    </div>
                    <div className="form-group mt-4">
                      <textarea
                        name="massage"
                        className="form-control"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    {/* Captcha Field added below */}
                    <div className="form-group mt-4">
                      <label>
                        {num1} + {num2} = ?
                      </label>
                      <input
                        type="text"
                        name="captcha"
                        className="form-control"
                        placeholder="Enter captcha answer"
                        value={captchaInput}
                        onChange={(e) => {
                          setCaptchaInput(e.target.value);
                          setCaptchaError(""); // Reset error on change
                        }}
                        required
                      />
                      {captchaError && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {captchaError}
                        </div>
                      )}
                    </div>
                    <div className="text-center mt-4">
                      <button type="submit" className="btn btn-dark">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Productpagecommon />
      <Modalform showModal={showModal} handleClose={handleClose} /> {/* Pass handleClose */}
    </div>
  );
}

export default Mainproductpage;
