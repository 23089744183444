import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
       

<header id="header" className="fixed-top d-flex align-items-center">
<div className="container d-flex align-items-center justify-content-between">
  <div className="logo">
    <h1 className="text-light">
      <Link to="/">
        <img
          src="https://digics.in/wp-content/themes/digics/dev-assets/images/digics logo-01.png"
          alt="Digics Logo"
        />
       </Link>
    </h1>
  </div>
  <div id="mySidenav" className="sidenav">
    <Link  className="closebtn" onClick={closeNav}>
      ×
    </Link>
    <nav id="navbar" className="navbar">
      <ul className="d-block">
        <li>
          <Link to="/" className="menu-item">
            Home
          </Link>
        </li>
        <li className="dropdown menu-item">
          <Link to="/digital-marketing">
            <span>Digital Marketing</span> <i className="bi bi-chevron-right"></i>
          </Link>

          <ul>
  <li>
    <Link to="/leading-digital-marketing-agency">Digital Marketing Agency</Link>
  </li>
  <li>
    <Link to="/best-digital-marketing-and-advertising-agency">
      Digital Marketing and Advertising Agency
    </Link>
  </li>
  <li>
    <Link to="/top-digital-advertising-services">Digital Advertising</Link>
  </li>
  <li>
    <Link to="/top-digital-marketing-specialist">Digital Marketing Specialist</Link>
  </li>
  <li>
    <Link to="/best-digital-marketing-consultant">Digital Marketing Consultant</Link>
  </li>
  <li>
    <Link to="/top-digital-marketing-packages">Digital Marketing Package</Link>
  </li>
  <li>
    <Link to="/best-digital-advertising-company">Digital Advertising Company</Link>
  </li>
  <li>
    <Link to="/top-digital-advertising-agency">Digital Advertising Agency</Link>
  </li>
  <li>
    <Link to="/leading-digital-advertising-firm">Digital Advertising Firm</Link>
  </li>
            </ul>


         
        </li>

        <li className="dropdown menu-item">
          <Link to="">
            <span>Extra</span> <i className="bi bi-chevron-right"></i>
          </Link>


          <ul>
            <li>
              <Link to="/digital-marketing-agency">Digital Marketing Agency</Link>
            </li>
            <li>
              <Link to="/digicsabout">About</Link>
            </li>
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
            {/* Add additional links as needed */}
          </ul>
          </li>


        {/* Repeat structure for other dropdown items */}
      </ul>
    </nav>
  </div>
  <span
    style={{ fontSize: '25px', cursor: 'pointer', color: '#f99d1b' }}
    className="desktopmenubtn"
    onClick={openNav}
  >
    ☰ <span style={{ color: '#000' }}>Menu</span>
  </span>
  <nav id="navbar" className="navbar">
    <ul>
      <li>
        <Link
         
          className="getstarted myButton"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          data-value="Header / Get Started"
        >
          Get Started
        </Link>
      </li>
    </ul>
    <i className="bi mobile-nav-toggle bi-list"></i>
  </nav>
</div>
</header>


    );
}
function openNav() {
    document.getElementById("mySidenav").style.width = "50%";
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
export default Header;
