import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function MarketareaNamericastateandcity() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>N America State and City</h2>
                    <ul class="state-list">
                 
                    <li> <Link to="/alberta">
                        Alberta
                    </Link></li>
                    <li> <Link to="/british-columbia">
                        British Columbia
                    </Link></li>
                    <li> <Link to="/manitoba">
                        Manitoba
                    </Link></li>
                    <li> <Link to="/new-brunswick">
                        New Brunswick
                    </Link></li>
                    <li> <Link to="/newfoundland-and-labrador">
                        Newfoundland and Labrador
                    </Link></li>
                    <li> <Link to="/nova-scotia">
                        Nova Scotia
                    </Link></li>
                    <li> <Link to="/ontario">
                        Ontario
                    </Link></li>
                    <li> <Link to="/prince-edward-island">
                        Prince Edward Island
                    </Link></li>
                    <li> <Link to="/quebec">
                        Quebec
                    </Link></li>
                    <li> <Link to="/saskatchewan">
                        Saskatchewan
                    </Link></li>
                    <li> <Link to="/northwest-territories">
                        Northwest Territories
                    </Link></li>
                    <li> <Link to="/nunavut">
                        Nunavut
                    </Link></li>
                    <li> <Link to="/yukon">
                        Yukon
                    </Link></li>
                    <li> <Link to="/alabama">
                        Alabama
                    </Link></li>
                    <li> <Link to="/alaska">
                        Alaska
                    </Link></li>
                    <li> <Link to="/arizona">
                        Arizona
                    </Link></li>
                    <li> <Link to="/arkansas">
                        Arkansas
                    </Link></li>
                    <li> <Link to="/california">
                        California
                    </Link></li>
                    <li> <Link to="/colorado">
                        Colorado
                    </Link></li>
                    <li> <Link to="/connecticut">
                        Connecticut
                    </Link></li>
                    <li> <Link to="/delaware">
                        Delaware
                    </Link></li>
                    <li> <Link to="/florida">
                        Florida
                    </Link></li>
                    <li> <Link to="/georgia">
                        Georgia
                    </Link></li>
                    <li> <Link to="/hawaii">
                        Hawaii
                    </Link></li>
                    <li> <Link to="/idaho">
                        Idaho
                    </Link></li>
                    <li> <Link to="/illinois">
                        Illinois
                    </Link></li>
                    <li> <Link to="/indiana">
                        Indiana
                    </Link></li>
                    <li> <Link to="/iowa">
                        Iowa
                    </Link></li>
                    <li> <Link to="/kansas">
                        Kansas
                    </Link></li>
                    <li> <Link to="/kentucky">
                        Kentucky
                    </Link></li>
                    <li> <Link to="/louisiana">
                        Louisiana
                    </Link></li>
                    <li> <Link to="/maine">
                        Maine
                    </Link></li>
                    <li> <Link to="/maryland">
                        Maryland
                    </Link></li>
                    <li> <Link to="/massachusetts">
                        Massachusetts
                    </Link></li>
                    <li> <Link to="/michigan">
                        Michigan
                    </Link></li>
                    <li> <Link to="/minnesota">
                        Minnesota
                    </Link></li>
                    <li> <Link to="/mississippi">
                        Mississippi
                    </Link></li>
                    <li> <Link to="/missouri">
                        Missouri
                    </Link></li>
                    <li> <Link to="/montana">
                        Montana
                    </Link></li>
                    <li> <Link to="/nebraska">
                        Nebraska
                    </Link></li>
                    <li> <Link to="/nevada">
                        Nevada
                    </Link></li>
                    <li> <Link to="/new-hampshire">
                        New Hampshire
                    </Link></li>
                    <li> <Link to="/new-jersey">
                        New Jersey
                    </Link></li>
                    <li> <Link to="/new-mexico">
                        New Mexico
                    </Link></li>
                    <li> <Link to="/new-york">
                        New York
                    </Link></li>
                    <li> <Link to="/north-carolina">
                        North Carolina
                    </Link></li>
                    <li> <Link to="/north-dakota">
                        North Dakota
                    </Link></li>
                    <li> <Link to="/ohio">
                        Ohio
                    </Link></li>
                    <li> <Link to="/oklahoma">
                        Oklahoma
                    </Link></li>
                    <li> <Link to="/oregon">
                        Oregon
                    </Link></li>
                    <li> <Link to="/pennsylvania">
                        Pennsylvania
                    </Link></li>
                    <li> <Link to="/rhode-island">
                        Rhode Island
                    </Link></li>
                    <li> <Link to="/south-carolina">
                        South Carolina
                    </Link></li>
                    <li> <Link to="/south-dakota">
                        South Dakota
                    </Link></li>
                    <li> <Link to="/tennessee">
                        Tennessee
                    </Link></li>
                    <li> <Link to="/texas">
                        Texas
                    </Link></li>
                    <li> <Link to="/utah">
                        Utah
                    </Link></li>
                    <li> <Link to="/vermont">
                        Vermont
                    </Link></li>
                    <li> <Link to="/virginia">
                        Virginia
                    </Link></li>
                    <li> <Link to="/washington">
                        Washington
                    </Link></li>
                    <li> <Link to="/west-virginia">
                        West Virginia
                    </Link></li>
                    <li> <Link to="/wisconsin">
                        Wisconsin
                    </Link></li>
                    <li> <Link to="/wyoming">
                        Wyoming
                    </Link></li>
                    <li> <Link to="/calgary">
                        Calgary
                    </Link></li>
                    <li> <Link to="/edmonton">
                        Edmonton
                    </Link></li>
                    <li> <Link to="/red-deer">
                        Red Deer
                    </Link></li>
                    <li> <Link to="/lethbridge">
                        Lethbridge
                    </Link></li>
                    <li> <Link to="/st-albert">
                        St. Albert
                    </Link></li>
                    <li> <Link to="/medicine-hat">
                        Medicine Hat
                    </Link></li>
                    <li> <Link to="/grande-prairie">
                        Grande Prairie
                    </Link></li>
                    <li> <Link to="/airdrie">
                        Airdrie
                    </Link></li>
                    <li> <Link to="/spruce-grove">
                        Spruce Grove
                    </Link></li>
                    <li> <Link to="/leduc">
                        Leduc
                    </Link></li>
                    <li> <Link to="/vancouver">
                        Vancouver
                    </Link></li>
                    <li> <Link to="/surrey">
                        Surrey
                    </Link></li>
                    <li> <Link to="/burnaby">
                        Burnaby
                    </Link></li>
                    <li> <Link to="/richmond">
                        Richmond
                    </Link></li>
                    <li> <Link to="/abbotsford">
                        Abbotsford
                    </Link></li>
                    <li> <Link to="/coquitlam">
                        Coquitlam
                    </Link></li>
                    <li> <Link to="/kelowna">
                        Kelowna
                    </Link></li>
                    <li> <Link to="/nanaimo">
                        Nanaimo
                    </Link></li>
                    <li> <Link to="/kamloops">
                        Kamloops
                    </Link></li>
                    <li> <Link to="/chilliwack">
                        Chilliwack
                    </Link></li>
                    <li> <Link to="/winnipeg">
                        Winnipeg
                    </Link></li>
                    <li> <Link to="/brandon">
                        Brandon
                    </Link></li>
                    <li> <Link to="/steinbach">
                        Steinbach
                    </Link></li>
                    <li> <Link to="/thompson">
                        Thompson
                    </Link></li>
                    <li> <Link to="/portage-la-prairie">
                        Portage la Prairie
                    </Link></li>
                    <li> <Link to="/winkler">
                        Winkler
                    </Link></li>
                    <li> <Link to="/selkirk">
                        Selkirk
                    </Link></li>
                    <li> <Link to="/morden">
                        Morden
                    </Link></li>
                    <li> <Link to="/dauphin">
                        Dauphin
                    </Link></li>
                    <li> <Link to="/fredericton">
                        Fredericton
                    </Link></li>
                    <li> <Link to="/saint-john">
                        Saint John
                    </Link></li>
                    <li> <Link to="/moncton">
                        Moncton
                    </Link></li>
                    <li> <Link to="/dieppe">
                        Dieppe
                    </Link></li>
                    <li> <Link to="/miramichi">
                        Miramichi
                    </Link></li>
                    <li> <Link to="/bathurst">
                        Bathurst
                    </Link></li>
                    <li> <Link to="/edmundston">
                        Edmundston
                    </Link></li>

                    <li> <Link to="/st-johns">
                        St. John's
                    </Link></li>
                    <li> <Link to="/mount-pearl">
                        Mount Pearl
                    </Link></li>
                    <li> <Link to="/corner-brook">
                        Corner Brook
                    </Link></li>
                    <li> <Link to="/paradise">
                        Paradise
                    </Link></li>
                    <li> <Link to="/conception-bay-south">
                        Conception Bay South
                    </Link></li>
                    <li> <Link to="/grand-falls-windsor">
                        Grand Falls-Windsor
                    </Link></li>
                    <li> <Link to="/gander">
                        Gander
                    </Link></li>
                    <li> <Link to="/happy-valley-goose-bay">
                        Happy Valley-Goose Bay
                    </Link></li>
                    <li> <Link to="/labrador-city">
                        Labrador City
                    </Link></li>
                    <li> <Link to="/halifax">
                        Halifax
                    </Link></li>
                    <li> <Link to="/cape-breton">
                        Cape Breton
                    </Link></li>
                    <li> <Link to="/truro">
                        Truro
                    </Link></li>
                    <li> <Link to="/sydney">
                        Sydney
                    </Link></li>
                    <li> <Link to="/new-glasgow">
                        New Glasgow
                    </Link></li>
                    <li> <Link to="/glace-bay">
                        Glace Bay
                    </Link></li>
                    <li> <Link to="/kentville">
                        Kentville
                    </Link></li>
                    <li> <Link to="/amherst">
                        Amherst
                    </Link></li>
                    <li> <Link to="/toronto">
                        Toronto
                    </Link></li>
                    <li> <Link to="/ottawa">
                        Ottawa
                    </Link></li>
                    <li> <Link to="/mississauga">
                        Mississauga
                    </Link></li>
                    <li> <Link to="/brampton">
                        Brampton
                    </Link></li>
                    <li> <Link to="/hamilton">
                        Hamilton
                    </Link></li>
                    <li> <Link to="/london">
                        London
                    </Link></li>
                    <li> <Link to="/markham">
                        Markham
                    </Link></li>
                    <li> <Link to="/vaughan">
                        Vaughan
                    </Link></li>
                    <li> <Link to="/kitchener">
                        Kitchener
                    </Link></li>
                    <li> <Link to="/windsor">
                        Windsor
                    </Link></li>
                    <li> <Link to="/charlottetown">
                        Charlottetown
                    </Link></li>
                    <li> <Link to="/summerside">
                        Summerside
                    </Link></li>
                    <li> <Link to="/stratford">
                        Stratford
                    </Link></li>
                    <li> <Link to="/cornwall">
                        Cornwall
                    </Link></li>
                    <li> <Link to="/montreal">
                        Montreal
                    </Link></li>
                    <li> <Link to="/quebec-city">
                        Quebec City
                    </Link></li>
                    <li> <Link to="/laval">
                        Laval
                    </Link></li>
                    <li> <Link to="/gatineau">
                        Gatineau
                    </Link></li>
                    <li> <Link to="/longueuil">
                        Longueuil
                    </Link></li>
                    <li> <Link to="/sherbrooke">
                        Sherbrooke
                    </Link></li>
                    <li> <Link to="/saguenay">
                        Saguenay
                    </Link></li>
                    <li> <Link to="/trois-rivieres">
                        Trois-Rivières
                    </Link></li>
                    <li> <Link to="/levis">
                        Lévis
                    </Link></li>
                    <li> <Link to="/terrebonne">
                        Terrebonne
                    </Link></li>
                    <li> <Link to="/saskatoon">
                        Saskatoon
                    </Link></li>
                    <li> <Link to="/regina">
                        Regina
                    </Link></li>
                    <li> <Link to="/prince-albert">
                        Prince Albert
                    </Link></li>
                    <li> <Link to="/moose-jaw">
                        Moose Jaw
                    </Link></li>
                    <li> <Link to="/yorkton">
                        Yorkton
                    </Link></li>
                    <li> <Link to="/swift-current">
                        Swift Current
                    </Link></li>
                    <li> <Link to="/north-battleford">
                        North Battleford
                    </Link></li>
                    <li> <Link to="/estevan">
                        Estevan
                    </Link></li>
                    <li> <Link to="/weyburn">
                        Weyburn
                    </Link></li>
                    <li> <Link to="/yellowknife">
                        Yellowknife
                    </Link></li>
                    <li> <Link to="/iqaluit">
                        Iqaluit
                    </Link></li>
                    <li> <Link to="/whitehorse">
                        Whitehorse
                    </Link></li>
                    <li> <Link to="/birmingham">
                        Birmingham
                    </Link></li>
                    <li> <Link to="/montgomery">
                        Montgomery
                    </Link></li>
                    <li> <Link to="/mobile">
                        Mobile
                    </Link></li>
                    <li> <Link to="/huntsville">
                        Huntsville
                    </Link></li>
                    <li> <Link to="/tuscaloosa">
                        Tuscaloosa
                    </Link></li>
                    <li> <Link to="/hoover">
                        Hoover
                    </Link></li>
                    <li> <Link to="/dothan">
                        Dothan
                    </Link></li>
                    <li> <Link to="/auburn">
                        Auburn
                    </Link></li>
                    <li> <Link to="/decatur">
                        Decatur
                    </Link></li>
                    <li> <Link to="/madison">
                        Madison
                    </Link></li>
                    <li> <Link to="/anchorage">
                        Anchorage
                    </Link></li>
                    <li> <Link to="/fairbanks">
                        Fairbanks
                    </Link></li>
                    <li> <Link to="/juneau">
                        Juneau
                    </Link></li>
                    <li> <Link to="/sitka">
                        Sitka
                    </Link></li>
                    <li> <Link to="/ketchikan">
                        Ketchikan
                    </Link></li>
                    <li> <Link to="/wasilla">
                        Wasilla
                    </Link></li>
                    <li> <Link to="/kenai">
                        Kenai
                    </Link></li>
                    <li> <Link to="/kodiak">
                        Kodiak
                    </Link></li>
                    <li> <Link to="/bethel">
                        Bethel
                    </Link></li>
                    <li> <Link to="/palmer">
                        Palmer
                    </Link></li>
                    <li> <Link to="/phoenix">
                        Phoenix
                    </Link></li>
                    <li> <Link to="/tucson">
                        Tucson
                    </Link></li>
                    <li> <Link to="/mesa">
                        Mesa
                    </Link></li>
                    <li> <Link to="/chandler">
                        Chandler
                    </Link></li>
                    <li> <Link to="/scottsdale">
                        Scottsdale
                    </Link></li>
                    <li> <Link to="/glendale">
                        Glendale
                    </Link></li>
                    <li> <Link to="/gilbert">
                        Gilbert
                    </Link></li>
                    <li> <Link to="/tempe">
                        Tempe
                    </Link></li>
                    <li> <Link to="/peoria">
                        Peoria
                    </Link></li>
                    <li> <Link to="/yuma">
                        Yuma
                    </Link></li>
                    <li> <Link to="/little-rock">
                        Little Rock
                    </Link></li>
                    <li> <Link to="/fort-smith">
                        Fort Smith
                    </Link></li>
                    <li> <Link to="/fayetteville">
                        Fayetteville
                    </Link></li>
                    <li> <Link to="/springdale">
                        Springdale
                    </Link></li>
                    <li> <Link to="/jonesboro">
                        Jonesboro
                    </Link></li>
                    <li> <Link to="/north-little-rock">
                        North Little Rock
                    </Link></li>
                    <li> <Link to="/conway">
                        Conway
                    </Link></li>
                    <li> <Link to="/rogers">
                        Rogers
                    </Link></li>
                    <li> <Link to="/bentonville">
                        Bentonville
                    </Link></li>
                    <li> <Link to="/pine-bluff">
                        Pine Bluff
                    </Link></li>
                    <li> <Link to="/los-angeles">
                        Los Angeles
                    </Link></li>
                    <li> <Link to="/san-diego">
                        San Diego
                    </Link></li>
                    <li> <Link to="/san-jose">
                        San Jose
                    </Link></li>
                    <li> <Link to="/san-francisco">
                        San Francisco
                    </Link></li>
                    <li> <Link to="/fresno">
                        Fresno
                    </Link></li>
                    <li> <Link to="/sacramento">
                        Sacramento
                    </Link></li>
                    <li> <Link to="/long-beach">
                        Long Beach
                    </Link></li>


                    <li> <Link to="/oakland">
                        Oakland
                    </Link></li>
                    <li> <Link to="/bakersfield">
                        Bakersfield
                    </Link></li>
                    <li> <Link to="/anaheim">
                        Anaheim
                    </Link></li>
                    <li> <Link to="/denver">
                        Denver
                    </Link></li>
                    <li> <Link to="/colorado-springs">
                        Colorado Springs
                    </Link></li>
                    <li> <Link to="/aurora">
                        Aurora
                    </Link></li>
                    <li> <Link to="/fort-collins">
                        Fort Collins
                    </Link></li>
                    <li> <Link to="/lakewood">
                        Lakewood
                    </Link></li>
                    <li> <Link to="/thornton">
                        Thornton
                    </Link></li>
                    <li> <Link to="/arvada">
                        Arvada
                    </Link></li>
                    <li> <Link to="/westminster">
                        Westminster
                    </Link></li>
                    <li> <Link to="/pueblo">
                        Pueblo
                    </Link></li>
                    <li> <Link to="/centennial">
                        Centennial
                    </Link></li>
                    <li> <Link to="/bridgeport">
                        Bridgeport
                    </Link></li>
                    <li> <Link to="/new-haven">
                        New Haven
                    </Link></li>
                    <li> <Link to="/stamford">
                        Stamford
                    </Link></li>
                    <li> <Link to="/hartford">
                        Hartford
                    </Link></li>
                    <li> <Link to="/waterbury">
                        Waterbury
                    </Link></li>
                    <li> <Link to="/norwalk">
                        Norwalk
                    </Link></li>
                    <li> <Link to="/danbury">
                        Danbury
                    </Link></li>
                    <li> <Link to="/new-britain">
                        New Britain
                    </Link></li>
                    <li> <Link to="/west-hartford">
                        West Hartford
                    </Link></li>
                    <li> <Link to="/bristol">
                        Bristol
                    </Link></li>
                    <li> <Link to="/wilmington">
                        Wilmington
                    </Link></li>
                    <li> <Link to="/dover">
                        Dover
                    </Link></li>
                    <li> <Link to="/newark">
                        Newark
                    </Link></li>
                    <li> <Link to="/middletown">
                        Middletown
                    </Link></li>
                    <li> <Link to="/smyrna">
                        Smyrna
                    </Link></li>
                    <li> <Link to="/milford">
                        Milford
                    </Link></li>
                    <li> <Link to="/seaford">
                        Seaford
                    </Link></li>
                    <li> <Link to="/georgetown">
                        Georgetown
                    </Link></li>
                    <li> <Link to="/elsmere">
                        Elsmere
                    </Link></li>
                    <li> <Link to="/laurel">
                        Laurel
                    </Link></li>
                    <li> <Link to="/jacksonville">
                        Jacksonville
                    </Link></li>
                    <li> <Link to="/miami">
                        Miami
                    </Link></li>
                    <li> <Link to="/tampa">
                        Tampa
                    </Link></li>
                    <li> <Link to="/orlando">
                        Orlando
                    </Link></li>
                    <li> <Link to="/st-petersburg">
                        St. Petersburg
                    </Link></li>
                    <li> <Link to="/hialeah">
                        Hialeah
                    </Link></li>
                    <li> <Link to="/port-st-lucie">
                        Port St. Lucie
                    </Link></li>
                    <li> <Link to="/cape-coral">
                        Cape Coral
                    </Link></li>
                    <li> <Link to="/fort-lauderdale">
                        Fort Lauderdale
                    </Link></li>
                    <li> <Link to="/tallahassee">
                        Tallahassee
                    </Link></li>
                    <li> <Link to="/atlanta">
                        Atlanta
                    </Link></li>
                    <li> <Link to="/augusta">
                        Augusta
                    </Link></li>
                    <li> <Link to="/columbus">
                        Columbus
                    </Link></li>
                    <li> <Link to="/savannah">
                        Savannah
                    </Link></li>
                    <li> <Link to="/athens">
                        Athens
                    </Link></li>
                    <li> <Link to="/sandy-springs">
                        Sandy Springs
                    </Link></li>
                    <li> <Link to="/roswell">
                        Roswell
                    </Link></li>
                    <li> <Link to="/macon">
                        Macon
                    </Link></li>
                    <li> <Link to="/johns-creek">
                        Johns Creek
                    </Link></li>
                    <li> <Link to="/albany">
                        Albany
                    </Link></li>
                    <li> <Link to="/honolulu">
                        Honolulu
                    </Link></li>
                    <li> <Link to="/hilo">
                        Hilo
                    </Link></li>
                    <li> <Link to="/kailua">
                        Kailua
                    </Link></li>
                    <li> <Link to="/kaneohe">
                        Kaneohe
                    </Link></li>
                    <li> <Link to="/waipahu">
                        Waipahu
                    </Link></li>
                    <li> <Link to="/pearl-city">
                        Pearl City
                    </Link></li>
                    <li> <Link to="/kahului">
                        Kahului
                    </Link></li>
                    <li> <Link to="/kihei">
                        Kihei
                    </Link></li>
                    <li> <Link to="/mililani-town">
                        Mililani Town
                    </Link></li>
                    <li> <Link to="/ewa-gentry">
                        Ewa Gentry
                    </Link></li>
                    <li> <Link to="/boise">
                        Boise
                    </Link></li>
                    <li> <Link to="/meridian">
                        Meridian
                    </Link></li>
                    <li> <Link to="/nampa">
                        Nampa
                    </Link></li>
                    <li> <Link to="/idaho-falls">
                        Idaho Falls
                    </Link></li>
                    <li> <Link to="/pocatello">
                        Pocatello
                    </Link></li>
                    <li> <Link to="/coeur-dalene">
                        Coeur d'Alene
                    </Link></li>
                    <li> <Link to="/twin-falls">
                        Twin Falls
                    </Link></li>
                    <li> <Link to="/caldwell">
                        Caldwell
                    </Link></li>
                    <li> <Link to="/lewiston">
                        Lewiston
                    </Link></li>
                    <li> <Link to="/post-falls">
                        Post Falls
                    </Link></li>
                    <li> <Link to="/chicago">
                        Chicago
                    </Link></li>
                    <li> <Link to="/naperville">
                        Naperville
                    </Link></li>
                    <li> <Link to="/joliet">
                        Joliet
                    </Link></li>
                    <li> <Link to="/rockford">
                        Rockford
                    </Link></li>
                    <li> <Link to="/springfield">
                        Springfield
                    </Link></li>
                    <li> <Link to="/elgin">
                        Elgin
                    </Link></li>
                    <li> <Link to="/champaign">
                        Champaign
                    </Link></li>
                    <li> <Link to="/waukegan">
                        Waukegan
                    </Link></li>
                    <li> <Link to="/indianapolis">
                        Indianapolis
                    </Link></li>
                    <li> <Link to="/fort-wayne">
                        Fort Wayne
                    </Link></li>
                    <li> <Link to="/evansville">
                        Evansville
                    </Link></li>
                    <li> <Link to="/south-bend">
                        South Bend
                    </Link></li>
                    <li> <Link to="/carmel">
                        Carmel
                    </Link></li>
                    <li> <Link to="/fishers">
                        Fishers
                    </Link></li>
                    <li> <Link to="/bloomington">
                        Bloomington
                    </Link></li>
                    <li> <Link to="/hammond">
                        Hammond
                    </Link></li>
                    <li> <Link to="/gary">
                        Gary
                    </Link></li>
                    <li> <Link to="/muncie">
                        Muncie
                    </Link></li>
                    <li> <Link to="/des-moines">
                        Des Moines
                    </Link></li>
                    <li> <Link to="/cedar-rapids">
                        Cedar Rapids
                    </Link></li>
                    <li> <Link to="/davenport">
                        Davenport
                    </Link></li>
                    <li> <Link to="/sioux-city">
                        Sioux City
                    </Link></li>
                    <li> <Link to="/iowa-city">
                        Iowa City
                    </Link></li>
                    <li> <Link to="/waterloo">
                        Waterloo
                    </Link></li>
                    <li> <Link to="/ames">
                        Ames
                    </Link></li>
                    <li> <Link to="/west-des-moines">
                        West Des Moines
                    </Link></li>
                    <li> <Link to="/ankeny">
                        Ankeny
                    </Link></li>


                    <li> <Link to="/dubuque">
                        Dubuque
                    </Link></li>
                    <li> <Link to="/wichita">
                        Wichita
                    </Link></li>
                    <li> <Link to="/overland-park">
                        Overland Park
                    </Link></li>
                    <li> <Link to="/kansas-city">
                        Kansas City
                    </Link></li>
                    <li> <Link to="/olathe">
                        Olathe
                    </Link></li>
                    <li> <Link to="/topeka">
                        Topeka
                    </Link></li>
                    <li> <Link to="/lawrence">
                        Lawrence
                    </Link></li>
                    <li> <Link to="/shawnee">
                        Shawnee
                    </Link></li>
                    <li> <Link to="/manhattan">
                        Manhattan
                    </Link></li>
                    <li> <Link to="/lenexa">
                        Lenexa
                    </Link></li>
                    <li> <Link to="/salina">
                        Salina
                    </Link></li>
                    <li> <Link to="/louisville">
                        Louisville
                    </Link></li>
                    <li> <Link to="/lexington">
                        Lexington
                    </Link></li>
                    <li> <Link to="/bowling-green">
                        Bowling Green
                    </Link></li>
                    <li> <Link to="/owensboro">
                        Owensboro
                    </Link></li>
                    <li> <Link to="/covington">
                        Covington
                    </Link></li>
                    <li> <Link to="/hopkinsville">
                        Hopkinsville
                    </Link></li>
                    <li> <Link to="/florence">
                        Florence
                    </Link></li>
                    <li> <Link to="/jeffersontown">
                        Jeffersontown
                    </Link></li>
                    <li> <Link to="/new-orleans">
                        New Orleans
                    </Link></li>
                    <li> <Link to="/baton-rouge">
                        Baton Rouge
                    </Link></li>
                    <li> <Link to="/shreveport">
                        Shreveport
                    </Link></li>
                    <li> <Link to="/lafayette">
                        Lafayette
                    </Link></li>
                    <li> <Link to="/lake-charles">
                        Lake Charles
                    </Link></li>
                    <li> <Link to="/kenner">
                        Kenner
                    </Link></li>
                    <li> <Link to="/bossier-city">
                        Bossier City
                    </Link></li>
                    <li> <Link to="/monroe">
                        Monroe
                    </Link></li>
                    <li> <Link to="/alexandria">
                        Alexandria
                    </Link></li>
                    <li> <Link to="/houma">
                        Houma
                    </Link></li>
                    <li> <Link to="/portland">
                        Portland
                    </Link></li>
                    <li> <Link to="/bangor">
                        Bangor
                    </Link></li>
                    <li> <Link to="/south-portland">
                        South Portland
                    </Link></li>
                    <li> <Link to="/biddeford">
                        Biddeford
                    </Link></li>
                    <li> <Link to="/saco">
                        Saco
                    </Link></li>
                    <li> <Link to="/brunswick">
                        Brunswick
                    </Link></li>
                    <li> <Link to="/westbrook">
                        Westbrook
                    </Link></li>
                    <li> <Link to="/baltimore">
                        Baltimore
                    </Link></li>
                    <li> <Link to="/columbia">
                        Columbia
                    </Link></li>
                    <li> <Link to="/germantown">
                        Germantown
                    </Link></li>
                    <li> <Link to="/silver-spring">
                        Silver Spring
                    </Link></li>
                    <li> <Link to="/waldorf">
                        Waldorf
                    </Link></li>
                    <li> <Link to="/glen-burnie">
                        Glen Burnie
                    </Link></li>
                    <li> <Link to="/frederick">
                        Frederick
                    </Link></li>
                    <li> <Link to="/ellicott-city">
                        Ellicott City
                    </Link></li>
                    <li> <Link to="/rockville">
                        Rockville
                    </Link></li>
                    <li> <Link to="/bethesda">
                        Bethesda
                    </Link></li>
                    <li> <Link to="/boston">
                        Boston
                    </Link></li>
                    <li> <Link to="/worcester">
                        Worcester
                    </Link></li>
                    <li> <Link to="/lowell">
                        Lowell
                    </Link></li>
                    <li> <Link to="/cambridge">
                        Cambridge
                    </Link></li>
                    <li> <Link to="/new-bedford">
                        New Bedford
                    </Link></li>
                    <li> <Link to="/brockton">
                        Brockton
                    </Link></li>
                    <li> <Link to="/quincy">
                        Quincy
                    </Link></li>
                    <li> <Link to="/lynn">
                        Lynn
                    </Link></li>
                    <li> <Link to="/fall-river">
                        Fall River
                    </Link></li>
                    <li> <Link to="/detroit">
                        Detroit
                    </Link></li>
                    <li> <Link to="/grand-rapids">
                        Grand Rapids
                    </Link></li>
                    <li> <Link to="/warren">
                        Warren
                    </Link></li>
                    <li> <Link to="/sterling-heights">
                        Sterling Heights
                    </Link></li>
                    <li> <Link to="/ann-arbor">
                        Ann Arbor
                    </Link></li>
                    <li> <Link to="/lansing">
                        Lansing
                    </Link></li>
                    <li> <Link to="/flint">
                        Flint
                    </Link></li>
                    <li> <Link to="/dearborn">
                        Dearborn
                    </Link></li>
                    <li> <Link to="/livonia">
                        Livonia
                    </Link></li>
                    <li> <Link to="/clinton-township">
                        Clinton Township
                    </Link></li>
                    <li> <Link to="/minneapolis">
                        Minneapolis
                    </Link></li>
                    <li> <Link to="/saint-paul">
                        Saint Paul
                    </Link></li>
                    <li> <Link to="/rochester">
                        Rochester
                    </Link></li>
                    <li> <Link to="/duluth">
                        Duluth
                    </Link></li>
                    <li> <Link to="/brooklyn-park">
                        Brooklyn Park
                    </Link></li>
                    <li> <Link to="/plymouth">
                        Plymouth
                    </Link></li>
                    <li> <Link to="/woodbury">
                        Woodbury
                    </Link></li>
                    <li> <Link to="/maple-grove">
                        Maple Grove
                    </Link></li>
                    <li> <Link to="/eagan">
                        Eagan
                    </Link></li>
                    <li> <Link to="/jackson">
                        Jackson
                    </Link></li>
                    <li> <Link to="/gulfport">
                        Gulfport
                    </Link></li>
                    <li> <Link to="/southaven">
                        Southaven
                    </Link></li>
                    <li> <Link to="/biloxi">
                        Biloxi
                    </Link></li>
                    <li> <Link to="/hattiesburg">
                        Hattiesburg
                    </Link></li>
                    <li> <Link to="/olive-branch">
                        Olive Branch
                    </Link></li>
                    <li> <Link to="/tupelo">
                        Tupelo
                    </Link></li>
                    <li> <Link to="/greenville">
                        Greenville
                    </Link></li>
                    <li> <Link to="/horn-lake">
                        Horn Lake
                    </Link></li>
                    <li> <Link to="/st-louis">
                        St. Louis
                    </Link></li>
                    <li> <Link to="/independence">
                        Independence
                    </Link></li>
                    <li> <Link to="/lees-summit">
                        Lee's Summit
                    </Link></li>
                    <li> <Link to="/ofallon">
                        O'Fallon
                    </Link></li>
                    <li> <Link to="/st-joseph">
                        St. Joseph
                    </Link></li>
                    <li> <Link to="/st-charles">
                        St. Charles
                    </Link></li>
                    <li> <Link to="/st-peters">
                        St. Peters
                    </Link></li>
                    <li> <Link to="/billings">
                        Billings
                    </Link></li>
                    <li> <Link to="/missoula">
                        Missoula
                    </Link></li>
                    <li> <Link to="/great-falls">
                        Great Falls
                    </Link></li>
                    <li> <Link to="/bozeman">
                        Bozeman
                    </Link></li>
                    <li> <Link to="/butte">
                        Butte
                    </Link></li>
                    <li> <Link to="/helena">
                        Helena
                    </Link></li>
                    <li> <Link to="/kalispell">
                        Kalispell
                    </Link></li>
                    <li> <Link to="/havre">
                        Havre
                    </Link></li>
                    <li> <Link to="/anaconda">
                        Anaconda
                    </Link></li>
                    <li> <Link to="/miles-city">
                        Miles City
                    </Link></li>

                    <li> <Link to="/omaha">
                        Omaha
                    </Link></li>
                    <li> <Link to="/lincoln">
                        Lincoln
                    </Link></li>
                    <li> <Link to="/bellevue">
                        Bellevue
                    </Link></li>
                    <li> <Link to="/grand-island">
                        Grand Island
                    </Link></li>
                    <li> <Link to="/kearney">
                        Kearney
                    </Link></li>
                    <li> <Link to="/fremont">
                        Fremont
                    </Link></li>
                    <li> <Link to="/hastings">
                        Hastings
                    </Link></li>
                    <li> <Link to="/north-platte">
                        North Platte
                    </Link></li>
                    <li> <Link to="/norfolk">
                        Norfolk
                    </Link></li>
                    <li> <Link to="/las-vegas">
                        Las Vegas
                    </Link></li>
                    <li> <Link to="/henderson">
                        Henderson
                    </Link></li>
                    <li> <Link to="/reno">
                        Reno
                    </Link></li>
                    <li> <Link to="/north-las-vegas">
                        North Las Vegas
                    </Link></li>
                    <li> <Link to="/sparks">
                        Sparks
                    </Link></li>
                    <li> <Link to="/manchester">
                        Manchester
                    </Link></li>
                    <li> <Link to="/nashua">
                        Nashua
                    </Link></li>
                    <li> <Link to="/concord">
                        Concord
                    </Link></li>
                    <li> <Link to="/jersey-city">
                        Jersey City
                    </Link></li>
                    <li> <Link to="/paterson">
                        Paterson
                    </Link></li>
                    <li> <Link to="/elizabeth">
                        Elizabeth
                    </Link></li>
                    <li> <Link to="/edison">
                        Edison
                    </Link></li>
                    <li> <Link to="/albuquerque">
                        Albuquerque
                    </Link></li>
                    <li> <Link to="/las-cruces">
                        Las Cruces
                    </Link></li>
                    <li> <Link to="/rio-rancho">
                        Rio Rancho
                    </Link></li>
                    <li> <Link to="/santa-fe">
                        Santa Fe
                    </Link></li>
                    <li> <Link to="/new-york-city">
                        New York City
                    </Link></li>
                    <li> <Link to="/buffalo">
                        Buffalo
                    </Link></li>
                    <li> <Link to="/yonkers">
                        Yonkers
                    </Link></li>
                    <li> <Link to="/syracuse">
                        Syracuse
                    </Link></li>
                    <li> <Link to="/charlotte">
                        Charlotte
                    </Link></li>
                    <li> <Link to="/raleigh">
                        Raleigh
                    </Link></li>
                    <li> <Link to="/greensboro">
                        Greensboro
                    </Link></li>
                    <li> <Link to="/durham">
                        Durham
                    </Link></li>
                    <li> <Link to="/winston-salem">
                        Winston-Salem
                    </Link></li>
                    <li> <Link to="/fargo">
                        Fargo
                    </Link></li>
                    <li> <Link to="/bismarck">
                        Bismarck
                    </Link></li>
                    <li> <Link to="/grand-forks">
                        Grand Forks
                    </Link></li>
                    <li> <Link to="/minot">
                        Minot
                    </Link></li>
                    <li> <Link to="/west-fargo">
                        West Fargo
                    </Link></li>
                    <li> <Link to="/cleveland">
                        Cleveland
                    </Link></li>
                    <li> <Link to="/cincinnati">
                        Cincinnati
                    </Link></li>
                    <li> <Link to="/toledo">
                        Toledo
                    </Link></li>
                    <li> <Link to="/akron">
                        Akron
                    </Link></li>
                    <li> <Link to="/oklahoma-city">
                        Oklahoma City
                    </Link></li>
                    <li> <Link to="/tulsa">
                        Tulsa
                    </Link></li>
                    <li> <Link to="/norman">
                        Norman
                    </Link></li>
                    <li> <Link to="/broken-arrow">
                        Broken Arrow
                    </Link></li>
                    <li> <Link to="/lawton">
                        Lawton
                    </Link></li>
                    <li> <Link to="/salem">
                        Salem
                    </Link></li>
                    <li> <Link to="/eugene">
                        Eugene
                    </Link></li>
                    <li> <Link to="/gresham">
                        Gresham
                    </Link></li>
                    <li> <Link to="/hillsboro">
                        Hillsboro
                    </Link></li>
                    <li> <Link to="/philadelphia">
                        Philadelphia
                    </Link></li>
                    <li> <Link to="/pittsburgh">
                        Pittsburgh
                    </Link></li>
                    <li> <Link to="/allentown">
                        Allentown
                    </Link></li>
                    <li> <Link to="/erie">
                        Erie
                    </Link></li>
                    <li> <Link to="/reading">
                        Reading
                    </Link></li>
                    <li> <Link to="/providence">
                        Providence
                    </Link></li>
                    <li> <Link to="/warwick">
                        Warwick
                    </Link></li>
                    <li> <Link to="/cranston">
                        Cranston
                    </Link></li>
                    <li> <Link to="/pawtucket">
                        Pawtucket
                    </Link></li>
                    <li> <Link to="/east-providence">
                        East Providence
                    </Link></li>
                    <li> <Link to="/charleston">
                        Charleston
                    </Link></li>
                    <li> <Link to="/north-charleston">
                        North Charleston
                    </Link></li>
                    <li> <Link to="/mount-pleasant">
                        Mount Pleasant
                    </Link></li>
                    <li> <Link to="/rock-hill">
                        Rock Hill
                    </Link></li>
                    <li> <Link to="/sioux-falls">
                        Sioux Falls
                    </Link></li>
                    <li> <Link to="/rapid-city">
                        Rapid City
                    </Link></li>
                    <li> <Link to="/aberdeen">
                        Aberdeen
                    </Link></li>
                    <li> <Link to="/brookings">
                        Brookings
                    </Link></li>
                    <li> <Link to="/watertown">
                        Watertown
                    </Link></li>
                    <li> <Link to="/nashville">
                        Nashville
                    </Link></li>
                    <li> <Link to="/memphis">
                        Memphis
                    </Link></li>
                    <li> <Link to="/knoxville">
                        Knoxville
                    </Link></li>
                    <li> <Link to="/chattanooga">
                        Chattanooga
                    </Link></li>
                    <li> <Link to="/clarksville">
                        Clarksville
                    </Link></li>
                    <li> <Link to="/houston">
                        Houston
                    </Link></li>
                    <li> <Link to="/san-antonio">
                        San Antonio
                    </Link></li>
                    <li> <Link to="/dallas">
                        Dallas
                    </Link></li>
                    <li> <Link to="/austin">
                        Austin
                    </Link></li>
                    <li> <Link to="/fort-worth">
                        Fort Worth
                    </Link></li>
                    <li> <Link to="/salt-lake-city">
                        Salt Lake City
                    </Link></li>
                    <li> <Link to="/west-valley-city">
                        West Valley City
                    </Link></li>
                    <li> <Link to="/provo">
                        Provo
                    </Link></li>
                    <li> <Link to="/west-jordan">
                        West Jordan
                    </Link></li>
                    <li> <Link to="/orem">
                        Orem
                    </Link></li>
                    <li> <Link to="/burlington">
                        Burlington
                    </Link></li>
                    <li> <Link to="/south-burlington">
                        South Burlington
                    </Link></li>
                    <li> <Link to="/rutland">
                        Rutland
                    </Link></li>
                    <li> <Link to="/barre">
                        Barre
                    </Link></li>
                    <li> <Link to="/montpelier">
                        Montpelier
                    </Link></li>
                    <li> <Link to="/virginia-beach">
                        Virginia Beach
                    </Link></li>
                    <li> <Link to="/chesapeake">
                        Chesapeake
                    </Link></li>
                    <li> <Link to="/newport-news">
                        Newport News
                    </Link></li>
                    <li> <Link to="/seattle">
                        Seattle
                    </Link></li>
                    <li> <Link to="/spokane">
                        Spokane
                    </Link></li>
                    <li> <Link to="/tacoma">
                        Tacoma
                    </Link></li>
                    <li> <Link to="/huntington">
                        Huntington
                    </Link></li>
                    <li> <Link to="/morgantown">
                        Morgantown
                    </Link></li>
                    <li> <Link to="/parkersburg">
                        Parkersburg
                    </Link></li>

                    <li> <Link to="/wheeling">
                        Wheeling
                    </Link></li>
                    <li> <Link to="/milwaukee">
                        Milwaukee
                    </Link></li>
                    <li> <Link to="/green-bay">
                        Green Bay
                    </Link></li>
                    <li> <Link to="/kenosha">
                        Kenosha
                    </Link></li>
                    <li> <Link to="/racine">
                        Racine
                    </Link></li>
                    <li> <Link to="/cheyenne">
                        Cheyenne
                    </Link></li>
                    <li> <Link to="/casper">
                        Casper
                    </Link></li>
                    <li> <Link to="/laramie">
                        Laramie
                    </Link></li>
                    <li> <Link to="/gillette">
                        Gillette
                    </Link></li>
                    <li> <Link to="/rock-springs">
                        Rock Springs
                    </Link></li>
                    <li> <Link to="/selma">
                        Selma
                    </Link></li>
                    <li> <Link to="/eufaula">
                        Eufaula
                    </Link></li>
                    <li> <Link to="/fairhope">
                        Fairhope
                    </Link></li>
                    <li> <Link to="/opelika">
                        Opelika
                    </Link></li>
                    <li> <Link to="/barrow">
                        Barrow
                    </Link></li>
                    <li> <Link to="/nome">
                        Nome
                    </Link></li>
                    <li> <Link to="/safford">
                        Safford
                    </Link></li>
                    <li> <Link to="/winslow">
                        Winslow
                    </Link></li>
                    <li> <Link to="/page">
                        Page
                    </Link></li>
                    <li> <Link to="/camp-verde">
                        Camp Verde
                    </Link></li>
                    <li> <Link to="/kingman">
                        Kingman
                    </Link></li>
                    <li> <Link to="/eureka-springs">
                        Eureka Springs
                    </Link></li>
                    <li> <Link to="/batesville">
                        Batesville
                    </Link></li>
                    <li> <Link to="/monticello">
                        Monticello
                    </Link></li>
                    <li> <Link to="/harrison">
                        Harrison
                    </Link></li>
                    <li> <Link to="/monterey">
                        Monterey
                    </Link></li>
                    <li> <Link to="/sonoma">
                        Sonoma
                    </Link></li>
                    <li> <Link to="/placerville">
                        Placerville
                    </Link></li>
                    <li> <Link to="/truckee">
                        Truckee
                    </Link></li>
                    <li> <Link to="/aspen">
                        Aspen
                    </Link></li>
                    <li> <Link to="/durango">
                        Durango
                    </Link></li>
                    <li> <Link to="/salida">
                        Salida
                    </Link></li>
                    <li> <Link to="/estes-park">
                        Estes Park
                    </Link></li>
                    <li> <Link to="/telluride">
                        Telluride
                    </Link></li>
                    <li> <Link to="/kissimmee">
                        Kissimmee
                    </Link></li>
                    <li> <Link to="/sanford">
                        Sanford
                    </Link></li>
                    <li> <Link to="/deland">
                        DeLand
                    </Link></li>
                    <li> <Link to="/sebring">
                        Sebring
                    </Link></li>
                    <li> <Link to="/starke">
                        Starke
                    </Link></li>
                    <li> <Link to="/rome">
                        Rome
                    </Link></li>
                    <li> <Link to="/blairsville">
                        Blairsville
                    </Link></li>
                    <li> <Link to="/hiawassee">
                        Hiawassee
                    </Link></li>
                    <li> <Link to="/toccoa">
                        Toccoa
                    </Link></li>
                    <li> <Link to="/milledgeville">
                        Milledgeville
                    </Link></li>
                    <li> <Link to="/fredericksburg">
                        Fredericksburg
                    </Link></li>
                    <li> <Link to="/boerne">
                        Boerne
                    </Link></li>
                    <li> <Link to="/marfa">
                        Marfa
                    </Link></li>
                    <li> <Link to="/brenham">
                        Brenham
                    </Link></li>
                    <li> <Link to="/port-aransas">
                        Port Aransas
                    </Link></li>


                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default MarketareaNamericastateandcity;
