import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Modalform({ showModal, handleClose }) {
  const navigate = useNavigate();

  // State for captcha values and user input
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  // Generate new captcha values whenever the modal is shown
  useEffect(() => {
    if (showModal) {
      // For example: Generate a random number between 50 and 60 for num1,
      // and a random number between 1 and 10 for num2.
      // You can adjust these ranges as needed.
      const random1 = Math.floor(Math.random() * 11) + 50; // 50-60
      const random2 = Math.floor(Math.random() * 10) + 1;   // 1-10
      setNum1(random1);
      setNum2(random2);
      setCaptchaInput("");
      setCaptchaError("");
    }
  }, [showModal]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the captcha answer
    if (parseInt(captchaInput, 10) !== num1 + num2) {
      setCaptchaError("Captcha answer is incorrect. Please try again.");
      return; // Do not proceed if captcha is wrong
    }

    const url =
      "https://script.google.com/macros/s/AKfycbwa4ZjE2HqP1Ql6nVTZdK41X5NLA1Evkuqzcd9xYyo16olKCI_1RwTgjZKRsWjb20NRYg/exec";

    const formData = new URLSearchParams({
      Name: e.target.name.value,
      Email: e.target.email.value,
      Phoneno: e.target.phoneno.value,
      Whatsapp: e.target.whatsapp.value,
      Location: e.target.location.value,
      PageUrl: e.target.PageUrl.value,
      Massage: e.target.massage.value,
    });

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formData.toString(),
    })
      .then((res) => res.text())
      .then((data) => {
        // Navigate to the Thank You page on successful submission
        navigate("/Thankyou");
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal  show={showModal} onHide={handleClose}>
      <form style={{ background: '#f99d1b',}} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>We Can Help You Reach Your Dream Customer. Get in Touch.</Modal.Title>
        </Modal.Header>
        <Modal.Body>


<div className="row">

<div className="col-6">
<input
            name="name"
            className="form-control"
            placeholder="Name"
          />
</div>
<div className="col-6">
<input
            name="email"
            className="form-control"
            placeholder="Email"
          />
</div>

<div className="col-6 mt-3">
  
<input
            name="phoneno"
            className="form-control"
            placeholder="Phone"
          />
</div>

<div className="col-6 mt-3 ">
<input
            name="whatsapp"
            className="form-control"
            placeholder="Whatsapp"
          />
</div>
</div>

      
         <br />
        
       
       
          <input
            name="location"
            className="form-control"
            placeholder="Location"
          />
           <input
            type="Hidden"
            name="PageUrl"
            value={window.location.href}
          />
          <br />
          <textarea
            name="massage"
            className="form-control"
            rows="4"
            placeholder="Message"
          ></textarea>
          <br />
          {/* Captcha Field */}
          <div>
            <label>
              {num1} + {num2} = ?
            </label>
            <input
              type="text"
              name="captcha"
              className="form-control"
              placeholder="Enter captcha answer"
              value={captchaInput}
              onChange={(e) => setCaptchaInput(e.target.value)}
            />
            {captchaError && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {captchaError}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default Modalform;
