import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function MarketareaMiddleeaststateandcity() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>Middle East State and City</h2>
                    <ul class="state-list">
                 
                    <li> <Link to="/bahrain">
                        Bahrain
                    </Link></li>
                    <li> <Link to="/jordan">
                        Jordan
                    </Link></li>
                    <li> <Link to="/kuwait">
                        Kuwait
                    </Link></li>
                    <li> <Link to="/lebanon">
                        Lebanon
                    </Link></li>
                    <li> <Link to="/oman">
                        Oman
                    </Link></li>
                    <li> <Link to="/qatar">
                        Qatar
                    </Link></li>
                    <li> <Link to="/saudi-arabia">
                        Saudi Arabia
                    </Link></li>
                    <li> <Link to="/uae">
                        UAE
                    </Link></li>
                    <li> <Link to="/cyprus">
                        Cyprus
                    </Link></li>
                    <li> <Link to="/israel">
                        Israel
                    </Link></li>
                    <li> <Link to="/manama">
                        Manama
                    </Link></li>
                    <li> <Link to="/riffa">
                        Riffa
                    </Link></li>
                    <li> <Link to="/muharraq">
                        Muharraq
                    </Link></li>
                    <li> <Link to="/amman">
                        Amman
                    </Link></li>
                    <li> <Link to="/zarqa">
                        Zarqa
                    </Link></li>
                    <li> <Link to="/irbid">
                        Irbid
                    </Link></li>
                    <li> <Link to="/kuwait-city">
                        Kuwait City
                    </Link></li>
                    <li> <Link to="/hawally">
                        Hawally
                    </Link></li>
                    <li> <Link to="/salmiya">
                        Salmiya
                    </Link></li>
                    <li> <Link to="/beirut">
                        Beirut
                    </Link></li>
                    <li> <Link to="/tripoli">
                        Tripoli
                    </Link></li>
                    <li> <Link to="/sidon">
                        Sidon
                    </Link></li>
                    <li> <Link to="/muscat">
                        Muscat
                    </Link></li>
                    <li> <Link to="/salalah">
                        Salalah
                    </Link></li>
                    <li> <Link to="/sohar">
                        Sohar
                    </Link></li>
                    <li> <Link to="/doha">
                        Doha
                    </Link></li>
                    <li> <Link to="/al-wakrah">
                        Al Wakrah
                    </Link></li>
                    <li> <Link to="/al-rayyan">
                        Al Rayyan
                    </Link></li>
                    <li> <Link to="/riyadh">
                        Riyadh
                    </Link></li>
                    <li> <Link to="/jeddah">
                        Jeddah
                    </Link></li>
                    <li> <Link to="/dammam">
                        Dammam
                    </Link></li>
                    <li> <Link to="/dubai">
                        Dubai
                    </Link></li>
                    <li> <Link to="/abu-dhabi">
                        Abu Dhabi
                    </Link></li>
                    <li> <Link to="/sharjah">
                        Sharjah
                    </Link></li>
                    <li> <Link to="/nicosia">
                        Nicosia
                    </Link></li>
                    <li> <Link to="/limassol">
                        Limassol
                    </Link></li>
                    <li> <Link to="/larnaca">
                        Larnaca
                    </Link></li>
                    <li> <Link to="/tel-aviv">
                        Tel Aviv
                    </Link></li>
                    <li> <Link to="/jerusalem">
                        Jerusalem
                    </Link></li>
                    <li> <Link to="/haifa">
                        Haifa
                    </Link></li>


                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default MarketareaMiddleeaststateandcity;
