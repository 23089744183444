import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function Marketareaafrica() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio">
            <div class="tab-content mt-3">
                <div id="Arica" class=" portfolio container">
                    <h2>Africa State & City {currentLocation}</h2>
                    <ul class="state-list">
                        <li>  <Link to="/africa" >
                            Africa
                        </Link></li>


                        <li> <Link to="/kenya">
                            Kenya
                        </Link></li>
                        <li> <Link to="/tanzania">
                            Tanzania
                        </Link></li>
                        <li> <Link to="/uganda">
                            Uganda
                        </Link></li>
                        <li> <Link to="/ethiopia">
                            Ethiopia
                        </Link></li>
                        <li> <Link to="/egypt">
                            Egypt
                        </Link></li>
                        <li> <Link to="/libya">
                            Libya
                        </Link></li>
                        <li> <Link to="/morocco">
                            Morocco
                        </Link></li>
                        <li> <Link to="/sudan">
                            Sudan
                        </Link></li>
                        <li> <Link to="/tunisia">
                            Tunisia
                        </Link></li>
                        <li> <Link to="/botswana">
                            Botswana
                        </Link></li>
                        <li> <Link to="/eswatini">
                            Eswatini
                        </Link></li>
                        <li> <Link to="/lesotho">
                            Lesotho
                        </Link></li>
                        <li> <Link to="/malawi">
                            Malawi
                        </Link></li>
                        <li> <Link to="/namibia">
                            Namibia
                        </Link></li>
                        <li> <Link to="/south-africa">
                            South Africa
                        </Link></li>
                        <li> <Link to="/zambia">
                            Zambia
                        </Link></li>
                        <li> <Link to="/zimbabwe">
                            Zimbabwe
                        </Link></li>
                        <li> <Link to="/cameroon">
                            Cameroon
                        </Link></li>
                        <li> <Link to="/gambia">
                            Gambia
                        </Link></li>
                        <li> <Link to="/ghana">
                            Ghana
                        </Link></li>
                        <li> <Link to="/liberia">
                            Liberia
                        </Link></li>
                        <li> <Link to="/nigeria">
                            Nigeria
                        </Link></li>
                        <li> <Link to="/sierra-leone">
                            Sierra Leone
                        </Link></li>
                        <li> <Link to="/nairobi">
                            Nairobi
                        </Link></li>
                        <li> <Link to="/mombasa">
                            Mombasa
                        </Link></li>
                        <li> <Link to="/kisumu">
                            Kisumu
                        </Link></li>
                        <li> <Link to="/nakuru">
                            Nakuru
                        </Link></li>
                        <li> <Link to="/dar-es-salaam">
                            Dar es Salaam
                        </Link></li>
                        <li> <Link to="/dodoma">
                            Dodoma
                        </Link></li>
                        <li> <Link to="/arusha">
                            Arusha
                        </Link></li>
                        <li> <Link to="/mwanza">
                            Mwanza
                        </Link></li>
                        <li> <Link to="/kampala">
                            Kampala
                        </Link></li>
                        <li> <Link to="/entebbe">
                            Entebbe
                        </Link></li>
                        <li> <Link to="/jinja">
                            Jinja
                        </Link></li>
                        <li> <Link to="/mbarara">
                            Mbarara
                        </Link></li>
                        <li> <Link to="/addis-ababa">
                            Addis Ababa
                        </Link></li>
                        <li> <Link to="/dire-dawa">
                            Dire Dawa
                        </Link></li>
                        <li> <Link to="/mekelle">
                            Mekelle
                        </Link></li>
                        <li> <Link to="/gondar">
                            Gondar
                        </Link></li>
                        <li> <Link to="/cairo">
                            Cairo
                        </Link></li>
                        <li> <Link to="/alexandria">
                            Alexandria
                        </Link></li>
                        <li> <Link to="/giza">
                            Giza
                        </Link></li>
                        <li> <Link to="/shubra-el-kheima">
                            Shubra El-Kheima
                        </Link></li>
                        <li> <Link to="/tripoli">
                            Tripoli
                        </Link></li>
                        <li> <Link to="/benghazi">
                            Benghazi
                        </Link></li>
                        <li> <Link to="/misrata">
                            Misrata
                        </Link></li>
                        <li> <Link to="/casablanca">
                            Casablanca
                        </Link></li>
                        <li> <Link to="/rabat">
                            Rabat
                        </Link></li>
                        <li> <Link to="/marrakech">
                            Marrakech
                        </Link></li>
                        <li> <Link to="/fes">
                            Fes
                        </Link></li>
                        <li> <Link to="/khartoum">
                            Khartoum
                        </Link></li>
                        <li> <Link to="/omdurman">
                            Omdurman
                        </Link></li>
                        <li> <Link to="/port-sudan">
                            Port Sudan
                        </Link></li>
                        <li> <Link to="/tunis">
                            Tunis
                        </Link></li>
                        <li> <Link to="/sfax">
                            Sfax
                        </Link></li>
                        <li> <Link to="/sousse">
                            Sousse
                        </Link></li>
                        <li> <Link to="/kairouan">
                            Kairouan
                        </Link></li>
                        <li> <Link to="/gaborone">
                            Gaborone
                        </Link></li>
                        <li> <Link to="/francistown">
                            Francistown
                        </Link></li>
                        <li> <Link to="/molepolole">
                            Molepolole
                        </Link></li>
                        <li> <Link to="/mbabane">
                            Mbabane
                        </Link></li>
                        <li> <Link to="/manzini">
                            Manzini
                        </Link></li>
                        <li> <Link to="/siteki">
                            Siteki
                        </Link></li>
                        <li> <Link to="/maseru">
                            Maseru
                        </Link></li>
                        <li> <Link to="/teyateyaneng">
                            Teyateyaneng
                        </Link></li>
                        <li> <Link to="/mafeteng">
                            Mafeteng
                        </Link></li>
                        <li> <Link to="/lilongwe">
                            Lilongwe
                        </Link></li>
                        <li> <Link to="/blantyre">
                            Blantyre
                        </Link></li>
                        <li> <Link to="/mzuzu">
                            Mzuzu
                        </Link></li>
                        <li> <Link to="/windhoek">
                            Windhoek
                        </Link></li>
                        <li> <Link to="/swakopmund">
                            Swakopmund
                        </Link></li>
                        <li> <Link to="/walvis-bay">
                            Walvis Bay
                        </Link></li>
                        <li> <Link to="/johannesburg">
                            Johannesburg
                        </Link></li>
                        <li> <Link to="/cape-town">
                            Cape Town
                        </Link></li>
                        <li> <Link to="/durban">
                            Durban
                        </Link></li>
                        <li> <Link to="/pretoria">
                            Pretoria
                        </Link></li>
                        <li> <Link to="/lusaka">
                            Lusaka
                        </Link></li>
                        <li> <Link to="/ndola">
                            Ndola
                        </Link></li>
                        <li> <Link to="/kitwe">
                            Kitwe
                        </Link></li>
                        <li> <Link to="/harare">
                            Harare
                        </Link></li>
                        <li> <Link to="/bulawayo">
                            Bulawayo
                        </Link></li>
                        <li> <Link to="/mutare">
                            Mutare
                        </Link></li>
                        <li> <Link to="/douala">
                            Douala
                        </Link></li>
                        <li> <Link to="/yaounde">
                            Yaoundé
                        </Link></li>
                        <li> <Link to="/garoua">
                            Garoua
                        </Link></li>
                        <li> <Link to="/banjul">
                            Banjul
                        </Link></li>
                        <li> <Link to="/serekunda">
                            Serekunda
                        </Link></li>
                        <li> <Link to="/brikama">
                            Brikama
                        </Link></li>
                        <li> <Link to="/accra">
                            Accra
                        </Link></li>
                        <li> <Link to="/kumasi">
                            Kumasi
                        </Link></li>
                        <li> <Link to="/tamale">
                            Tamale
                        </Link></li>
                        <li> <Link to="/takoradi">
                            Takoradi
                        </Link></li>
                        <li> <Link to="/monrovia">
                            Monrovia
                        </Link></li>
                        <li> <Link to="/gbarnga">
                            Gbarnga
                        </Link></li>
                        <li> <Link to="/kakata">
                            Kakata
                        </Link></li>
                        <li> <Link to="/lagos">
                            Lagos
                        </Link></li>
                        <li> <Link to="/abuja">
                            Abuja
                        </Link></li>
                        <li> <Link to="/port-harcourt">
                            Port Harcourt
                        </Link></li>
                        <li> <Link to="/ibadan">
                            Ibadan
                        </Link></li>
                        <li> <Link to="/freetown">
                            Freetown
                        </Link></li>
                        <li> <Link to="/bo">
                            Bo
                        </Link></li>
                        <li> <Link to="/kenema">
                            Kenema
                        </Link></li>


                    </ul>

                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default Marketareaafrica;
