import React, { useState } from 'react';
import Modalform from './Modalform';
import { useLocation, Link } from 'react-router-dom';

function Footer() {
    const [showModal, setShowModal] = useState(false);

    // Functions to handle modal open/close
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const location = useLocation();
    const pathSegments = location.pathname.split("/"); // Split URL path into parts
    const locationName = pathSegments[1] || "Home"; // Get first segment after domain

    // List of locations where phone number & address should change
    const specialLocations = [
        "sydney", "melbourne", "brisbane", "perth", "canberra", "australia"
    ];

    // Default contact details
    let phoneNumber = "+919266355565";
    let whatsappno = "+919266355565";
    let address = "2F-CS-53, Ansal Corporate Plaza, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh 201012";

    // Change details if location matches special locations
    if (specialLocations.includes(locationName.toLowerCase())) {
        phoneNumber = "+61490444230";
        whatsappno = "+61490444230";
        address = "32/60 John Gorton Drive Coombs, Australian Capital Territory 2611";
    }

    return (
        <footer id="footer"> 
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        {/* Company Info Section */}
                        <div className="col-lg-3 col-md-6 footer-info">
                            <img src={require("./images/digics pvt ltd white-01.png")} alt="Digics Pvt Ltd" className="lazyloaded" />
                            <p>
                                Digics Private Limited is a one-stop service provider for digital marketing. 
                                Our services can be scaled to meet the needs of businesses of all sizes, from startups to 
                                large enterprises. Partner with Digics Private Limited to unlock the full potential of 
                                your business's digital marketing.
                            </p>
                        </div>

                        {/* Useful Links Section */}
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/disclaimer">Disclaimer</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                <li><Link to="/refund-policy">Refund Policy</Link></li>
                                <li><Link to="/marketarea/india">Market Area</Link></li>
                            </ul>
                        </div>

                        {/* Services Section */}
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul id="services-list">
                                <li><Link to="/india/best-digital-marketing-services">Digital Marketing</Link></li>
                                <li><Link to="/india/best-facebook-ads-manager">Facebook Advertising Management</Link></li>
                                <li><Link to="/india/top-web-development-services">Web Development</Link></li>
                                <li><Link to="/india/best-b2b-lead-generation-services">B2B Lead Generation</Link></li>
                                <li><Link to="/india/best-lead-generation-services">Lead Generation</Link></li>
                                <li><Link to="/india/best-programmatic-advertising-services">Programmatic Advertising</Link></li>
                                <li><Link to="/india/best-ppc-marketing-services">PPC Marketing</Link></li>
                                <li><Link to="/india/top-web-designing-services">Web Designing</Link></li>
                                <li><Link to="/india/best-youtube-advertising-services">YouTube Advertising</Link></li>
                                <li><Link to="/india/top-linkedin-advertising-services">LinkedIn Advertising</Link></li>
                            </ul>
                        </div>

                        {/* Contact Section */}
                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                {address} <br />
                                <strong>Phone:</strong> <a href={`tel:${phoneNumber}`}>{phoneNumber}</a><br />
                                <strong>Email:</strong> <a href="mailto:hello@digics.in">hello@digics.in</a><br />
                            </p>
                            <div className="social-links">
                                <a href={`https://wa.me/${whatsappno}`} className="whatsapp"><i className="bi bi-whatsapp"></i></a>
                                <a href="https://www.youtube.com/channel/UCH5k494d6K5H1U0wIOICydA" className="youtube"><i className="bi bi-youtube"></i></a>
                                <a href="https://www.facebook.com/Digics.in/" className="facebook"><i className="bi bi-facebook"></i></a>
                                <a href="https://www.instagram.com/digicsmkt/" className="instagram"><i className="bi bi-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/digicsin/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-menu p-0 d-md-none d-block mobile-cart">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-3 p-2" style={{ display: "flex", background: "#f99d1b" }}>
                            <a href={`tel:${phoneNumber}`} style={{ display: "flex", alignItems: "center", color: "#000", margin: "auto" }}>
                                <i className="bi bi-phone font26"></i>
                            </a>
                        </div>
                        <div className="col-6 p-2" style={{ background: "#000" }}>
                            <a onClick={handleShow} style={{ display: "flex", alignItems: "center", color: "#fff", margin: "auto 20px" }}>
                                <h5 className="mt-2">Enquiry Now</h5>
                            </a>
                        </div>
                        <div className="col-3 p-2">
                            <a href={`https://wa.me/${whatsappno}`} style={{ display: "flex", alignItems: "center", color: "#000", margin: "auto 18px" }}>
                                <i className="bi bi-whatsapp font26"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright credits">
                    © Copyright <strong><Link to="/">Digics</Link></strong>. All Rights Reserved
                </div>
            </div>
            
            <Modalform showModal={showModal} handleClose={handleClose} />
        </footer>
    );
}

export default Footer;
