import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function MarketareaEUstateandcity() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>EU State and City</h2>
                    <ul class="state-list">
                 
                    <li> <Link to="/ireland">
                        Ireland
                    </Link></li>
                    <li> <Link to="/france">
                        France
                    </Link></li>
                    <li> <Link to="/germany">
                        Germany
                    </Link></li>
                    <li> <Link to="/liechtenstein">
                        Liechtenstein
                    </Link></li>
                    <li> <Link to="/luxembourg">
                        Luxembourg
                    </Link></li>
                    <li> <Link to="/switzerland">
                        Switzerland
                    </Link></li>
                    <li> <Link to="/united-kingdom">
                        United Kingdom
                    </Link></li>
                    <li> <Link to="/italy">
                        Italy
                    </Link></li>
                    <li> <Link to="/czech-republic">
                        Czech Republic
                    </Link></li>
                    <li> <Link to="/malta">
                        Malta
                    </Link></li>
                    <li> <Link to="/dublin">
                        Dublin
                    </Link></li>
                    <li> <Link to="/cork">
                        Cork
                    </Link></li>
                    <li> <Link to="/limerick">
                        Limerick
                    </Link></li>
                    <li> <Link to="/paris">
                        Paris
                    </Link></li>
                    <li> <Link to="/lyon">
                        Lyon
                    </Link></li>
                    <li> <Link to="/marseille">
                        Marseille
                    </Link></li>
                    <li> <Link to="/toulouse">
                        Toulouse
                    </Link></li>
                    <li> <Link to="/berlin">
                        Berlin
                    </Link></li>
                    <li> <Link to="/munich">
                        Munich
                    </Link></li>
                    <li> <Link to="/frankfurt">
                        Frankfurt
                    </Link></li>
                    <li> <Link to="/hamburg">
                        Hamburg
                    </Link></li>
                    <li> <Link to="/vaduz">
                        Vaduz
                    </Link></li>
                    <li> <Link to="/schaan">
                        Schaan
                    </Link></li>
                    <li> <Link to="/luxembourg-city">
                        Luxembourg City
                    </Link></li>
                    <li> <Link to="/esch-sur-alzette">
                        Esch-sur-Alzette
                    </Link></li>
                    <li> <Link to="/zurich">
                        Zurich
                    </Link></li>
                    <li> <Link to="/geneva">
                        Geneva
                    </Link></li>
                    <li> <Link to="/bern">
                        Bern
                    </Link></li>
                    <li> <Link to="/basel">
                        Basel
                    </Link></li>
                    <li> <Link to="/london">
                        London
                    </Link></li>
                    <li> <Link to="/manchester">
                        Manchester
                    </Link></li>
                    <li> <Link to="/birmingham">
                        Birmingham
                    </Link></li>
                    <li> <Link to="/glasgow">
                        Glasgow
                    </Link></li>
                    <li> <Link to="/rome">
                        Rome
                    </Link></li>
                    <li> <Link to="/milan">
                        Milan
                    </Link></li>
                    <li> <Link to="/naples">
                        Naples
                    </Link></li>
                    <li> <Link to="/turin">
                        Turin
                    </Link></li>
                    <li> <Link to="/san-marino-city">
                        San Marino City
                    </Link></li>
                    <li> <Link to="/prague">
                        Prague
                    </Link></li>
                    <li> <Link to="/brno">
                        Brno
                    </Link></li>
                    <li> <Link to="/ostrava">
                        Ostrava
                    </Link></li>
                    <li> <Link to="/valletta">
                        Valletta
                    </Link></li>
                    <li> <Link to="/birkirkara">
                        Birkirkara
                    </Link></li>
                    <li> <Link to="/mosta">
                        Mosta
                    </Link></li>

                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default MarketareaEUstateandcity;
