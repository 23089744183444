import React from "react";

const Disclaimer = () => {
  return (
    <div>
        <main id="main">
      {/* Breadcrumb Section */}
      <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero srvcMrkt">
        <div class="container">
          <div class="breadcrumb-hero">
            <h1>Disclaimer</h1>
            
          </div>
        </div>
      </div>
      <div class="container ">
        <ol>
          <li><a href="https://digics.in/">Home</a></li>
          <li>Disclaimer</li>
        </ol>
      </div>
    </section>

      {/* Disclaimer Content */}
      <div class="container pb-5">
    
   
    
 
<div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">

                <div class="col-md-12 wow fadeInUp" data-wow-delay=".1s">

                <p>Whatever we have written or explained is best of our knowledge, we can’t share client names &amp; can’t show any figures due to our data privacy policy. But if we do business together we can show you the numbers in your own campaign.</p>

                

                <p>Every campaign performance depends upon lots of factors like, website look N feel, content, inquiry options on your website, how promptly advertiser contact new lead, season, how automated their system are, location, customer care, advertiser’s USP, offers, seasons, Weekdays, weekends, salary cycle of their end user and many more. We can’t guarantee on anything before we run any campaign. Besides in order to generate business online we have to have good internet presence online.</p>

                

                <p>A digital marketing campaign takes time to perform &amp; require ongoing optimization in order to get low lead cost. We are managing accounts from last 3 years &amp; still there are opportunities to optimize, improve those campaigns.</p>

                

                <p>We make strategy after 1-month data analyzing. Initially we just understand business &amp; create campaigns on the basis of our expertise on digital marketing. Every single business is unique even though it looks like same to common people. As we completely rely on data we expect minimum 100 website visitors every single day. in order to create or plan new strategy.</p>

                

                <p>There are so many business which got impacted by seasons, weekends, salary cycle, user demography, device, location and many more reason which impact the lead quality. And we can’t make judgement without any data. As we believe all business are unique even though it looks same to common people but by their own USP, user interface , customer response time so on &amp; so forth factore plays great role.</p>

                

                <p>Initially most of the campaigns requires lot of participation from advertiser side. Because of their deep understanding of their products or services &amp; their end client’s demography. Not every marketing channels suites to every advertizer so we need time &amp; budget to try all different marketing channel. </p>

                

                <p>We require from our customer to have patience, trust &amp; no communication gap.</p>

                <p>We are looking for long term business relationship.</p>

              </div>

            </div>
</div>
    
    
   
                            

      </div>
      </main>
    </div>
  );
};

export default Disclaimer;
