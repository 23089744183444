import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, } from 'react-router-dom';
import Header from './Header';
import Header2 from './Header2'; // Import the alternate header
import Footer from './Footer';
import Home from './Home';
import Mainproductpage from './Mainproductpage';
import Mainstate from './mainstate';
import Contact from './Contact';
import Disclaimer from './Disclaimer';
import Refundpolicy from './Refundpolicy';
import Privacypolicy from './Privacypolicy';
import Termsconditions from './Termsconditions';
// import Marketareaindia from './Marketareaindia';
import Modalform from './Modalform';
import Scrolltop from './Scrolltop';
import Thankyou from './Thankyou';
import Marketarea from './marketarea/india';
import APACstateandcity from './marketarea/APACstateandcity';
import EUstateandcity from './marketarea/EUstateandcity';
import Middleeaststateandcity from './marketarea/Middleeaststateandcity';
import Namericastateandcity from './marketarea/Namericastateandcity';
import Samericastateandcity from './marketarea/Samericastateandcity';
import Africa from './marketarea/africa';
import Indiandistrict from './marketarea/indiandistrict';



// import Marketareaafrica from './marketarea/MarketareaNamericastateandcity';
import Kerword from './kerword';

import './assets/css/style.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
// import './assets/assets/vendor/boxicons/css/boxiconsmin.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/css/style.css';


// Conditional Layout Wrapper
function Layout({ children }) {
    const location = useLocation();

    // Render Header2 only for the dynamic Mainstate route
    const isMainstatePage = location.pathname.startsWith('/');

    return (
        <>
            {isMainstatePage ? <Header2 /> : <Header />}
            {children}
            <Footer />
        </>
    );
}

function App() {
    return (
        <Router>
            <Scrolltop />
            <Layout>
                <main style={{ padding: '20px' }}>
                    <Routes>
                        {/* Static Routes */}
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/disclaimer" element={<Disclaimer />} />
                        <Route path="/refund-policy" element={<Refundpolicy />} />
                        <Route path="/terms-conditions" element={<Termsconditions />} />
                        <Route path="/privacy-policy" element={<Privacypolicy />} />
                        <Route path="/Thankyou" element={<Thankyou />} />
                        {/* <Route path="/site-map" element={<Marketareaindia/>} /> */}
                        <Route path="/Kerword" element={<Kerword/>} />


                        {/* market area pages */}


                        <Route path="/marketarea/india" element={<Marketarea/>} />
                        <Route path="/marketarea/1" element={<APACstateandcity/>} />
                        <Route path="/marketarea/2" element={<EUstateandcity/>} />
                        <Route path="/marketarea/3" element={<Middleeaststateandcity/>} />
                        <Route path="/marketarea/4" element={<Namericastateandcity/>} />
                        <Route path="/marketarea/5" element={<Samericastateandcity/>} />
                        <Route path="/marketarea/6" element={<Africa/>} />
                        <Route path="/marketarea/7" element={<Indiandistrict/>} />

             


                        {/*end market area pages */}
                        
                        

                        {/* Dynamic Route for any location */}
                        <Route path="/:location" element={<Mainstate />} />
                        {/* <Route path="/ma-india" element={<Marketareaindia />} /> */}
                        <Route path="/test" element={<Modalform />} />

                        {/* Static Routes for About */}
                        <Route path="/digicsabout" element={<Mainproductpage />} />
                        <Route path="/:location/leading-digital-marketing-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-digital-marketing-and-advertising-agency" element={<Mainproductpage />} />
                        <Route path="/:location/top-digital-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-digital-marketing-specialist" element={<Mainproductpage />} />
                        <Route path="/:location/best-digital-marketing-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/top-digital-marketing-packages" element={<Mainproductpage />} />
                        <Route path="/:location/best-digital-advertising-company" element={<Mainproductpage />} />
                        <Route path="/:location/top-digital-advertising-agency" element={<Mainproductpage />} />
                        <Route path="/:location/leading-digital-advertising-firm" element={<Mainproductpage />} />
                        <Route path="/:location/leading-b2b-lead-gen-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-business-lead-generation-companies" element={<Mainproductpage />} />
                        <Route path="/:location/top-b-to-b-lead-generation-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-leads-for-b2b-services" element={<Mainproductpage />} />
                        <Route path="/:location/leading-b2b-lead-generation-companies" element={<Mainproductpage />} />
                        <Route path="/:location/b2b-lead-generation-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-b2b-lead-generation-firm" element={<Mainproductpage />} />
                        <Route path="/:location/leading-b2b-lead-generation-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-b2b-lead-generation-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/lead-generation-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-lead-generation-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-lead-generation-agency" element={<Mainproductpage />} />
                        <Route path="/:location/leading-lead-generation-specialist" element={<Mainproductpage />} />
                        <Route path="/:location/best-lead-generation-marketing" element={<Mainproductpage />} />
                        <Route path="/:location/best-lead-generation-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/leading-programmatic-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-programmatic-ads-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-programmatic-ads-agency" element={<Mainproductpage />} />
                        <Route path="/:location/leading-performance-marketing-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-marketing-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-marketing-specialist" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-marketing-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/best-internet-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-internet-advertising-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/leading-internet-advertising-firm" element={<Mainproductpage />} />
                        <Route path="/:location/best-internet-marketing-advertising-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/best-linkedin-campaign-manager" element={<Mainproductpage />} />
                        <Route path="/:location/best-linkedin-ads-management-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-pay-per-click-advertising-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/leading-pay-per-click-advertising-company" element={<Mainproductpage />} />
                        <Route path="/:location/top-pay-per-click-advertising-firm" element={<Mainproductpage />} />
                        <Route path="/:location/inida-best-pay-per-click-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-pay-per-click-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-pay-per-click-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-ppc-advertising-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/leading-ppc-advertising-company" element={<Mainproductpage />} />
                        <Route path="/:location/top-ppc-advertising-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-ppc-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-ppc-marketing-service" element={<Mainproductpage />} />
                        <Route path="/:location/best-ppc-marketing-advertising-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/leading-web-designing-company" element={<Mainproductpage />} />
                        <Route path="/:location/top-web-designing-firm" element={<Mainproductpage />} />
                        <Route path="/:location/best-web-designing-agency" element={<Mainproductpage />} />
                        <Route path="/:location/top-web-designing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-youtube-video-promotion-services" element={<Mainproductpage />} />
                        <Route path="/:location/leading-youtube-video-advertising-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/best-youtube-marketing-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-ads-account-manager" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-partner-companies" element={<Mainproductpage />} />
                        <Route path="/:location/leading-google-display-advertising-companies" element={<Mainproductpage />} />
                        <Route path="/:location/top-google-adwords-certified-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-ads-specialist" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-ads-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-ads-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/best-search-engine-marketing-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/top-search-engine-marketing-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/leading-seo-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-seo-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-seo-firm" element={<Mainproductpage />} />
                        <Route path="/:location/top-seo-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-seo-agency" element={<Mainproductpage />} />
                        <Route path="/:location/best-seo-packages" element={<Mainproductpage />} />
                        <Route path="/:location/top-seo-consultants" element={<Mainproductpage />} />
                        <Route path="/:location/youtube-seo-service" element={<Mainproductpage />} />
                        <Route path="/:location/leading-web-development-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-web-development-agency" element={<Mainproductpage />} />
                        <Route path="/:location/top-web-development-firm" element={<Mainproductpage />} />
                        <Route path="/:location/leading-web-development-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-web-development-consultant" element={<Mainproductpage />} />
                        <Route path="/:location/best-facebook-advertising-management-services" element={<Mainproductpage />} />
                        <Route path="/:location/leading-fb-ads-management-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-fb-ads-manager" element={<Mainproductpage />} />
                        <Route path="/:location/top-meta-ads-manager" element={<Mainproductpage />} />
                        <Route path="/:location/best-meta-advertisement-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-facebook-advertising-firm" element={<Mainproductpage />} />
                        <Route path="/:location/leading-advertising-agency-for-facebook" element={<Mainproductpage />} />
                        <Route path="/:location/top-facebook-ad-management-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-facebook-advertising-company" element={<Mainproductpage />} />
                        <Route path="/:location/best-digital-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-b2b-lead-generation-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-lead-generation-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-programmatic-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-performance-marketing-solutions" element={<Mainproductpage />} />
                        <Route path="/:location/best-internet-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-linkedin-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-pay-per-click-campaign-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-ppc-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-web-designing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-youtube-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-google-adwords-advertising-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-sem-marketing-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-seo-services" element={<Mainproductpage />} />
                        <Route path="/:location/top-web-development-services" element={<Mainproductpage />} />
                        <Route path="/:location/best-facebook-ads-manager" element={<Mainproductpage />} />

                    </Routes>
                </main>
            </Layout>
        </Router>
    );
}

export default App;



// bottom old data


// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './Header';
// import Footer from './Footer';
// import Home from './Home';
// import About from './About';
// import Mainstate from './mainstate';
// import Contact from './Contact';
// import './assets/css/style.css';
// import './assets/vendor/bootstrap/css/bootstrap.min.css';

// function App() {
//     return (
//         <Router>
//             <Header />
//             <main style={{ padding: '20px' }}>
//                 <Routes>
//                     {/* Static Routes */}
//                     <Route path="/" element={<Home />} />
//                     <Route path="/contact" element={<Contact />} />
                    
//                     {/* Dynamic Route for any location */}
//                     <Route path="/:location" element={<Mainstate />} />

//                     {/* Static Routes for About */}
//                     <Route path="/digicsabout" element={<About />} />
//                     <Route path="/leading-digital-marketing-agency" element={<About />} />
//                     <Route path="/best-digital-marketing-and-advertising-agency" element={<About />} />
//                     <Route path="/top-digital-advertising-services" element={<About />} />
//                     <Route path="/top-digital-marketing-specialist" element={<About />} />
//                     <Route path="/best-digital-marketing-consultant" element={<About />} />
//                     <Route path="/top-digital-marketing-packages" element={<About />} />
//                     <Route path="/best-digital-advertising-company" element={<About />} />
//                     <Route path="/top-digital-advertising-agency" element={<About />} />
//                     <Route path="/leading-digital-advertising-firm" element={<About />} />
//                 </Routes>
//             </main>
//             <Footer />
//         </Router>
//     );
// }

// export default App;


