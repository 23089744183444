import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function Marketareaindiadistrict() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>India District</h2>
                    <ul class="state-list">
                    <li><Link to="/allurisitharamaraju">Allurisitharamaraju</Link></li>
    <li><Link to="/ananthapuramu">Ananthapuramu</Link></li>
    <li><Link to="/annamayya">Annamayya</Link></li>
    <li><Link to="/anakapalli">Anakapalli</Link></li>
    <li><Link to="/baptla">Baptla</Link></li>
    <li><Link to="/chittoor">Chittoor</Link></li>
    <li><Link to="/east-godavari">East Godavari</Link></li>
    <li><Link to="/eluru-guntur">Eluru Guntur</Link></li>
    <li><Link to="/kakinada">Kakinada</Link></li>
    <li><Link to="/konaseema">Konaseema</Link></li>
    <li><Link to="/krishna">Krishna</Link></li>
    <li><Link to="/kurnool">Kurnool</Link></li>
    <li><Link to="/nandyal">Nandyal</Link></li>
    <li><Link to="/ntr">N.T.R</Link></li>
    <li><Link to="/palnadu">Palnadu</Link></li>
    <li><Link to="/parvathipurammanyam">Parvathipurammanyam</Link></li>
    <li><Link to="/prakasam">Prakasam</Link></li>
    <li><Link to="/srisathyasai">Srisathyasai</Link></li>
    <li><Link to="/sps-nellore">SPS Nellore</Link></li>
    <li><Link to="/srikakulam">Srikakulam</Link></li>
    <li><Link to="/tirupati">Tirupati</Link></li>
    <li><Link to="/visakhapatnam">Visakhapatnam</Link></li>
    <li><Link to="/vizianagaram">Vizianagaram</Link></li>
    <li><Link to="/west-godavari">West Godavari</Link></li>
    <li><Link to="/ysr-kadapa">YSR Kadapa</Link></li>
    <li><Link to="/anjaw">Anjaw</Link></li>
    <li><Link to="/changlang">Changlang</Link></li>
    <li><Link to="/dibang-valley">Dibang Valley</Link></li>
    <li><Link to="/east-kameng">East Kameng</Link></li>
    <li><Link to="/east-siang">East Siang</Link></li>
    <li><Link to="/itanagar">Itanagar</Link></li>
    <li><Link to="/kra-daadi">Kra Daadi</Link></li>
    <li><Link to="/kurung-kumey">Kurung Kumey</Link></li>
    <li><Link to="/lohit-longding">Lohit Longding</Link></li>
    <li><Link to="/lower-dibang-valley">Lower Dibang Valley</Link></li>
    <li><Link to="/lower-subansiri">Lower Subansiri</Link></li>
    <li><Link to="/namsai-papumpare">Namsai Papumpare</Link></li>
    <li><Link to="/shiyomi-siang">Shiyomi Siang</Link></li>
    <li><Link to="/tawang">Tawang</Link></li>
    <li><Link to="/tirap">Tirap</Link></li>
    <li><Link to="/upper-siang">Upper Siang</Link></li>
    <li><Link to="/upper-subansiri">Upper Subansiri</Link></li>
    <li><Link to="/west-kameng">West Kameng</Link></li>
    <li><Link to="/west-siang">West Siang</Link></li>
    <li><Link to="/baksa">Baksa</Link></li>
    <li><Link to="/barpeta">Barpeta</Link></li>
    <li><Link to="/bongaigaon">Bongaigaon</Link></li>
    <li><Link to="/cachar">Cachar</Link></li>
    <li><Link to="/charaideo">Charaideo</Link></li>
    <li><Link to="/chirang">Chirang</Link></li>
    <li><Link to="/darrang">Darrang</Link></li>
    <li><Link to="/dhemaji">Dhemaji</Link></li>
    <li><Link to="/dhubri">Dhubri</Link></li>
    <li><Link to="/dibrugarh">Dibrugarh</Link></li>
    <li><Link to="/dima-hasao">Dima Hasao</Link></li>
    <li><Link to="/goalpara">Goalpara</Link></li>
    <li><Link to="/golaghat">Golaghat</Link></li>
    <li><Link to="/hailakandi">Hailakandi</Link></li>
    <li><Link to="/jorhat">Jorhat</Link></li>
    <li><Link to="/kamrup-metropolitan">Kamrup Metropolitan</Link></li>
    <li><Link to="/kamrup">Kamrup</Link></li>
    <li><Link to="/karbi-anglong">Karbi Anglong</Link></li>
    <li><Link to="/karimganj">Karimganj</Link></li>
    <li><Link to="/kokrajhar">Kokrajhar</Link></li>
    <li><Link to="/lakhimpur">Lakhimpur</Link></li>
    <li><Link to="/majuli">Majuli</Link></li>
    <li><Link to="/morigaon">Morigaon</Link></li>
    <li><Link to="/nagaon">Nagaon</Link></li>
    <li><Link to="/sivasagar">Sivasagar</Link></li>
    <li><Link to="/sonitpur">Sonitpur</Link></li>
    <li><Link to="/south-salmara-mankachar">South Salmara-Mankachar</Link></li>
    <li><Link to="/tinsukia">Tinsukia</Link></li>
    <li><Link to="/udalguri">Udalguri</Link></li>
    <li><Link to="/west-karbi-anglong">West Karbi Anglong</Link></li>
    <li><Link to="/araria">Araria</Link></li>
    <li><Link to="/arwal">Arwal</Link></li>
    <li><Link to="/aurangabad">Aurangabad</Link></li>
    <li><Link to="/banka">Banka</Link></li>
    <li><Link to="/begusarai">Begusarai</Link></li>
    <li><Link to="/bhagalpur">Bhagalpur</Link></li>
    <li><Link to="/bhojpur">Bhojpur</Link></li>
    <li><Link to="/buxar">Buxar</Link></li>
    <li><Link to="/darbhanga">Darbhanga</Link></li>
    <li><Link to="/east-champaran">East Champaran</Link></li>
    <li><Link to="/gaya">Gaya</Link></li>
    <li><Link to="/gopalganj">Gopalganj</Link></li>
    <li><Link to="/jamui">Jamui</Link></li>
    <li><Link to="/jehanabad">Jehanabad</Link></li>
    <li><Link to="/kaimur">Kaimur</Link></li>
    <li><Link to="/katihar">Katihar</Link></li>
    <li><Link to="/khagaria">Khagaria</Link></li>
    <li><Link to="/kishanganj">Kishanganj</Link></li>
    <li><Link to="/lakhisarai">Lakhisarai</Link></li>
    <li><Link to="/madhepura">Madhepura</Link></li>
    <li><Link to="/madhubani">Madhubani</Link></li>
    <li><Link to="/munger">Munger</Link></li>
    <li><Link to="/muzaffarpur">Muzaffarpur</Link></li>
    <li><Link to="/nalanda">Nalanda</Link></li>
    <li><Link to="/nawada">Nawada</Link></li>
    <li><Link to="/patna">Patna</Link></li>
    <li><Link to="/purnea">Purnea</Link></li>
    <li><Link to="/rohtas">Rohtas</Link></li>
    <li><Link to="/saharsa">Saharsa</Link></li>
    <li><Link to="/samastipur">Samastipur</Link></li>
    <li><Link to="/saran">Saran</Link></li>
    <li><Link to="/sheikhpura">Sheikhpura</Link></li>
    <li><Link to="/sheohar">Sheohar</Link></li>
    <li><Link to="/sitamarhi">Sitamarhi</Link></li>
    <li><Link to="/siwan">Siwan</Link></li>
    <li><Link to="/supaul">Supaul</Link></li>
    <li><Link to="/vaishali">Vaishali</Link></li>
    <li><Link to="/west-champaran">West Champaran</Link></li>
    <li><Link to="/balod">Balod</Link></li>
    <li><Link to="/balodabazar-bhatapara">Balodabazar Bhatapara</Link></li>
    <li><Link to="/balrampur">Balrampur</Link></li>
    <li><Link to="/bastar">Bastar</Link></li>
    <li><Link to="/bemetara">Bemetara</Link></li>
    <li><Link to="/bijapur">Bijapur</Link></li>
    <li><Link to="/bilaspur">Bilaspur</Link></li>
    <li><Link to="/dantewada">Dantewada</Link></li>
    <li><Link to="/dhamtari">Dhamtari</Link></li>
    <li><Link to="/durg">Durg</Link></li>
    <li><Link to="/gariaband">Gariaband</Link></li>
    <li><Link to="/gaurela-pendra-marwahi">Gaurela Pendra Marwahi</Link></li>
    <li><Link to="/jangir-champa">Jangir Champa</Link></li>
    <li><Link to="/jashpur">Jashpur</Link></li>
    <li><Link to="/kanker">Kanker</Link></li>
    <li><Link to="/kawardha">Kawardha</Link></li>
    <li><Link to="/kondagaon">Kondagaon</Link></li>
    <li><Link to="/korba">Korba</Link></li>
    <li><Link to="/korea">Korea</Link></li>
    <li><Link to="/mahasamund">Mahasamund</Link></li>
    <li><Link to="/mungeli">Mungeli</Link></li>
    <li><Link to="/narayanpur">Narayanpur</Link></li>
    <li><Link to="/raigarh">Raigarh</Link></li>
    <li><Link to="/raipur">Raipur</Link></li>
    <li><Link to="/rajnandgaon">Rajnandgaon</Link></li>
    <li><Link to="/sukma">Sukma</Link></li>
    <li><Link to="/surajpur">Surajpur</Link></li>
    <li><Link to="/surguja">Surguja</Link></li>
    <li><Link to="/north-goa">North Goa</Link></li>
    <li><Link to="/south-goa">South Goa</Link></li>
    <li><Link to="/panaji">Panaji</Link></li>
    <li><Link to="/ahmedabad">Ahmedabad</Link></li>
    <li><Link to="/amreli">Amreli</Link></li>
    <li><Link to="/anand">Anand</Link></li>
    <li><Link to="/arvalli">Arvalli</Link></li>
    <li><Link to="/banaskantha">Banaskantha</Link></li>
    <li><Link to="/bharuch">Bharuch</Link></li>
    <li><Link to="/bhavnagar">Bhavnagar</Link></li>
    <li><Link to="/botad">Botad</Link></li>
    <li><Link to="/chhotaudepur">Chhotaudepur</Link></li>
    <li><Link to="/dahod">Dahod</Link></li>
    <li><Link to="/devbhumi-dwarka">Devbhumi Dwarka</Link></li>
    <li><Link to="/gandhinagar">Gandhinagar</Link></li>
    <li><Link to="/gir-somnath">Gir Somnath</Link></li>
    <li><Link to="/jamnagar">Jamnagar</Link></li>
    <li><Link to="/junagadh">Junagadh</Link></li>
    <li><Link to="/kutch-kheda">Kutch Kheda</Link></li>
    <li><Link to="/mahesana">Mahesana</Link></li>
    <li><Link to="/mahisagar">Mahisagar</Link></li>
    <li><Link to="/morbi">Morbi</Link></li>
    <li><Link to="/narmada">Narmada</Link></li>
    <li><Link to="/navsari">Navsari</Link></li>
    <li><Link to="/panchmahals">Panchmahals</Link></li>
    <li><Link to="/patan">Patan</Link></li>
    <li><Link to="/porbandar">Porbandar</Link></li>
    <li><Link to="/rajkot">Rajkot</Link></li>
    <li><Link to="/sabarkantha">Sabarkantha</Link></li>
    <li><Link to="/surat">Surat</Link></li>
    <li><Link to="/surendranagar">Surendranagar</Link></li>
    <li><Link to="/tapi">Tapi</Link></li>
    <li><Link to="/dang">Dang</Link></li>
    <li><Link to="/vadodara">Vadodara</Link></li>
    <li><Link to="/valsad">Valsad</Link></li>
    <li><Link to="/ambala">Ambala</Link></li>
    <li><Link to="/bhiwani">Bhiwani</Link></li>
    <li><Link to="/charkhi-dadri">Charkhi Dadri</Link></li>
    <li><Link to="/faridabad">Faridabad</Link></li>
    <li><Link to="/fatehabad">Fatehabad</Link></li>
    <li><Link to="/gurugram">Gurugram</Link></li>
    <li><Link to="/hisar">Hisar</Link></li>
    <li><Link to="/jhajjar">Jhajjar</Link></li>
    <li><Link to="/jind">Jind</Link></li>
    <li><Link to="/kaithal">Kaithal</Link></li>
    <li><Link to="/karnal">Karnal</Link></li>
    <li><Link to="/kurukshetra">Kurukshetra</Link></li>
    <li><Link to="/mahendragarh">Mahendragarh</Link></li>
    <li><Link to="/nuh">Nuh</Link></li>
    <li><Link to="/palwal">Palwal</Link></li>
    <li><Link to="/panchkula">Panchkula</Link></li>
    <li><Link to="/panipat">Panipat</Link></li>
    <li><Link to="/rewari">Rewari</Link></li>
    <li><Link to="/rohtak">Rohtak</Link></li>
    <li><Link to="/sirsa">Sirsa</Link></li>
    <li><Link to="/sonipat">Sonipat</Link></li>
    <li><Link to="/yamunanagar">Yamunanagar</Link></li>
    <li><Link to="/chamba">Chamba</Link></li>
    <li><Link to="/hamirpur">Hamirpur</Link></li>
    <li><Link to="/kangra">Kangra</Link></li>
    <li><Link to="/kinnaur">Kinnaur</Link></li>
    <li><Link to="/kullu">Kullu</Link></li>
    <li><Link to="/lahaul-and-spiti">Lahaul and Spiti</Link></li>
    <li><Link to="/mandi">Mandi</Link></li>
    <li><Link to="/shimla">Shimla</Link></li>
    <li><Link to="/sirmaur">Sirmaur</Link></li>
    <li><Link to="/solan">Solan</Link></li>
    <li><Link to="/una">Una</Link></li>
    <li><Link to="/bokaro">Bokaro</Link></li>
    <li><Link to="/chaibasa">Chaibasa</Link></li>
    <li><Link to="/chatra">Chatra</Link></li>
    <li><Link to="/deoghar">Deoghar</Link></li>
    <li><Link to="/dhanbad">Dhanbad</Link></li>
    <li><Link to="/dumka">Dumka</Link></li>
    <li><Link to="/garhwa">Garhwa</Link></li>
    <li><Link to="/giridih">Giridih</Link></li>
    <li><Link to="/godda">Godda</Link></li>
    <li><Link to="/gumla">Gumla</Link></li>
    <li><Link to="/hazaribagh">Hazaribagh</Link></li>
    <li><Link to="/jamshedpur">Jamshedpur</Link></li>
    <li><Link to="/jamtara">Jamtara</Link></li>
    <li><Link to="/khunti">Khunti</Link></li>
    <li><Link to="/koderma">Koderma</Link></li>
    <li><Link to="/latehar">Latehar</Link></li>
    <li><Link to="/lohardaga">Lohardaga</Link></li>
    <li><Link to="/pakur">Pakur</Link></li>
    <li><Link to="/palamu">Palamu</Link></li>
    <li><Link to="/ramgarh">Ramgarh</Link></li>
    <li><Link to="/ranchi">Ranchi</Link></li>
    <li><Link to="/sahibganj">Sahibganj</Link></li>
    <li><Link to="/seraikela">Seraikela</Link></li>
    <li><Link to="/simdega">Simdega</Link></li>
    <li><Link to="/bidar">Bidar</Link></li>
    <li><Link to="/kalaburagi">Kalaburagi</Link></li>
    <li><Link to="/vijaypura">Vijaypura</Link></li>
    <li><Link to="/yadagiri">Yadagiri</Link></li>
    <li><Link to="/belagavi">Belagavi</Link></li>
    <li><Link to="/bagalkot">Bagalkot</Link></li>
    <li><Link to="/raichur">Raichur</Link></li>
    <li><Link to="/uttar-kannada">Uttar Kannada</Link></li>
    <li><Link to="/dharwad">Dharwad</Link></li>
    <li><Link to="/gadag">Gadag</Link></li>
    <li><Link to="/koppal">Koppal</Link></li>
    <li><Link to="/ballari">Ballari</Link></li>
    <li><Link to="/vijayanagar">Vijayanagar</Link></li>
    <li><Link to="/haveri">Haveri</Link></li>
    <li><Link to="/davangere">Davangere</Link></li>
    <li><Link to="/shivamogga">Shivamogga</Link></li>
    <li><Link to="/udupi">Udupi</Link></li>
    <li><Link to="/chikkamagaluru">Chikkamagaluru</Link></li>
    <li><Link to="/chitradurga">Chitradurga</Link></li>
    <li><Link to="/dakshin-kannada">Dakshin Kannada</Link></li>
    <li><Link to="/kodagu">Kodagu</Link></li>
    <li><Link to="/hassan">Hassan</Link></li>
    <li><Link to="/tumakuru">Tumakuru</Link></li>
    <li><Link to="/mysuru">Mysuru</Link></li>
    <li><Link to="/mandya">Mandya</Link></li>
    <li><Link to="/chamrajnagar">Chamrajnagar</Link></li>
    <li><Link to="/ramanagara">Ramanagara</Link></li>
    <li><Link to="/bengluru">Bengluru</Link></li>
    <li><Link to="/bengaluru-rural">Bengaluru Rural</Link></li>
    <li><Link to="/kolar">Kolar</Link></li>
    <li><Link to="/chikkaballapura">Chikkaballapura</Link></li>
    <li><Link to="/alappuzha">Alappuzha</Link></li>
    <li><Link to="/ernakulam">Ernakulam</Link></li>
    <li><Link to="/idukki">Idukki</Link></li>
    <li><Link to="/kannur">Kannur</Link></li>
    <li><Link to="/kasaragod">Kasaragod</Link></li>
    <li><Link to="/kollam">Kollam</Link></li>
    <li><Link to="/kottayam">Kottayam</Link></li>
    <li><Link to="/kozhikode">Kozhikode</Link></li>
    <li><Link to="/mallapuram">Mallapuram</Link></li>
    <li><Link to="/palakkad">Palakkad</Link></li>
    <li><Link to="/pathanamthitta">Pathanamthitta</Link></li>
    <li><Link to="/thrissur">Thrissur</Link></li>
    <li><Link to="/trivandrum">Trivandrum</Link></li>
    <li><Link to="/wayanad">Wayanad</Link></li>
    <li><Link to="/agar-malwa">Agar Malwa</Link></li>
    <li><Link to="/alirajpur">Alirajpur</Link></li>
    <li><Link to="/anuppur">Anuppur</Link></li>
    <li><Link to="/ashokanagar">Ashokanagar</Link></li>
    <li><Link to="/balaghat">Balaghat</Link></li>
    <li><Link to="/barwani">Barwani</Link></li>
    <li><Link to="/betul">Betul</Link></li>
    <li><Link to="/bhind">Bhind</Link></li>
    <li><Link to="/bhopal">Bhopal</Link></li>
    <li><Link to="/burhanpur">Burhanpur</Link></li>
    <li><Link to="/chhatarpur">Chhatarpur</Link></li>
    <li><Link to="/chhindwara">Chhindwara</Link></li>
    <li><Link to="/damoh">Damoh</Link></li>
    <li><Link to="/datia">Datia</Link></li>
    <li><Link to="/dewas">Dewas</Link></li>
    <li><Link to="/dhar">Dhar</Link></li>
    <li><Link to="/dindori">Dindori</Link></li>
    <li><Link to="/guna">Guna</Link></li>
    <li><Link to="/gwalior">Gwalior</Link></li>
    <li><Link to="/harda">Harda</Link></li>
    <li><Link to="/indore">Indore</Link></li>
    <li><Link to="/jabalpur">Jabalpur</Link></li>
    <li><Link to="/jhabua">Jhabua</Link></li>
    <li><Link to="/katni">Katni</Link></li>
    <li><Link to="/khandwa">Khandwa</Link></li>
    <li><Link to="/khargone">Khargone</Link></li>
    <li><Link to="/mandla">Mandla</Link></li>
    <li><Link to="/mandsaur">Mandsaur</Link></li>
    <li><Link to="/mauganj">Mauganj</Link></li>
    <li><Link to="/morena">Morena</Link></li>
    <li><Link to="/narmadapuram">Narmadapuram</Link></li>
    <li><Link to="/narsinghpur">Narsinghpur</Link></li>
    <li><Link to="/neemuch">Neemuch</Link></li>
    <li><Link to="/niwari">Niwari</Link></li>
    <li><Link to="/panna">Panna</Link></li>
    <li><Link to="/raisen">Raisen</Link></li>
    <li><Link to="/rajgarh">Rajgarh</Link></li>
    <li><Link to="/ratlam">Ratlam</Link></li>
    <li><Link to="/rewa">Rewa</Link></li>
    <li><Link to="/sagar">Sagar</Link></li>
    <li><Link to="/satna">Satna</Link></li>
    <li><Link to="/shehore">Shehore</Link></li>
    <li><Link to="/seoni">Seoni</Link></li>
    <li><Link to="/shahdol">Shahdol</Link></li>
<li><Link to="/shajapur">Shajapur</Link></li>
<li><Link to="/sheopur">Sheopur</Link></li>
<li><Link to="/shivpuri">Shivpuri</Link></li>
<li><Link to="/sidhi">Sidhi</Link></li>
<li><Link to="/singrouli">Singrouli</Link></li>
<li><Link to="/tikamgarh">Tikamgarh</Link></li>
<li><Link to="/ujjain">Ujjain</Link></li>
<li><Link to="/umaria">Umaria</Link></li>
<li><Link to="/vidisha">Vidisha</Link></li>
<li><Link to="/ahmednagar">Ahmednagar</Link></li>
<li><Link to="/akola">Akola</Link></li>
<li><Link to="/amravati">Amravati</Link></li>
<li><Link to="/need">Need</Link></li>
<li><Link to="/bhandara">Bhandara</Link></li>
<li><Link to="/buldhana">Buldhana</Link></li>
<li><Link to="/chandrapur">Chandrapur</Link></li>
<li><Link to="/dhule">Dhule</Link></li>
<li><Link to="/gadchiroli">Gadchiroli</Link></li>
<li><Link to="/gondia">Gondia</Link></li>
<li><Link to="/hingoli">Hingoli</Link></li>
<li><Link to="/jalgaon">Jalgaon</Link></li>
<li><Link to="/jalna">Jalna</Link></li>
<li><Link to="/kolhapur">Kolhapur</Link></li>
<li><Link to="/latur">Latur</Link></li>
<li><Link to="/mumbai-city">Mumbai City</Link></li>
<li><Link to="/mumbai-suburban">Mumbai Suburban</Link></li>
<li><Link to="/nagpur">Nagpur</Link></li>
<li><Link to="/nanded">Nanded</Link></li>
<li><Link to="/nandurbar">Nandurbar</Link></li>
<li><Link to="/nashik">Nashik</Link></li>
<li><Link to="/osmanabad">Osmanabad</Link></li>
<li><Link to="/palghar">Palghar</Link></li>
<li><Link to="/parbhani">Parbhani</Link></li>
<li><Link to="/pune">Pune</Link></li>
<li><Link to="/raigad">Raigad</Link></li>
<li><Link to="/ratnagiri">Ratnagiri</Link></li>
<li><Link to="/sangli">Sangli</Link></li>
<li><Link to="/satara">Satara</Link></li>
<li><Link to="/sindudurg">Sindudurg</Link></li>
<li><Link to="/solapur">Solapur</Link></li>
    <li><Link to="/thane">Thane</Link></li>
    <li><Link to="/wardha">Wardha</Link></li>
    <li><Link to="/washim">Washim</Link></li>
    <li><Link to="/yavatmal">Yavatmal</Link></li>
    <li><Link to="/bishnupur">Bishnupur</Link></li>
    <li><Link to="/chandel">Chandel</Link></li>
    <li><Link to="/churachandpur">Churachandpur</Link></li>
    <li><Link to="/pherzawl">Pherzawl</Link></li>
    <li><Link to="/tengnoupal">Tengnoupal</Link></li>
    <li><Link to="/kakching">Kakching</Link></li>
    <li><Link to="/noney">Noney</Link></li>
    <li><Link to="/imphal-east-west">Imphal East and West</Link></li>
    <li><Link to="/jiribam">Jiribam</Link></li>
    <li><Link to="/kamjong">Kamjong</Link></li>
    <li><Link to="/kangpokpi">Kangpokpi</Link></li>
    <li><Link to="/senapati">Senapati</Link></li>
    <li><Link to="/tamenglong">Tamenglong</Link></li>
    <li><Link to="/thoubal">Thoubal</Link></li>
    <li><Link to="/ukhrul">Ukhrul</Link></li>
    <li><Link to="/south-west-garo-hills">South West Garo Hills</Link></li>
    <li><Link to="/west-garo-hills">West Garo Hills</Link></li>
    <li><Link to="/north-garo-hills">North Garo Hills</Link></li>
    <li><Link to="/east-garo-hills">East Garo Hills</Link></li>
    <li><Link to="/south-garo-hills">South Garo Hills</Link></li>
    <li><Link to="/west-khasi-hills">West Khasi Hills</Link></li>
    <li><Link to="/south-west-khasi-hills">South West Khasi Hills</Link></li>
    <li><Link to="/easter-west-khasi-hills">Easter West Khasi Hills</Link></li>
    <li><Link to="/east-khasi-hills">East Khasi Hills</Link></li>
    <li><Link to="/ri-bhoi">Ri Bhoi</Link></li>
    <li><Link to="/west-jaintia-hills">West Jaintia Hills</Link></li>
    <li><Link to="/east-jaintia-hills">East Jaintia Hills</Link></li>
    <li><Link to="/aizawl">Aizawl</Link></li>
    <li><Link to="/lunglei">Lunglei</Link></li>
    <li><Link to="/champhai">Champhai</Link></li>
    <li><Link to="/mamit">Mamit</Link></li>
    <li><Link to="/serchhip">Serchhip</Link></li>
    <li><Link to="/kolasib">Kolasib</Link></li>
    <li><Link to="/lawngtlai">Lawngtlai</Link></li>
    <li><Link to="/saiha">Saiha</Link></li>
    <li><Link to="/khawzawl">Khawzawl</Link></li>
    <li><Link to="/dimapur">Dimapur</Link></li>
    <li><Link to="/kiphire">Kiphire</Link></li>
    <li><Link to="/kohima">Kohima</Link></li>
    <li><Link to="/longleng">Longleng</Link></li>
    <li><Link to="/mokokchung">Mokokchung</Link></li>
    <li><Link to="/mon">Mon</Link></li>
    <li><Link to="/paren">Paren</Link></li>
    <li><Link to="/phek">Phek</Link></li>
    <li><Link to="/tuensang">Tuensang</Link></li>
    <li><Link to="/wokha">Wokha</Link></li>
    <li><Link to="/zunheboto">Zunheboto</Link></li>
    <li><Link to="/chumukedima">Chumukedima</Link></li>
    <li><Link to="/niuland">Niuland</Link></li>
    <li><Link to="/noklal">Noklal</Link></li>
    <li><Link to="/shamator">Shamator</Link></li>
    <li><Link to="/tseminyu">Tseminyu</Link></li>
    <li><Link to="/angul">Angul</Link></li>
    <li><Link to="/balangir">Balangir</Link></li>
    <li><Link to="/baleshwar">Baleshwar</Link></li>
    <li><Link to="/bargarh">Bargarh</Link></li>
    <li><Link to="/bhadrak">Bhadrak</Link></li>
    <li><Link to="/boudh">Boudh</Link></li>
    <li><Link to="/cuttack">Cuttack</Link></li>
    <li><Link to="/deogarh">Deogarh</Link></li>
    <li><Link to="/dhenkanal">Dhenkanal</Link></li>
    <li><Link to="/gajapati">Gajapati</Link></li>
    <li><Link to="/ganjam">Ganjam</Link></li>
    <li><Link to="/jagatsinghpur">Jagatsinghpur</Link></li>
    <li><Link to="/jajpur">Jajpur</Link></li>
    <li><Link to="/jharsuguda">Jharsuguda</Link></li>
    <li><Link to="/kalahandi">Kalahandi</Link></li>
    <li><Link to="/kandhamal">Kandhamal</Link></li>
    <li><Link to="/kendrapara">Kendrapara</Link></li>
    <li><Link to="/kendujhar">Kendujhar</Link></li>
    <li><Link to="/khorda">Khorda</Link></li>
    <li><Link to="/koraput">Koraput</Link></li>
    <li><Link to="/malkangiri">Malkangiri</Link></li>
    <li><Link to="/mayurbhanj">Mayurbhanj</Link></li>
    <li><Link to="/nabarangpur">Nabarangpur</Link></li>
    <li><Link to="/nayagarh">Nayagarh</Link></li>
    <li><Link to="/nuapada">Nuapada</Link></li>
    <li><Link to="/puri">Puri</Link></li>
    <li><Link to="/rayagada">Rayagada</Link></li>
    <li><Link to="/sambalpur">Sambalpur</Link></li>
    <li><Link to="/subarnapur">Subarnapur</Link></li>
    <li><Link to="/sundargarh">Sundargarh</Link></li>
    <li><Link to="/amritsar">Amritsar</Link></li>
    <li><Link to="/barnala">Barnala</Link></li>
    <li><Link to="/bathinda">Bathinda</Link></li>
    <li><Link to="/faridkot">Faridkot</Link></li>
    <li><Link to="/fatehgarh-sahib">Fatehgarh Sahib</Link></li>
    <li><Link to="/fazilka">Fazilka</Link></li>
    <li><Link to="/ferozepur">Ferozepur</Link></li>
    <li><Link to="/gurdaspur">Gurdaspur</Link></li>
    <li><Link to="/hoshiarpur">Hoshiarpur</Link></li>
    <li><Link to="/jalandhar">Jalandhar</Link></li>
    <li><Link to="/kapurthala">Kapurthala</Link></li>
    <li><Link to="/ludhiana">Ludhiana</Link></li>
    <li><Link to="/malerkotla">Malerkotla</Link></li>
    <li><Link to="/mansa">Mansa</Link></li>
    <li><Link to="/moga">Moga</Link></li>
    <li><Link to="/sas-nagar">Sas Nagar</Link></li>
    <li><Link to="/sri-muktar-sahib">Sri Muktar Sahib</Link></li>
    <li><Link to="/sbs-nagar">SBS Nagar</Link></li>
    <li><Link to="/pathankot">Pathankot</Link></li>
    <li><Link to="/patiala">Patiala</Link></li>
    <li><Link to="/rupnagar">Rupnagar</Link></li>
    <li><Link to="/sangrur">Sangrur</Link></li>
    <li><Link to="/tarn-taran">Tarn Taran</Link></li>
    <li><Link to="/ajmer">Ajmer</Link></li>
    <li><Link to="/alwar">Alwar</Link></li>
    <li><Link to="/banswara">Banswara</Link></li>
    <li><Link to="/baran">Baran</Link></li>
    <li><Link to="/barmer">Barmer</Link></li>
    <li><Link to="/bharatpur">Bharatpur</Link></li>
    <li><Link to="/bhilwara">Bhilwara</Link></li>
    <li><Link to="/bikaner">Bikaner</Link></li>
    <li><Link to="/bundi">Bundi</Link></li>
    <li><Link to="/chittorgarh">Chittorgarh</Link></li>
    <li><Link to="/churu">Churu</Link></li>
    <li><Link to="/dausa">Dausa</Link></li>
    <li><Link to="/dholpur">Dholpur</Link></li>
    <li><Link to="/dungarpur">Dungarpur</Link></li>
    <li><Link to="/hanumangarh">Hanumangarh</Link></li>
    <li><Link to="/jaisalmer">Jaisalmer</Link></li>
    <li><Link to="/jaipur">Jaipur</Link></li>
    <li><Link to="/jalor">Jalor</Link></li>
    <li><Link to="/jhalawar">Jhalawar</Link></li>
    <li><Link to="/jhunjhunu">Jhunjhunu</Link></li>
    <li><Link to="/jodhpur">Jodhpur</Link></li>
    <li><Link to="/karauli">Karauli</Link></li>
    <li><Link to="/kota">Kota</Link></li>
    <li><Link to="/nagaur">Nagaur</Link></li>
    <li><Link to="/pali">Pali</Link></li>
    <li><Link to="/pratapgarh">Pratapgarh</Link></li>
    <li><Link to="/rajsamand">Rajsamand</Link></li>
    <li><Link to="/sawai-madhopur">Sawai Madhopur</Link></li>
    <li><Link to="/sikar">Sikar</Link></li>
    <li><Link to="/sirohi">Sirohi</Link></li>
    <li><Link to="/sri-ganganagar">Sri Ganganagar</Link></li>
    <li><Link to="/tonk">Tonk</Link></li>
    <li><Link to="/udaipur">Udaipur</Link></li>
    <li><Link to="/gangtok">Gangtok</Link></li>
    <li><Link to="/mangan">Mangan</Link></li>
    <li><Link to="/gyalshinh">Gyalshinh</Link></li>
    <li><Link to="/namchi">Namchi</Link></li>
    <li><Link to="/pakyong">Pakyong</Link></li>
    <li><Link to="/soreng">Soreng</Link></li>
    <li><Link to="/ariyalur">Ariyalur</Link></li>
    <li><Link to="/chengalpattu">Chengalpattu</Link></li>
    <li><Link to="/chennai">Chennai</Link></li>
    <li><Link to="/coimbatore">Coimbatore</Link></li>
    <li><Link to="/cuddalore">Cuddalore</Link></li>
    <li><Link to="/dharmapuri">Dharmapuri</Link></li>
    <li><Link to="/dindigul">Dindigul</Link></li>
    <li><Link to="/erode">Erode</Link></li>
    <li><Link to="/kallakurichi">Kallakurichi</Link></li>
    <li><Link to="/kancheepuram">Kancheepuram</Link></li>
    <li><Link to="/kanniyakumari">Kanniyakumari</Link></li>
    <li><Link to="/karur">Karur</Link></li>
    <li><Link to="/krishnagiri">Krishnagiri</Link></li>
    <li><Link to="/madurai">Madurai</Link></li>
    <li><Link to="/mayiladuthurai">Mayiladuthurai</Link></li>
    <li><Link to="/nagapattinam">Nagapattinam</Link></li>
    <li><Link to="/namakkal">Namakkal</Link></li>
    <li><Link to="/nilgiris">Nilgiris</Link></li>
    <li><Link to="/perambalur">Perambalur</Link></li>
    <li><Link to="/pudukkottai">Pudukkottai</Link></li>
    <li><Link to="/ramanathapuram">Ramanathapuram</Link></li>
    <li><Link to="/ranipet">Ranipet</Link></li>
    <li><Link to="/salem">Salem</Link></li>
    <li><Link to="/sivaganga">Sivaganga</Link></li>
    <li><Link to="/tenkasi">Tenkasi</Link></li>
    <li><Link to="/thanjavur">Thanjavur</Link></li>
    <li><Link to="/theni">Theni</Link></li>
    <li><Link to="/thoothukudi">Thoothukudi</Link></li>
    <li><Link to="/tiruchirapalli">Tiruchirapalli</Link></li>
    <li><Link to="/tirunelveli">Tirunelveli</Link></li>
    <li><Link to="/tirupathur">Tirupathur</Link></li>
    <li><Link to="/tiruvannamalai">Tiruvannamalai</Link></li>
    <li><Link to="/tiruvarur">Tiruvarur</Link></li>
    <li><Link to="/vellore">Vellore</Link></li>
    <li><Link to="/viluppuram">Viluppuram</Link></li>
    <li><Link to="/virudhunagar">Virudhunagar</Link></li>
    <li><Link to="/adilabad">Adilabad</Link></li>
    <li><Link to="/hyderabad">Hyderabad</Link></li>
    <li><Link to="/jagtial">Jagtial</Link></li>
    <li><Link to="/jangaon">Jangaon</Link></li>
    <li><Link to="/jayashankar">Jayashankar</Link></li>
    <li><Link to="/bhupalapally">Bhupalapally</Link></li>
    <li><Link to="/jogulamba-gadwal">Jogulamba Gadwal</Link></li>
    <li><Link to="/kamareddy">Kamareddy</Link></li>
    <li><Link to="/karimnagar">Karimnagar</Link></li>
    <li><Link to="/khammam">Khammam</Link></li>
    <li><Link to="/bhadradri-kothagudem">Bhadradri Kothagudem</Link></li>
    <li><Link to="/komaram-bheem-asifabad">Komaram Bheem Asifabad</Link></li>
    <li><Link to="/mahabubnagar">Mahabubnagar</Link></li>
    <li><Link to="/mahabubabad">Mahabubabad</Link></li>
    <li><Link to="/mancherial">Mancherial</Link></li>
    <li><Link to="/medak">Medak</Link></li>
    <li><Link to="/medchal-malkajgiri">Medchal Malkajgiri</Link></li>
    <li><Link to="/mulugu">Mulugu</Link></li>
    <li><Link to="/nagarkurnool">Nagarkurnool</Link></li>
    <li><Link to="/nalgonda">Nalgonda</Link></li>
    <li><Link to="/narayanpet">Narayanpet</Link></li>
    <li><Link to="/nirmal">Nirmal</Link></li>
    <li><Link to="/nizamabad">Nizamabad</Link></li>
    <li><Link to="/pedapalli">Pedapalli</Link></li>
    <li><Link to="/rajanna-sircilla">Rajanna Sircilla</Link></li>
    <li><Link to="/rangareddy">Rangareddy</Link></li>
    <li><Link to="/sangareddy">Sangareddy</Link></li>
    <li><Link to="/siddipet">Siddipet</Link></li>
    <li><Link to="/suryapet">Suryapet</Link></li>
    <li><Link to="/vikarabad">Vikarabad</Link></li>
    <li><Link to="/wanaparthy">Wanaparthy</Link></li>
    <li><Link to="/hanumakonda">Hanumakonda</Link></li>
    <li><Link to="/warangal">Warangal</Link></li>
    <li><Link to="/yadadri-bhuvanagari">Yadadri Bhuvanagari</Link></li>
    <li><Link to="/dhalai">Dhalai</Link></li>
    <li><Link to="/gomati">Gomati</Link></li>
    <li><Link to="/khowai">Khowai</Link></li>
    <li><Link to="/north-tripura">North Tripura</Link></li>
    <li><Link to="/sepahijala">Sepahijala</Link></li>
    <li><Link to="/south-tripura">South Tripura</Link></li>
    <li><Link to="/unakoti">Unakoti</Link></li>
    <li><Link to="/west-tripura">West Tripura</Link></li>
    <li><Link to="/agra">Agra</Link></li>
    <li><Link to="/aligarh">Aligarh</Link></li>
    <li><Link to="/ambedkar-nagar">Ambedkar Nagar</Link></li>
    <li><Link to="/ayodhya">Ayodhya</Link></li>
    <li><Link to="/amethi">Amethi</Link></li>
    <li><Link to="/amroha">Amroha</Link></li>
    <li><Link to="/auraiya">Auraiya</Link></li>
    <li><Link to="/azamgarh">Azamgarh</Link></li>
    <li><Link to="/badaun">Badaun</Link></li>
    <li><Link to="/bagpat">Bagpat</Link></li>
    <li><Link to="/bahraich">Bahraich</Link></li>
    <li><Link to="/ballia">Ballia</Link></li>
    <li><Link to="/banda">Banda</Link></li>
    <li><Link to="/barabanki">Barabanki</Link></li>
    <li><Link to="/bareilly">Bareilly</Link></li>
    <li><Link to="/basti">Basti</Link></li>
    <li><Link to="/bijnor">Bijnor</Link></li>
    <li><Link to="/bulandshahr">Bulandshahr</Link></li>
    <li><Link to="/chandauli">Chandauli</Link></li>
    <li><Link to="/chitrakoot">Chitrakoot</Link></li>
    <li><Link to="/deoria">Deoria</Link></li>
    <li><Link to="/etah">Etah</Link></li>
    <li><Link to="/etawah">Etawah</Link></li>
    <li><Link to="/farrukhabad">Farrukhabad</Link></li>
    <li><Link to="/fatehpur">Fatehpur</Link></li>
    <li><Link to="/firozabad">Firozabad</Link></li>
    <li><Link to="/gautam-buddha-nagar">Gautam Buddha Nagar</Link></li>
    <li><Link to="/ghaziabad">Ghaziabad</Link></li>
    <li><Link to="/ghazipur">Ghazipur</Link></li>
    <li><Link to="/gonda">Gonda</Link></li>
    <li><Link to="/gorakhpur">Gorakhpur</Link></li>
    <li><Link to="/hapur">Hapur</Link></li>
    <li><Link to="/hardoi">Hardoi</Link></li>
    <li><Link to="/hathras">Hathras</Link></li>
    <li><Link to="/jalaun">Jalaun</Link></li>
    <li><Link to="/jaunpur">Jaunpur</Link></li>
    <li><Link to="/jhansi">Jhansi</Link></li>
    <li><Link to="/kannauj">Kannauj</Link></li>
    <li><Link to="/kanpur-dehat">Kanpur Dehat</Link></li>
    <li><Link to="/kanpur-nagar">Kanpur Nagar</Link></li>
    <li><Link to="/kasganj">Kasganj</Link></li>
    <li><Link to="/kaushambi">Kaushambi</Link></li>
    <li><Link to="/kushinagar">Kushinagar</Link></li>
    <li><Link to="/lakhimpur-kheri">Lakhimpur Kheri</Link></li>
    <li><Link to="/lalitpur">Lalitpur</Link></li>
    <li><Link to="/lucknow">Lucknow</Link></li>
    <li><Link to="/maharajganj">Maharajganj</Link></li>
    <li><Link to="/mahoba">Mahoba</Link></li>
    <li><Link to="/mainpuri">Mainpuri</Link></li>
    <li><Link to="/mathura">Mathura</Link></li>
    <li><Link to="/mau">Mau</Link></li>
    <li><Link to="/meerut">Meerut</Link></li>
    <li><Link to="/mirzapur">Mirzapur</Link></li>
    <li><Link to="/moradabad">Moradabad</Link></li>
    <li><Link to="/muzaffarnagar">Muzaffarnagar</Link></li>
    <li><Link to="/pilibhit">Pilibhit</Link></li>
    <li><Link to="/prayagraj">Prayagraj</Link></li>
    <li><Link to="/rae-bareli">Rae Bareli</Link></li>
    <li><Link to="/rampur">Rampur</Link></li>
    <li><Link to="/saharanpur">Saharanpur</Link></li>
    <li><Link to="/sant-kabir-nagar">Sant Kabir Nagar</Link></li>
    <li><Link to="/sant-ravidas-nagar">Sant Ravidas Nagar</Link></li>
    <li><Link to="/sambhal">Sambhal</Link></li>
    <li><Link to="/shahjahanpur">Shahjahanpur</Link></li>
    <li><Link to="/shamli">Shamli</Link></li>
    <li><Link to="/shravasti">Shravasti</Link></li>
    <li><Link to="/siddharthnagar">Siddharthnagar</Link></li>
    <li><Link to="/sitapur">Sitapur</Link></li>
    <li><Link to="/sonbhadra">Sonbhadra</Link></li>
    <li><Link to="/sultanpur">Sultanpur</Link></li>
    <li><Link to="/unnao">Unnao</Link></li>
    <li><Link to="/varanasi">Varanasi</Link></li>
    <li><Link to="/almora">Almora</Link></li>
    <li><Link to="/bageshwar">Bageshwar</Link></li>
    <li><Link to="/chamoli">Chamoli</Link></li>
    <li><Link to="/champawat">Champawat</Link></li>
    <li><Link to="/dehradun">Dehradun</Link></li>
    <li><Link to="/haridwar">Haridwar</Link></li>
    <li><Link to="/nainital">Nainital</Link></li>
    <li><Link to="/pauri-garhwal">Pauri Garhwal</Link></li>
    <li><Link to="/pithoragarh">Pithoragarh</Link></li>
    <li><Link to="/rudraprayag">Rudraprayag</Link></li>
    <li><Link to="/tehri-garhwal">Tehri Garhwal</Link></li>
    <li><Link to="/udham-singh-nagar">Udham Singh Nagar</Link></li>
    <li><Link to="/uttarkashi">Uttarkashi</Link></li>
    <li><Link to="/haldwani">Haldwani</Link></li>
    <li><Link to="/north-24-parganas">North 24 Parganas</Link></li>
    <li><Link to="/south-24-parganas">South 24 Parganas</Link></li>
    <li><Link to="/bankura">Bankura</Link></li>
    <li><Link to="/birbhum">Birbhum</Link></li>
    <li><Link to="/coochbihar">CoochBihar</Link></li>
    <li><Link to="/dakshin-dinajpur">Dakshin Dinajpur</Link></li>
    <li><Link to="/darjeeling">Darjeeling</Link></li>
    <li><Link to="/hooghly">Hooghly</Link></li>
    <li><Link to="/howrah">Howrah</Link></li>
    <li><Link to="/jalpaiguri">Jalpaiguri</Link></li>
    <li><Link to="/jhargram">Jhargram</Link></li>
    <li><Link to="/kalimpong">Kalimpong</Link></li>
    <li><Link to="/kolkata">Kolkata</Link></li>
    <li><Link to="/malda">Malda</Link></li>
    <li><Link to="/murshidabad">Murshidabad</Link></li>
    <li><Link to="/nadia">Nadia</Link></li>
    <li><Link to="/paschim-burdwan">Paschim Burdwan</Link></li>
    <li><Link to="/purba-burdwan">Purba Burdwan</Link></li>
    <li><Link to="/paschim-medinipur">Paschim Medinipur</Link></li>
    <li><Link to="/purba-medinipur">Purba Medinipur</Link></li>
    <li><Link to="/purulia">Purulia</Link></li>
    <li><Link to="/uttar-dinajpur">Uttar Dinajpur</Link></li>
    <li><Link to="/alipurduar">Alipurduar</Link></li>
    <li><Link to="/vijayawada">Vijayawada</Link></li>
    <li><Link to="/guntur">Guntur</Link></li>
    <li><Link to="/nellore">Nellore</Link></li>
    <li><Link to="/rajahmundry">Rajahmundry</Link></li>
    <li><Link to="/kadapa">Kadapa</Link></li>
    <li><Link to="/anantapur">Anantapur</Link></li>
    <li><Link to="/naharlagun">Naharlagun</Link></li>
    <li><Link to="/pasighat">Pasighat</Link></li>
    <li><Link to="/guwahati">Guwahati</Link></li>
    <li><Link to="/silchar">Silchar</Link></li>
    <li><Link to="/tezpur">Tezpur</Link></li>
    <li><Link to="/bihar-sharif">Bihar Sharif</Link></li>
    <li><Link to="/arrah">Arrah</Link></li>
    <li><Link to="/purnia">Purnia</Link></li>
    <li><Link to="/durg-bhilai-nagar">Durg-Bhilai Nagar</Link></li>
    <li><Link to="/jagdalpur">Jagdalpur</Link></li>
    <li><Link to="/ambikapur">Ambikapur</Link></li>
    <li><Link to="/vasco-da-gama">Vasco da Gama</Link></li>
    <li><Link to="/margao">Margao</Link></li>
    <li><Link to="/mapusa">Mapusa</Link></li>
    <li><Link to="/ponda">Ponda</Link></li>
    <li><Link to="/nadiad">Nadiad</Link></li>
    <li><Link to="/dharamshala">Dharamshala</Link></li>
    <li><Link to="/manali">Manali</Link></li>
    <li><Link to="/baddi">Baddi</Link></li>
    <li><Link to="/nahan">Nahan</Link></li>
    <li><Link to="/palampur">Palampur</Link></li>
    <li><Link to="/bokaro-steel-city">Bokaro Steel City</Link></li>
    <li><Link to="/chandigarh">Chandigarh</Link></li>
    <li><Link to="/east-delhi">East Delhi</Link></li>
    <li><Link to="/west-delhi">West Delhi</Link></li>
    <li><Link to="/ndmc">NDMC</Link></li>
    <li><Link to="/south-delhi">South Delhi</Link></li>
    <li><Link to="/north-delhi">North Delhi</Link></li>
    <li><Link to="/central-delhi">Central Delhi</Link></li>
                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default Marketareaindiadistrict;
