import React, { useState, useEffect } from "react";

const Kerword = () => {
  const [slug, setslug] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/data.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const extractedslug = data.map((item) => item.slug);
        setslug(extractedslug);
      })
      .catch((error) => console.error("Error loading JSON:", error));
  }, []);

  return (
    <div>
      <h2>All Keywords</h2>
      <ul>
        {slug.length > 0 ? (
          slug.map((slug, index) => <li key={index}>{slug}</li>)
        ) : (
          <p>Loading keywords...</p>
        )}
      </ul>
    </div>
  );
};

export default Kerword;
