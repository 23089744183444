import React, { useState } from 'react';
import Modalform from './Modalform';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

function Header2() {
    const location = useLocation(); // Access the current location
    const pagesWithDefaultIndia = [
        'contact',
        'terms-conditions',
        'disclaimer',
        'privacy-policy',
        'refund-policy',
        'site-map',
        'Thankyou',
        'marketarea/india',
        'marketarea/1',
        'marketarea/2',
        'marketarea/3',
        'marketarea/4',
        'marketarea/5',
        'marketarea/6',
        'marketarea/7'
    ];
    
    const currentLocation = pagesWithDefaultIndia.some(page => location.pathname.includes(`/${page}`)) 
        ? 'india' 
        : (location.pathname.split('/')[1] || 'india');
    
    
    const [showModal, setShowModal] = useState(false);
     
       // Functions to handle modal open/close
       const handleShow = () => setShowModal(true);
       const handleClose = () => setShowModal(false);

// mobile nav bar 
 // State for controlling navbar collapse
 const [expanded, setExpanded] = useState(false);

 // Function to handle link click (closes navbar)
 const handleNavLinkClick = () => {
   setExpanded(false);}


    return (
        
        <header id="header" className="fixed-top d-flex align-items-center">
            <div className='desktop  d-none d-md-block ' style={{ width: "100%"}}>

            
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo">
                    <h1 className="text-light">
                        <Link to="/">
                            <img
                             src={ require("./images/digics logo-01.png") }                          
                                alt="Digics Logo"
                            />
                        </Link>
                    </h1>
                </div>
                <div id="mySidenav" className="sidenav">
                    <Link className="closebtn" onClick={closeNav}>
                        ×
                    </Link>
                    <nav id="navbar" className="navbar">
                        <ul className="d-block">
                            <li>
                                <Link to="/" className="menu-item">
                                    Home
                                </Link>
                            </li>
                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-digital-marketing-services`}  onClick={closeNav}>
                                    <span>Digital Marketing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-digital-marketing-agency`}  onClick={closeNav}>
                                            Digital Marketing Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-digital-marketing-and-advertising-agency`}  onClick={closeNav}>
                                            Digital Marketing and Advertising Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-digital-advertising-services`}  onClick={closeNav}>
                                            Digital Advertising
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-digital-marketing-specialist`}  onClick={closeNav}>
                                            Digital Marketing Specialist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-digital-marketing-consultant`}  onClick={closeNav}>
                                            Digital Marketing Consultant
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-digital-marketing-packages`}  onClick={closeNav}>
                                            Digital Marketing Package
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-digital-advertising-company`}  onClick={closeNav}>
                                            Digital Advertising Company
                                        </Link>
                                    </li>
                                    <li>
                                         
                                        <Link to={`/${currentLocation}/top-digital-advertising-agency`}  onClick={closeNav}>
                                            Digital Advertising Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-digital-advertising-firm`}  onClick={closeNav}>
                                            Digital Advertising Firm
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                          

                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-b2b-lead-generation-services`}  onClick={closeNav}>
                                    <span>B2B Lead Generation</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-b2b-lead-generation-services`}  onClick={closeNav}>
                                            B2B Lead Gen
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-business-lead-generation-companies`}  onClick={closeNav}>
                                            Business Lead Generation Companies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-b-to-b-lead-generation-services`}  onClick={closeNav}>
                                            B2B Lead Generation
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-leads-for-b2b-services`}  onClick={closeNav}>
                                            Leads For B2B
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-b2b-lead-generation-companies`}  onClick={closeNav}>
                                            B2B Lead Generation Companies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/b2b-lead-generation-agency`}  onClick={closeNav}>
                                            B2B Lead Generation Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-b2b-lead-generation-firm`}  onClick={closeNav}>
                                            B2B Lead Generation Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-b2b-lead-generation-services`}  onClick={closeNav}>
                                            B2B Lead Generation Servics
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-b2b-lead-generation-consultant`}  onClick={closeNav}>
                                            B2B Lead Generation Consultant
                                        </Link>
                                    </li>
                                   
                                </ul>
                            </li>








                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-lead-generation-services`}  onClick={closeNav}>
                                    <span>Lead Generation</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/lead-generation-company`}  onClick={closeNav}>
                                            Lead Generation Comapany
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-lead-generation-services`}  onClick={closeNav}>
                                            Lead Generation Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-lead-generation-agency`}  onClick={closeNav}>
                                            Lead Generation Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-lead-generation-specialist`}  onClick={closeNav}>
                                            Lead Generation Specialist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-lead-generation-marketing`}  onClick={closeNav}>
                                            Lead Generation Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-lead-generation-consultant`}  onClick={closeNav}>
                                            Lead Generation Conultant
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>







                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-programmatic-advertising-services`}  onClick={closeNav}>
                                    <span>Programmatic Advertising</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-programmatic-marketing-services`}  onClick={closeNav}>
                                            Programmatic Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-programmatic-ads-company`}  onClick={closeNav}>
                                            Programmatic Ads Comapny
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-programmatic-ads-agency`}  onClick={closeNav}>
                                            Programmatic Ads Agency
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>







                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-performance-marketing-solutions`}  onClick={closeNav}>
                                    <span>Performance Marketing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-performance-marketing-company`}  onClick={closeNav}>
                                        Performance Marketing Comapany
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-performance-marketing-agency`}  onClick={closeNav}>
                                        Performance Marketing Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-performance-advertising-services`}  onClick={closeNav}>
                                        Performance Marketing Advertisement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-performance-marketing-services`}  onClick={closeNav}>
                                        Performance Marketing Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-performance-marketing-specialist`}  onClick={closeNav}>
                                        Performance Marketing Specialist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-performance-marketing-consultant`}  onClick={closeNav}>
                                        Performance Marketing Consultant
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>








                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-internet-marketing-services`}  onClick={closeNav}>
                                    <span>Internet Marketing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-internet-marketing-services`}  onClick={closeNav}>
                                            Internet Marketing Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-internet-advertising-solutions`}  onClick={closeNav}>
                                            Internet Marketing Advertising
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-internet-advertising-firm`}  onClick={closeNav}>
                                            Internet Marketing Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-internet-marketing-advertising-consultant`}  onClick={closeNav}>
                                            Digital Marketing And Advertising Consultant
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>




                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/top-linkedin-advertising-services`}  onClick={closeNav}>
                                    <span>LinkedIn Advertising</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-linkedin-campaign-manager`}  onClick={closeNav}>
                                            LinkedIn Compaign Manager
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-linkedin-ads-management-services`}  onClick={closeNav}>
                                            LinkedIn Ads Management
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>









                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-pay-per-click-campaign-services`}  onClick={closeNav}>
                                    <span>Pay Per Click Company</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                <li>
                                        <Link to={`/${currentLocation}/top-pay-per-click-advertising-solutions`}  onClick={closeNav}>
                                            Pay Per Click Advertising 
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={`/${currentLocation}/leading-pay-per-click-advertising-company`}  onClick={closeNav}>
                                            Pay Per Click Advertising Comapany
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-pay-per-click-advertising-firm`}  onClick={closeNav}>
                                            Pay Per Click Advertising Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-pay-per-click-advertising-services`}  onClick={closeNav}>
                                            Pay Per Click Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-pay-per-click-marketing-services`}  onClick={closeNav}>
                                            Pay Per Click Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-pay-per-click-marketing-services`}  onClick={closeNav}>
                                            Pay Per Click Marketing Services
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>






                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-ppc-marketing-services`}  onClick={closeNav}>
                                    <span>PPC Marketing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-ppc-advertising-solutions`}  onClick={closeNav}>
                                            PPC Advertising
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-ppc-advertising-company`}  onClick={closeNav}>
                                            PPC Advertising Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-ppc-advertising-agency`}  onClick={closeNav}>
                                            PPC Advertising Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-ppc-advertising-services`}  onClick={closeNav}>
                                            PPC Advertising Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-ppc-marketing-service`}  onClick={closeNav}>
                                            PPC Marketing Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-ppc-marketing-advertising-consultant`}  onClick={closeNav}>
                                            PPC Marketing And Advertising Consultant
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>



                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/top-web-designing-services`}  onClick={closeNav}>
                                    <span>Web Designing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-web-designing-company`}  onClick={closeNav}>
                                            Web Designing Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-web-designing-firm`}  onClick={closeNav}>
                                            Web Designing Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-web-designing-agency`}  onClick={closeNav}>
                                            Web Designing Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-web-designing-service`}  onClick={closeNav}>
                                            Web Designing Services
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>




                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-youtube-advertising-services`}  onClick={closeNav}>
                                    <span>Youtube Advertising </span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-youtube-video-promotion-services`}  onClick={closeNav}>
                                            Youtube Promote Video
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-youtube-video-advertising-solutions`}  onClick={closeNav}>
                                            Youtube Video Advertising
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-youtube-marketing-agency`}  onClick={closeNav}>
                                            Youtube Marketing Agency
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>








                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-google-adwords-advertising-services`}  onClick={closeNav}>
                                    <span>Google Adwords Ad</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-google-ads-account-manager`}  onClick={closeNav}>
                                            Google Ads Account Manager
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-google-partner-companies`}  onClick={closeNav}>
                                            Google Partner Companies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-google-display-advertising-companies`}  onClick={closeNav}>
                                            Google Display Advertising Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-google-adwords-certified-company`}  onClick={closeNav}>
                                            Google Adwords Certified Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-google-ads-specialist`}  onClick={closeNav}>
                                            Google Ads Specialist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-google-ads-agency`}  onClick={closeNav}>
                                            Google Ads Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-google-ads-consultant`}  onClick={closeNav}>
                                            Google Ads Consultant
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>




                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/top-sem-marketing-services`}  onClick={closeNav}>
                                    <span>SEM Digital Marketing</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-search-engine-marketing-solutions`}  onClick={closeNav}>
                                            SEM Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-search-engine-marketing-solutions`}  onClick={closeNav}>
                                            Search Engine Marketing
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>





                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-seo-services`}  onClick={closeNav}>
                                    <span>SEO</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                <li>
                                        <Link to={`/${currentLocation}/leading-seo-services`}  onClick={closeNav}>
                                            SEO Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-seo-marketing-services`}  onClick={closeNav}>
                                            SEO Marketing
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-seo-firm`}  onClick={closeNav}>
                                            SEO Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-seo-company`}  onClick={closeNav}>
                                            SEO Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-seo-agency`}  onClick={closeNav}>
                                            SEO Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-seo-packages`}  onClick={closeNav}>
                                           SEO Packeges
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={`/${currentLocation}/top-seo-consultants`}  onClick={closeNav}>
                                            SEO Consultant
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/youtube-seo-service`}  onClick={closeNav}>
                                            Youtub SEO
                                        </Link>
                                    </li> 
                                   
                                </ul>
                            </li>










                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/top-web-development-services`}  onClick={closeNav}>
                                    <span>Web Development</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-web-development-company`}  onClick={closeNav}>
                                            Web Development Company
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-web-development-agency`}  onClick={closeNav}>
                                            Web Development Agency
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-web-development-firm`}  onClick={closeNav}>
                                            Web Development Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-web-development-services`}  onClick={closeNav}>
                                            Web Development Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-web-development-consultant`}  onClick={closeNav}>
                                            Web Development Consultant
                                        </Link>
                                    </li>
                                   
                                </ul>
                            </li>






                            <li className="dropdown menu-item">
                                <Link to={`/${currentLocation}/best-facebook-ads-manager`}  onClick={closeNav}>
                                    <span>Facebook Ads Manager</span> <i className="bi bi-chevron-right"></i>
                                </Link>
                                <ul>
                                    <li>
                                        <Link to={`/${currentLocation}/best-facebook-advertising-management-services`}  onClick={closeNav}>
                                            Facebook Advertising Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-fb-ads-management-services`}  onClick={closeNav}>
                                            Fb Ads Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-fb-ads-manager`}  onClick={closeNav}>
                                            Fb Ads Manager
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-meta-ads-manager`}  onClick={closeNav}>
                                            Meta Ads Manager 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/best-meta-advertisement-services`}  onClick={closeNav}>
                                            Meta Advertisement
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-facebook-advertising-firm`}  onClick={closeNav}>
                                            Facebook Advertisement Firm
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/leading-advertising-agency-for-facebook`}  onClick={closeNav}>
                                            Advertising Agency For Facebook
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-facebook-ad-management-services`}  onClick={closeNav}>
                                            FAcebook Ad Management
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`/${currentLocation}/top-facebook-advertising-company`}  onClick={closeNav}>
                                            Facebook Adevrtising Companies
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </li>








                        </ul>
                    </nav>
                </div>
                <span
                    style={{ fontSize: '25px', cursor: 'pointer', color: '#f99d1b' }}
                    className="desktopmenubtn"
                    onClick={openNav}
                >
                    ☰ <span style={{ color: '#000' }}>Menu</span>
                </span>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li>
                            <Link
                                className="getstarted myButton"
                                onClick={handleShow}
                            >
                                Get Started
                            </Link>
                        </li>
                    </ul>
                    <i className="bi mobile-nav-toggle bi-list"></i>
                </nav>
            </div>
            </div>

            <div className='mobile d-block d-md-none' style={{ width: "100%" , position: "absolute", top: "0px"}}>
            <div className="container d-flex align-items-center justify-content-between p-0">
              
            <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded} style={{ width: "100%" }}>
      <Container>
        <Navbar.Brand className="p-1">
          <div className="logo">
            <h1 className="text-light">
              <Link to="/" className="p-0" onClick={handleNavLinkClick}>
                <img src={require("./images/digics pvt ltd white-01.png")} alt="Digics Logo" />               

              </Link>
            </h1>
          </div>
        </Navbar.Brand>

        {/* Toggle Button */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" onClick={handleNavLinkClick}>
              Home
            </Nav.Link>

            {/* Digital Marketing Dropdown */}
            <NavDropdown title="Digital Marketing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-digital-marketing-services`} onClick={handleNavLinkClick}>
        Digital Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-digital-marketing-agency`} onClick={handleNavLinkClick}>
        Digital Marketing Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-digital-marketing-and-advertising-agency`} onClick={handleNavLinkClick}>
        Digital Marketing and Advertising Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-digital-advertising-services`} onClick={handleNavLinkClick}>
        Digital Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-digital-marketing-specialist`} onClick={handleNavLinkClick}>
        Digital Marketing Specialist
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-digital-marketing-consultant`} onClick={handleNavLinkClick}>
        Digital Marketing Consultant
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-digital-marketing-packages`} onClick={handleNavLinkClick}>
        Digital Marketing Package
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-digital-advertising-company`} onClick={handleNavLinkClick}>
        Digital Advertising Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-digital-advertising-agency`} onClick={handleNavLinkClick}>
        Digital Advertising Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-digital-advertising-firm`} onClick={handleNavLinkClick}>
        Digital Advertising Firm
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="B2B Lead Generation" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-b2b-lead-generation-services`} onClick={handleNavLinkClick}>
        B2B Lead Generation
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-b2b-lead-generation-services`} onClick={handleNavLinkClick}>
        B2B Lead Gen
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-business-lead-generation-companies`} onClick={handleNavLinkClick}>
        Business Lead Generation Companies
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-b-to-b-lead-generation-services`} onClick={handleNavLinkClick}>
        B2B Lead Generation
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-leads-for-b2b-services`} onClick={handleNavLinkClick}>
        Leads For B2B
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-b2b-lead-generation-companies`} onClick={handleNavLinkClick}>
        B2B Lead Generation Companies
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/b2b-lead-generation-agency`} onClick={handleNavLinkClick}>
        B2B Lead Generation Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-b2b-lead-generation-firm`} onClick={handleNavLinkClick}>
        B2B Lead Generation Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-b2b-lead-generation-services`} onClick={handleNavLinkClick}>
        B2B Lead Generation Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-b2b-lead-generation-consultant`} onClick={handleNavLinkClick}>
        B2B Lead Generation Consultant
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Lead Generation" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-lead-generation-services`} onClick={handleNavLinkClick}>
        Lead Generation
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/lead-generation-company`} onClick={handleNavLinkClick}>
        Lead Generation Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-lead-generation-services`} onClick={handleNavLinkClick}>
        Lead Generation Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-lead-generation-agency`} onClick={handleNavLinkClick}>
        Lead Generation Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-lead-generation-specialist`} onClick={handleNavLinkClick}>
        Lead Generation Specialist
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-lead-generation-marketing`} onClick={handleNavLinkClick}>
        Lead Generation Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-lead-generation-consultant`} onClick={handleNavLinkClick}>
        Lead Generation Consultant
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Programmatic Advertising" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-programmatic-advertising-services`} onClick={handleNavLinkClick}>
        Programmatic Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-programmatic-marketing-services`} onClick={handleNavLinkClick}>
        Programmatic Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-programmatic-ads-company`} onClick={handleNavLinkClick}>
        Programmatic Ads Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-programmatic-ads-agency`} onClick={handleNavLinkClick}>
        Programmatic Ads Agency
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Performance Marketing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-marketing-solutions`} onClick={handleNavLinkClick}>
        Performance Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-performance-marketing-company`} onClick={handleNavLinkClick}>
        Performance Marketing Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-marketing-agency`} onClick={handleNavLinkClick}>
        Performance Marketing Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-advertising-services`} onClick={handleNavLinkClick}>
        Performance Marketing Advertisement
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-marketing-services`} onClick={handleNavLinkClick}>
        Performance Marketing Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-marketing-specialist`} onClick={handleNavLinkClick}>
        Performance Marketing Specialist
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-performance-marketing-consultant`} onClick={handleNavLinkClick}>
        Performance Marketing Consultant
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Internet Marketing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-internet-marketing-services`} onClick={handleNavLinkClick}>
        Internet Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-internet-marketing-services`} onClick={handleNavLinkClick}>
        Internet Marketing Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-internet-advertising-solutions`} onClick={handleNavLinkClick}>
        Internet Marketing Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-internet-advertising-firm`} onClick={handleNavLinkClick}>
        Internet Marketing Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-internet-marketing-advertising-consultant`} onClick={handleNavLinkClick}>
        Digital Marketing And Advertising Consultant
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="LinkedIn Advertising" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-linkedin-advertising-services`} onClick={handleNavLinkClick}>
        LinkedIn Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-linkedin-campaign-manager`} onClick={handleNavLinkClick}>
        LinkedIn Campaign Manager
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-linkedin-ads-management-services`} onClick={handleNavLinkClick}>
        LinkedIn Ads Management
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Pay Per Click Company" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-pay-per-click-campaign-services`} onClick={handleNavLinkClick}>
        Pay Per Click Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-pay-per-click-advertising-solutions`} onClick={handleNavLinkClick}>
        Pay Per Click Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-pay-per-click-advertising-company`} onClick={handleNavLinkClick}>
        Pay Per Click Advertising Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-pay-per-click-advertising-firm`} onClick={handleNavLinkClick}>
        Pay Per Click Advertising Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-pay-per-click-advertising-services`} onClick={handleNavLinkClick}>
        Pay Per Click Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-pay-per-click-marketing-services`} onClick={handleNavLinkClick}>
        Pay Per Click Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-pay-per-click-marketing-services`} onClick={handleNavLinkClick}>
        Pay Per Click Marketing Services
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="PPC Marketing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-ppc-marketing-services`} onClick={handleNavLinkClick}>
        PPC Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-ppc-advertising-solutions`} onClick={handleNavLinkClick}>
        PPC Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-ppc-advertising-company`} onClick={handleNavLinkClick}>
        PPC Advertising Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-ppc-advertising-agency`} onClick={handleNavLinkClick}>
        PPC Advertising Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-ppc-advertising-services`} onClick={handleNavLinkClick}>
        PPC Advertising Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-ppc-marketing-service`} onClick={handleNavLinkClick}>
        PPC Marketing Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-ppc-marketing-advertising-consultant`} onClick={handleNavLinkClick}>
        PPC Marketing And Advertising Consultant
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="Web Designing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-web-designing-services`} onClick={handleNavLinkClick}>
        Web Designing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-web-designing-company`} onClick={handleNavLinkClick}>
        Web Designing Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-web-designing-firm`} onClick={handleNavLinkClick}>
        Web Designing Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-web-designing-agency`} onClick={handleNavLinkClick}>
        Web Designing Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-web-designing-service`} onClick={handleNavLinkClick}>
        Web Designing Services
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="YouTube Advertising" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-youtube-advertising-services`} onClick={handleNavLinkClick}>
        YouTube Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-youtube-video-promotion-services`} onClick={handleNavLinkClick}>
        YouTube Promote Video
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-youtube-video-advertising-solutions`} onClick={handleNavLinkClick}>
        YouTube Video Advertising
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-youtube-marketing-agency`} onClick={handleNavLinkClick}>
        YouTube Marketing Agency
    </NavDropdown.Item>
</NavDropdown>


<NavDropdown title="Google AdWords Ad" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-adwords-advertising-services`} onClick={handleNavLinkClick}>
        Google AdWords Ad
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-ads-account-manager`} onClick={handleNavLinkClick}>
        Google Ads Account Manager
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-partner-companies`} onClick={handleNavLinkClick}>
        Google Partner Companies
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-google-display-advertising-companies`} onClick={handleNavLinkClick}>
        Google Display Advertising Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-google-adwords-certified-company`} onClick={handleNavLinkClick}>
        Google AdWords Certified Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-ads-specialist`} onClick={handleNavLinkClick}>
        Google Ads Specialist
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-ads-agency`} onClick={handleNavLinkClick}>
        Google Ads Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-google-ads-consultant`} onClick={handleNavLinkClick}>
        Google Ads Consultant
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="SEM Digital Marketing" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-sem-marketing-services`} onClick={handleNavLinkClick}>
        SEM Digital Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-search-engine-marketing-solutions`} onClick={handleNavLinkClick}>
        SEM Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-search-engine-marketing-solutions`} onClick={handleNavLinkClick}>
        Search Engine Marketing
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="SEO" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-seo-services`} onClick={handleNavLinkClick}>
        SEO
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-seo-services`} onClick={handleNavLinkClick}>
        SEO Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-seo-marketing-services`} onClick={handleNavLinkClick}>
        SEO Marketing
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-seo-firm`} onClick={handleNavLinkClick}>
        SEO Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-seo-company`} onClick={handleNavLinkClick}>
        SEO Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-seo-agency`} onClick={handleNavLinkClick}>
        SEO Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-seo-packages`} onClick={handleNavLinkClick}>
        SEO Packages
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-seo-consultants`} onClick={handleNavLinkClick}>
        SEO Consultant
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/youtube-seo-service`} onClick={handleNavLinkClick}>
        YouTube SEO
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="Web Development" id="basic-nav-dropdown">
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-web-development-services`} onClick={handleNavLinkClick}>
        Web Development
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-web-development-company`} onClick={handleNavLinkClick}>
        Web Development Company
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-web-development-agency`} onClick={handleNavLinkClick}>
        Web Development Agency
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-web-development-firm`} onClick={handleNavLinkClick}>
        Web Development Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-web-development-services`} onClick={handleNavLinkClick}>
        Web Development Services
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-web-development-consultant`} onClick={handleNavLinkClick}>
        Web Development Consultant
    </NavDropdown.Item>
</NavDropdown>

<NavDropdown title="Facebook Ads Manager" id="basic-nav-dropdown" className='mb-5'>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-facebook-ads-manager`} onClick={handleNavLinkClick}>
        Facebook Ads Manager
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-facebook-advertising-management-services`} onClick={handleNavLinkClick}>
        Facebook Advertising Management
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-fb-ads-management-services`} onClick={handleNavLinkClick}>
        FB Ads Management
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-fb-ads-manager`} onClick={handleNavLinkClick}>
        FB Ads Manager
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-meta-ads-manager`} onClick={handleNavLinkClick}>
        Meta Ads Manager
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/best-meta-advertisement-services`} onClick={handleNavLinkClick}>
        Meta Advertisement
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-facebook-advertising-firm`} onClick={handleNavLinkClick}>
        Facebook Advertisement Firm
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/leading-advertising-agency-for-facebook`} onClick={handleNavLinkClick}>
        Advertising Agency for Facebook
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-facebook-ad-management-services`} onClick={handleNavLinkClick}>
        Facebook Ad Management
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to={`/${currentLocation}/top-facebook-advertising-company`} onClick={handleNavLinkClick}>
        Facebook Advertising Companies
    </NavDropdown.Item>
</NavDropdown>




{/* end dropdown menu mobile mode */}


           
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
              
            
            </div>
            </div>
            <Modalform showModal={showModal} handleClose={handleClose} />
        </header>

        
        
    );
}

function openNav() {
    document.getElementById("mySidenav").style.width = "50%";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}



export default Header2;
