import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Modalform from './Modalform';
import HomeCommonSec from './HomeCommonSec';

function Mainstate() {
    const { location } = useParams(); // Keep original structure

 // State to manage modal visibility
      const [showModal, setShowModal] = useState(false);
    
      // Functions to handle modal open/close
      const handleShow = () => setShowModal(true);
      const handleClose = () => setShowModal(false);

    useEffect(() => {
        document.title = `Best Digital Marketing In ${location}`;
        
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute("content", `Digics - Get 10x ROI | Lead Generation Expert | Social Media Marketing | SEO in ${location}`);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.name = "description";
            newMetaTag.content = `Digics - Get 10x ROI | Lead Generation Expert | Social Media Marketing | SEO in ${location}`;
            document.head.appendChild(newMetaTag);
        }
        
        return () => {
            document.title = 'Digics'; // Reset title when component unmounts
        };
    }, [location]);

    return (
        <div>
            <section id="hero">
                <div className="hero-container">
                    <h1>Best Digital Marketing In {location}</h1>
                    <h2>Digics - Get 10x ROI | Lead Generation Expert | Social Media Marketing | SEO</h2>
                    <Link to="#"  onClick={handleShow} className="btn-get-started scrollto myButton" data-value="Home / Get Started">Get Started</Link>
                </div>
            </section>

            <HomeCommonSec />
            <Modalform showModal={showModal} handleClose={handleClose} />
        </div>
    );
}

export default Mainstate;
