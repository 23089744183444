import React from 'react';
import {useLocation, Link } from 'react-router-dom';


function MarketareaAPACstateandcity() {
    const location = useLocation(); // Access the current location
    const currentLocation = location.pathname.split('/')[1] || 'india'; // Extract location from URL

  return (
    <div>
     
      <main id="main">
        {/* Breadcrumb Section */}
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="breadcrumb-hero srvcMrkt">
            <div className="container">
              <div className="breadcrumb-hero">
                <h1>Service &amp; Market Area {currentLocation}</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service &amp; Market Area</li>
            </ol>
          </div>
        </section>

        {/* Portfolio Section */}
       
        <section id="portfolio" class="portfolio container">

            {/* working here */}

            {/* there is the content of africa states  */}

            <div class="tab-content mt-3 container">
                <div id="Africa" class=" portfolio">
                <h2>APAC State and City</h2>
                    <ul class="state-list">
                    <li> <Link to="/australia">
                        Australia
                    </Link></li>
                    <li> <Link to="/new-zealand">
                        New Zealand
                    </Link></li>
                    <li> <Link to="/fiji">
                        Fiji
                    </Link></li>
                    <li> <Link to="/papua-new-guinea">
                        Papua New Guinea
                    </Link></li>
                    <li> <Link to="/solomon-islands">
                        Solomon Islands
                    </Link></li>
                    <li> <Link to="/vanuatu">
                        Vanuatu
                    </Link></li>
                    <li> <Link to="/guam">
                        Guam
                    </Link></li>
                    <li> <Link to="/kiribati">
                        Kiribati
                    </Link></li>
                    <li> <Link to="/marshall-islands">
                        Marshall Islands
                    </Link></li>
                    <li> <Link to="/micronesia">
                        Micronesia
                    </Link></li>
                    <li> <Link to="/nauru">
                        Nauru
                    </Link></li>
                    <li> <Link to="/palau">
                        Palau
                    </Link></li>
                    <li> <Link to="/american-samoa">
                        American Samoa
                    </Link></li>
                    <li> <Link to="/cook-islands">
                        Cook Islands
                    </Link></li>
                    <li> <Link to="/niue">
                        Niue
                    </Link></li>
                    <li> <Link to="/samoa">
                        Samoa
                    </Link></li>
                    <li> <Link to="/tokelau">
                        Tokelau
                    </Link></li>
                    <li> <Link to="/tonga">
                        Tonga
                    </Link></li>
                    <li> <Link to="/tuvalu">
                        Tuvalu
                    </Link></li>
                    <li> <Link to="/sri-lanka">
                        Sri Lanka
                    </Link></li>
                    <li> <Link to="/philippines">
                        Philippines
                    </Link></li>
                    <li> <Link to="/singapore">
                        Singapore
                    </Link></li>
                    <li> <Link to="/indonesia">
                        Indonesia
                    </Link></li>
                    <li> <Link to="/malaysia">
                        Malaysia
                    </Link></li>
                    <li> <Link to="/thailand">
                        Thailand
                    </Link></li>
                    <li> <Link to="/vietnam">
                        Vietnam
                    </Link></li>
                    <li> <Link to="/sydney">
                        Sydney
                    </Link></li>
                    <li> <Link to="/melbourne">
                        Melbourne
                    </Link></li>
                    <li> <Link to="/brisbane">
                        Brisbane
                    </Link></li>
                    <li> <Link to="/perth">
                        Perth
                    </Link></li>
                    <li> <Link to="/auckland">
                        Auckland
                    </Link></li>
                    <li> <Link to="/wellington">
                        Wellington
                    </Link></li>
                    <li> <Link to="/christchurch">
                        Christchurch
                    </Link></li>
                    <li> <Link to="/hamilton">
                        Hamilton
                    </Link></li>
                    <li> <Link to="/suva">
                        Suva
                    </Link></li>
                    <li> <Link to="/nadi">
                        Nadi
                    </Link></li>
                    <li> <Link to="/lautoka">
                        Lautoka
                    </Link></li>
                    <li> <Link to="/port-moresby">
                        Port Moresby
                    </Link></li>
                    <li> <Link to="/lae">
                        Lae
                    </Link></li>
                    <li> <Link to="/mount-hagen">
                        Mount Hagen
                    </Link></li>
                    <li> <Link to="/honiara">
                        Honiara
                    </Link></li>
                    <li> <Link to="/gizo">
                        Gizo
                    </Link></li>
                    <li> <Link to="/auki">
                        Auki
                    </Link></li>
                    <li> <Link to="/port-vila">
                        Port Vila
                    </Link></li>
                    <li> <Link to="/luganville">
                        Luganville
                    </Link></li>
                    <li> <Link to="/hagatna">
                        Hagåtña
                    </Link></li>
                    <li> <Link to="/dededo">
                        Dededo
                    </Link></li>
                    <li> <Link to="/tamuning">
                        Tamuning
                    </Link></li>
                    <li> <Link to="/tarawa">
                        Tarawa
                    </Link></li>
                    <li> <Link to="/betio">
                        Betio
                    </Link></li>
                    <li> <Link to="/bikenibeu">
                        Bikenibeu
                    </Link></li>
                    <li> <Link to="/majuro">
                        Majuro
                    </Link></li>
                    <li> <Link to="/ebeye">
                        Ebeye
                    </Link></li>
                    <li> <Link to="/palikir">
                        Palikir
                    </Link></li>
                    <li> <Link to="/weno">
                        Weno
                    </Link></li>
                    <li> <Link to="/kolonia">
                        Kolonia
                    </Link></li>
                    <li> <Link to="/yaren">
                        Yaren
                    </Link></li>
                    <li> <Link to="/ngerulmud">
                        Ngerulmud
                    </Link></li>
                    <li> <Link to="/koror">
                        Koror
                    </Link></li>
                    <li> <Link to="/pago-pago">
                        Pago Pago
                    </Link></li>
                    <li> <Link to="/tafuna">
                        Tafuna
                    </Link></li>
                    <li> <Link to="/avarua">
                        Avarua
                    </Link></li>
                    <li> <Link to="/alofi">
                        Alofi
                    </Link></li>
                    <li> <Link to="/apia">
                        Apia
                    </Link></li>
                    <li> <Link to="/atafu">
                        Atafu
                    </Link></li>
                    <li> <Link to="/fakaofo">
                        Fakaofo
                    </Link></li>
                    <li> <Link to="/nukuʻalofa">
                        Nukuʻalofa
                    </Link></li>
                    <li> <Link to="/neiafu">
                        Neiafu
                    </Link></li>
                    <li> <Link to="/funafuti">
                        Funafuti
                    </Link></li>
                    <li> <Link to="/colombo">
                        Colombo
                    </Link></li>
                    <li> <Link to="/kandy">
                        Kandy
                    </Link></li>
                    <li> <Link to="/galle">
                        Galle
                    </Link></li>
                    <li> <Link to="/manila">
                        Manila
                    </Link></li>
                    <li> <Link to="/cebu">
                        Cebu
                    </Link></li>
                    <li> <Link to="/davao">
                        Davao
                    </Link></li>
                    <li> <Link to="/jakarta">
                        Jakarta
                    </Link></li>
                    <li> <Link to="/surabaya">
                        Surabaya
                    </Link></li>
                    <li> <Link to="/bandung">
                        Bandung
                    </Link></li>
                    <li> <Link to="/medan">
                        Medan
                    </Link></li>
                    <li> <Link to="/kuala-lumpur">
                        Kuala Lumpur
                    </Link></li>
                    <li> <Link to="/george-town">
                        George Town
                    </Link></li>
                    <li> <Link to="/johor-bahru">
                        Johor Bahru
                    </Link></li>
                    <li> <Link to="/bangkok">
                        Bangkok
                    </Link></li>
                    <li> <Link to="/chiang-mai">
                        Chiang Mai
                    </Link></li>
                    <li> <Link to="/pattaya">
                        Pattaya
                    </Link></li>
                    <li> <Link to="/hanoi">
                        Hanoi
                    </Link></li>
                    <li> <Link to="/ho-chi-minh-city">
                        Ho Chi Minh City
                    </Link></li>
                    <li> <Link to="/da-nang">
                        Da Nang
                    </Link></li>
                    <li> <Link to="/canberra">
                    Canberra
                    </Link></li>
                    


                 </ul>
                    



                </div>

            </div>
        </section>
      </main>
      
    </div>
  );
  
}



export default MarketareaAPACstateandcity;
