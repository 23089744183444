import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Contact() {

  
  const navigate = useNavigate();

  // State for captcha values and user input
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  // Generate captcha when the component mounts
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    // For example, generate a random number between 50 and 60 for num1,
    // and a random number between 1 and 10 for num2.
    const random1 = Math.floor(Math.random() * 11) + 50; // 50 to 60
    const random2 = Math.floor(Math.random() * 10) + 1;  // 1 to 10
    setNum1(random1);
    setNum2(random2);
    setCaptchaInput("");
    setCaptchaError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the captcha answer
    if (parseInt(captchaInput, 10) !== num1 + num2) {
      setCaptchaError("Captcha answer is incorrect. Please try again.");
      return; // Do not proceed if captcha is wrong
    }

    const url =
      "https://script.google.com/macros/s/AKfycbwa4ZjE2HqP1Ql6nVTZdK41X5NLA1Evkuqzcd9xYyo16olKCI_1RwTgjZKRsWjb20NRYg/exec";

    const formData = new URLSearchParams({
      Name: e.target.name.value,
      Email: e.target.email.value,
      Phoneno: e.target.phoneno.value,
      Whatsapp: e.target.whatsapp.value,
      Location: e.target.location.value,
      PageUrl: e.target.PageUrl.value,
      Massage: e.target.massage.value,
    });

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formData.toString(),
    })
      .then((res) => res.text())
      .then((data) => {
        // Optionally, you can refresh the captcha here
        // generateCaptcha();
        navigate("/Thankyou");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <main id="main">
        {/* Breadcrumb Section */}
        <div className="breadcrumb-hero">
          <div className="container">
            <div className="breadcrumb-hero">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <section id="contact" className="contact">
          <div className="container">
            {/* Map Section */}
            <div>
              <iframe
                style={{ border: 0, width: "100%", height: "270px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14005.914751366761!2d77.3332722!3d28.6453824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfc9ecaa4fab3%3A0x5baa1c5f2412ff0d!2sDigics%20-%20Get%2010x%20ROAS%20%7C%20B2B%20Lead%20Generation%20Expert%20%7C%20Social%20Media%20Marketing%20%7C%20SEO!5e0!3m2!1sen!2sin!4v1718280770589!5m2!1sen!2sin"
                frameBorder="0"
                allowFullScreen
                title="Google Maps"
              ></iframe>
            </div>

            {/* Content Section */}
            <div className="row mt-5">
              {/* Left Section */}
              <div className="col-lg-4" data-aos="fade-right">
               
                  <div  style={{
                        background: "#000",
                        color: "white",
                        padding: "27px",
                        borderRadius: "13px",
                      }}>

                        <h3 className="text-white mb-3" >Get In Touch.</h3>
                  <form onSubmit={handleSubmit}>
                  <div className="form-request a-ajax-form">
                    <div className="row">
                      <div className="col-6">
                        <input
                          name="name"
                          className="form-control"
                          placeholder="Name"
                        />
                         <input
            type="Hidden"
            name="PageUrl"
            value={window.location.href}
          />
                      </div>
                      <div className="col-6">
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <input
                          name="phoneno"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-6">
                        <input
                          name="whatsapp"
                          className="form-control"
                          placeholder="Whatsapp"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-4">
                      <input
                        name="location"
                        className="form-control"
                        placeholder="Location"
                      />
                    </div>
                    <div className="form-group mt-4">
                      <textarea
                        name="massage"
                        className="form-control"
                        rows="4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    {/* Captcha Field */}
                    <div className="form-group mt-4">
                      <label>
                        {num1} + {num2} = ?
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter captcha answer"
                        value={captchaInput}
                        onChange={(e) => setCaptchaInput(e.target.value)}
                      />
                      {captchaError && (
                        <div style={{ color: "red", marginTop: "5px" }}>
                          {captchaError}
                        </div>
                      )}
                    </div>
                    <div className="text-center mt-4">
                      <button type="submit" className="btn btn-dark">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                    </div>
                

               
              </div>

              {/* Right Section (Contact Form) */}
              <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
             
              {/* <h1>Location</h1> */}

                        <div className="row">
                          <div className="col-md-12">
                          <div className="info mt-4">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Head Office :</h4>
                    <p>
                      LG-CS-07A, Ansal Corporate Plaza, Sector-1, Vaishali,
                      Ghaziabad, Uttar Pradesh 201012
                    </p>
                  </div>
               
                </div>
                          </div>
                          <div className="col-md-6">
                          <div className="info mt-4">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Astralia Office :</h4>
                    <p>
                    32/60 John Gorton Drive Coombs Australian Capital Territory 2611
                    </p>
                  </div>
               
                </div>
                          </div>

                          <div className="col-md-6">
                            
                          <div className="info mt-4">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Himachal Pradesh Office :</h4>
                    <p>
                    Plot 08, Ground 1 Near Sheds College, Chambaghat, Solan, Himachal Pradesh 173213
                    </p>
                  </div>
               
                </div>
                            </div>

                        </div>

                        <div className="row">
                          <div className="col-md-6">
                          <div className="info mt-4">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Punjab Office :</h4>
                    <p>
                    Shop No. 4, opposite Dusshera Ground, Sadar Bazar, Jalandhar Cantt, Jalandhar, Punjab 144005
                    </p>
                  </div>
               
                </div>
                          </div>
                          <div className="col-md-6">
                            
                          <div className="info mt-4">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Kolkata Office :</h4>
                    <p>
                    Green Park Oishi Tower Flat 4c, 4th Floor Near China Mandir Bus Stop, kolkata, West Bengal 700055
                    </p>
                  </div>
               
                </div>
                            </div>

                        </div>


                        <div className="left-sidebar-box mt-4">
                  <div className="row">
                    <div
                      className="col-xl-12 text-center mt-1"
                      style={{
                        background: "#2a3235",
                        color: "white",
                        padding: "27px",
                        borderRadius: "13px",
                      }}
                    >
                      <h2 className="mt-3 text-yellow">Follow Us</h2>
                      <h5 className="mt-3">
                        Digics - The Next Level Of Digital Marketing Experience
                      </h5>
                      <ul className="social-icon mt-3 gap-3 d-flex justify-content-center list-unstyled">
                        <li className="light-bg">
                          <a
                            href="https://www.facebook.com/Digics.in/"
                            className="footer-link-color"
                          >
                            <i
                              className="bi bi-facebook font26"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="light-bg">
                          <i className="bi bi-cursor font26" aria-hidden="true"></i>
                        </li>
                        <li className="light-bg">
                          <i className="bi bi-twitter font26" aria-hidden="true"></i>
                        </li>
                        <li className="light-bg">
                          <a
                            href="https://www.instagram.com/digicsmkt/"
                            className="footer-link-color"
                          >
                            <i
                              className="bi bi-instagram font26"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="light-bg">
                          <i className="bi bi-pinterest font26" aria-hidden="true"></i>
                        </li>
                      </ul>
                      <ul className="social-icon list-unstyled mt-3 gap-3 d-flex justify-content-center">
                        <li className="light-bg">
                          <a
                            href="https://www.youtube.com/channel/UCH5k494d6K5H1U0wIOICydA?view_as=subscriber"
                            className="footer-link-color"
                          >
                            <i
                              className="bi bi-youtube font26"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="light-bg">
                          <a
                            href="https://www.linkedin.com/company/digicsin/?originalSubdomain=in"
                            className="footer-link-color"
                          >
                            <i
                              className="bi bi-linkedin font26"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li className="light-bg">
                          <i className="bi bi-snapchat font26" aria-hidden="true"></i>
                        </li>
                        <li className="light-bg">
                          <a
                            href="https://www.whatsapp.com/channel/0029VaAr8p130LKRGpowqI2q"
                            className="footer-link-color"
                          >
                            <i
                              className="bi bi-whatsapp font26"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </ul>
                      <h4 className="mt-3 text-yellow">
                        With - <b className="text-white">#DigitalMarketing</b>
                      </h4>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Contact;
