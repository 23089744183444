import React, {useState } from 'react';
import Modalform from './Modalform';
import { useParams, Link } from 'react-router-dom';


function HomeCommonSec() {
    const location = useParams().location || "India";
            
    
          // State to manage modal visibility
          const [showModal, setShowModal] = useState(false);
        
          // Functions to handle modal open/close
          const handleShow = () => setShowModal(true);
          const handleClose = () => setShowModal(false);

    return (
        <div id="main">
        <section id="about" className="about">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-lg-12">
                        <div className="row justify-content-around">
                            <div className="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                <div className="count-box py-5">
                                    <i className="bi bi-envelope-plus"></i>
                                    <div className="d-flex">
                                        <span data-purecounter-start="0" data-purecounter-end="80" className="purecounter" data-purecounter-duration="0">80</span>
                                        <h5>M +</h5>
                                    </div>
                                    <p>LEADS GENERATED</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                <div className="count-box py-5">
                                    <i className="bi bi-people"></i>
                                    <div className="d-flex">
                                        <span data-purecounter-start="0" data-purecounter-end="457" className="purecounter" data-purecounter-duration="0">457</span>
                                        <h6> + Biz. </h6>
                                    </div>
                                    <p>WORKED WITH</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                <div className="count-box pb-3 pt-0 pt-lg-5 middlecounter">
                                    <i className="bi bi-award"></i>
                                    <div className="d-flex">
                                        <span data-purecounter-start="0" data-purecounter-end="15" className="purecounter" data-purecounter-duration="0">15</span>
                                        <h5> + Yrs</h5>
                                    </div>
                                    <p>INDUSTRY EXPERT</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                <div className="count-box pb-5 pt-0 pt-lg-5">
                                    <i className="bi bi-currency-dollar"></i>
                                    <div className="d-flex">
                                        <span data-purecounter-start="0" data-purecounter-end="13" className="purecounter" data-purecounter-duration="0">13</span>
                                        <h6>Million </h6>
                                    </div>
                                    <p>$ BUDGET MANAGED</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-5 col-6 d-md-flex align-items-md-stretch">
                                <div className="count-box pb-5 pt-0 pt-lg-5">
                                    <i className="bi bi-graph-up"></i>
                                    <div className="d-flex">
                                        <span data-purecounter-start="0" data-purecounter-end="100" className="purecounter" data-purecounter-duration="0">99.9</span>
                                        <h6>% Till Date</h6>
                                    </div>
                                    <p>SUCCESS RATE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 video-box align-self-baseline position-relative text-center">
                     
                            <img
                                decoding="async"
                                fetchpriority="high"
                                src={ require("./images/about-scaled.webp") }
                                className="img-fluid aboutimg"
                                alt={`Digital-Marketing-${location}`}
                            />
                      
                    </div>
                    <div className="col-lg-7 pt-3 pt-lg-0 content">
                        <h3>Digital Marketing Agency In {location}</h3>
                        <p className="fst-italic"></p>
                        <p>
                            Digics is a leading <strong>Digital Marketing Agency in {location}</strong>, offering exceptional services. Our team of <b>Digital Marketing</b> experts has a deep understanding of the latest <b>Digital Marketing Trends</b> and technologies in <b>{location}</b>. With over 15+ years of experience in <b>{location}’s</b> market, they have the skills and insights to create customized marketing solutions tailored to specific advertising needs. Our <strong>Digital Marketing Company Services in {location}</strong> are cost-effective and scalable, catering to companies of all sizes from startups to large enterprises. Partnering with our <b>Digital Marketing Agency</b> can help businesses unlock the full potential of their online presence and achieve superior ROI with advanced cost-effective and marketing spending optimization strategies in <b>{location}</b>.
                        </p>
                        <h3>Best Digital Marketing and Advertising Company In {location}</h3>
                        <p>
                            Stands as {location}'s premier <strong>Digital Marketing Agency</strong>, our portfolio encompasses search engine marketing, lead generation, Facebook advertising management, web development, web designing, pay-per-click campaigns, and more services in <b>{location}</b>. Through our extensive offerings, we strive to deliver unparalleled solutions to our esteemed customers. We prioritize personalized attention by assigning dedicated relationship managers to each company, ensuring seamless execution of <b>digital marketing strategies</b>. Our company’s commitment in <b>{location}</b> is reflected in its remarkable 10X ROI within the first 90 days of <b>Digital Marketing Service</b>, empowering clients to streamline marketing expenditures and achieve exponential growth in <b>{location}'s</b> market.
                        </p>
                    </div>
                    <div className="col-lg-12 pt-3 pt-lg-0 content">
                        <h3>Top-notch Digital Marketing Company In {location}</h3>
                        <p>
                            We are a <strong>Digital Marketing Agency in {location}</strong>, responsible for growing our client firms with all types of digital marketing services. Our detailed Digital Marketing and Advertising package gives complete flexibility to all our clients in {location} to choose whatever services come under their allocated budget. Our <strong>Digital Marketing Specialist</strong> pays close attention to their company's advertising campaigns which we run in <b>{location}</b>. We pay close attention to every single account. The best service we offer our clients is a personalized <strong>Digital Marketing Consultant</strong> whose job is to make sure everything is streamlined in their <b>{location}</b> campaign.
                        </p>
                        <Link
                            className="readmorebtn mt-3 myButton"
                            onClick={handleShow}
                        >
                            Schedule Free Virtual Call
                        </Link>
                      
                    </div>
                </div>
            </div>
        </section>

        <section id="cta" className="cta">
<div className="container aos-init aos-animate" data-aos="fade-in">
<div className="row text-white">
  <h3 className="mb-4">Our Most Demanding Digital Marketing Services</h3>
  <div className="col-md-3">
    <ul className="list-unstyled">
      <li><i className="bx bx-check-double"></i> Digital Marketing</li>
      <li><i className="bx bx-check-double"></i> Facebook Advertising Management</li>
      <li><i className="bx bx-check-double"></i> SEO | Search Engine Marketing</li>
      <li><i className="bx bx-check-double"></i> Web Development</li>
    </ul>
  </div>
  <div className="col-md-3">
    <ul className="list-unstyled">
      <li><i className="bx bx-check-double"></i> Programmatic Advertising</li>
      <li><i className="bx bx-check-double"></i> SEM Marketing</li>
      <li><i className="bx bx-check-double"></i> Google AdWords Advertising</li>
      <li><i className="bx bx-check-double"></i> AMS Advertising</li>
    </ul>
  </div>
  <div className="col-md-3">
    <ul className="list-unstyled">
      <li><i className="bx bx-check-double"></i> Brand Marketing</li>
      <li><i className="bx bx-check-double"></i> Web Designing</li>
      <li><i className="bx bx-check-double"></i> YouTube Advertising</li>
      <li><i className="bx bx-check-double"></i> LinkedIn Advertising</li>
    </ul>
  </div>
  <div className="col-md-3">
    <ul className="list-unstyled">
      <li><i className="bx bx-check-double"></i> Pay Per Click Campaign</li>
      <li><i className="bx bx-check-double"></i> Lead Generation</li>
      <li><i className="bx bx-check-double"></i> PPC Marketing</li>
      <li><i className="bx bx-check-double"></i> Performance Marketing</li>
    </ul>
  </div>
  {/* Uncomment if you need the link */}
  {/* <Link className="cta-btn" to="#">Connect us Now !</Link> */}
</div>
</div>
</section>

<section id="services" className="services">
<div className="container">
<div className="section-title pt-5 aos-init aos-animate" data-aos="fade-up">
  <h2>7 Key Highlights Why Choose Us</h2>
  <p>Partnering with us means more than just acquiring a service; it signifies an alliance with a proven industry leader dedicated to elevating your B2B lead generation strategy to unprecedented heights</p>
</div>
<div className="row justify-content-center">
  <div className="col-md-6">
    <div className="icon-box aos-init aos-animate" data-aos="fade-up">
      <div className="icon"><i className="bi bi-briefcase" style={{ color: '#ff689b' }}></i></div>
      <h4 className="title"><Link to="#">Extensive Experience</Link></h4>
      <p className="description">With over 13 years of industry experience, Expertise to ROI</p>
    </div>
  </div>
  <div className="col-md-6">
    <div className="icon-box aos-init aos-animate" data-aos="fade-up">
      <div className="icon"><i className="bi bi-book" style={{ color: '#e9bf06' }}></i></div>
      <h4 className="title"><Link to="#">Impressive Portfolio</Link></h4>
      <p className="description">Successfully managing strategies for 479+ businesses In {location}</p>
    </div>
  </div>
  <div className="col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
    <div className="icon-box">
      <div className="icon"><i className="bi bi-globe" style={{ color: '#d6ff22' }}></i></div>
      <h4 className="title"><Link to="#">Success Rate</Link></h4>
      <p className="description">A remarkable 99.9% success rate, solidifying our reputation for consistently delivering results.</p>
    </div>
  </div>
  <div className="col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
    <div className="icon-box">
      <div className="icon"><i className="bi bi-binoculars" style={{ color: '#41cf2e' }}></i></div>
      <h4 className="title"><Link to="#">Elite Partnership</Link></h4>
      <p className="description">We proudly hold a Google and Facebook Marketing Partner badge, achieved by only 3% of agencies globally.</p>
    </div>
  </div>
  <div className="col-md-6 aos-init" data-aos="fade-up" data-aos-delay="100">
    <div className="icon-box">
      <div className="icon"><i className="bi bi-card-checklist" style={{ color: '#3fcdc7' }}></i></div>
      <h4 className="title"><Link to="#">Proven Growth Impact</Link></h4>
      <p className="description">99.9% of our clients have substantial 30-40% business growth.</p>
    </div>
  </div>
  <div className="col-md-6 aos-init" data-aos="fade-up" data-aos-delay="200">
    <div className="icon-box">
      <div className="icon"><i className="bi bi-clock" style={{ color: '#4680ff' }}></i></div>
      <h4 className="title"><Link to="#">Rapid Lead Generation</Link></h4>
      <p className="description">With a cumulative tally of 80M+ leads generated to date.</p>
    </div>
  </div>
  <div className="col-md-6 aos-init" data-aos="fade-up" data-aos-delay="200">
    <div className="icon-box">
      <div className="icon"><i className="bi bi-briefcase" style={{ color: '#f323d7' }}></i></div>
      <h4 className="title"><Link to="#">Budget Managed</Link></h4>
      <p className="description">USD 113M+, we understand maximizing ROI while optimizing marketing spend.</p>
    </div>
  </div>
</div>
</div>
</section>

<section id="portfolio" className="portfolio">
<div className="container">
<div className="section-title pt-5 aos-init aos-animate" data-aos="fade-up">
  <h2>Watch Our Client Testimonials</h2>
  <p>If you want to know Our Customer Success Stories. How it was done, What was their ROI, Leads, Spent, Campaigns, services etc.
    Then we'd highly recommend you to check out our customer testimonials Because we Believe "Customer Is The Real Mirror Of Your Brand" - Listen To Their Story.
  </p>
</div>
<div className="row justify-content-center aos-init aos-animate" data-aos="fade-up">
  <div className="col-lg-3 col-md-6 portfolio-item filter-app">
    <div className="portfolio-wrap">
    
      <img src={ require("./images/testimonial/1.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://youtu.be/OFwEskYgVNQ" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-web">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/2.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://youtu.be/qOEEBJbJNGo?si=2_qtvW_ACPzJtFGW" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-app">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/3.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://www.youtube.com/watch?v=FudHzKkzKiY&t" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-card">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/4.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://www.youtube.com/watch?v=cp96KZio7l4" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-card">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/5.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://youtu.be/Kr1_B3T7sjA" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-card">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/6.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-agency-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://www.youtube.com/watch?v=pcIqJ2IXv3w&t" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-3 col-md-6 portfolio-item filter-card">
    <div className="portfolio-wrap">
      <img src={ require("./images/testimonial/7.webp") }
        className="img-fluid entered lazyloaded" alt={`Digital-Marketing-firm-${location}`} />
      <div className="portfolio-info">
        <div className="portfolio-links">
          <Link to="https://youtu.be/mYHR_zgDTx4" className="portfolio-lightbox"><i className="bi bi-play youtube"></i></Link>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</section>

<section id="clients" className="clients">
<div className="container" data-aos="fade-up">
<div className="section-title">
<h2>Clients We Worked With</h2>
</div>

<div class="slider">
	<div class="slide-track">
		<div class="slide">  
			<img src={ require("./images/client-logo/1.webp") }  alt={`Digital-Marketing-${location}`} />
     
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/2.webp") }
       alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/3.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/4.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/5.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/6.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/7.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/8.webp") }   alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/9.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/10.webp") } alt={`Digital-Marketing-${location}`} />
		</div>
    <div class="slide">
			<img  src={ require("./images/client-logo/11.webp") }  alt={`Digital-Marketing-${location}`} />
     
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/12.webp") } alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/13.webp") }   alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/14.webp") }   alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/15.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/16.webp") }   alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/17.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
		<div class="slide">
			<img  src={ require("./images/client-logo/18.webp") }  alt={`Digital-Marketing-${location}`} />
		</div>
	</div>
</div>
</div>
</section>


<Modalform showModal={showModal} handleClose={handleClose} />
    </div>

       
    );
    
}


export default HomeCommonSec;
